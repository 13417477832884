import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { API, copy, isAdmin, isAgent, isRoot, showError, showInfo, showNotice, showSuccess } from '../helpers';
import Turnstile from 'react-turnstile';
import { UserContext } from '../context/User';
import { onGitHubOAuthClicked } from './utils';
import {
  Avatar, Banner,
  Button,
  Card,
  Descriptions,
  Divider, Image,
  Input, InputNumber,
  Layout,
  Modal,
  Space,
  Tag,
  Typography,
  Form,
  Select,
  Radio
} from "@douyinfe/semi-ui";
import { getQuotaPerUnit, renderQuota, renderQuotaWithPrompt, stringToColor } from "../helpers/render";

import { useTranslation } from 'react-i18next';


const PersonalSetting = () => {
  const [userState, userDispatch] = useContext(UserContext);
  let navigate = useNavigate();
  const { t } = useTranslation()
  const [inputs, setInputs] = useState({
    wechat_verification_code: '',
    email_verification_code: '',
    email: '',
    self_account_deletion_confirmation: '',
    set_new_password: '',
    set_new_password_confirmation: '',
  });
  const [status, setStatus] = useState({});
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
  const [showWeChatBindModal, setShowWeChatBindModal] = useState(false);
  const [showEmailBindModal, setShowEmailBindModal] = useState(false);
  const [showAccountDeleteModal, setShowAccountDeleteModal] = useState(false);
  const [turnstileEnabled, setTurnstileEnabled] = useState(false);
  const [turnstileSiteKey, setTurnstileSiteKey] = useState('');
  const [turnstileToken, setTurnstileToken] = useState('');
  const [loading, setLoading] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [countdown, setCountdown] = useState(30);
  const [systemToken, setSystemToken] = useState("");
  const [models, setModels] = useState([]);
  const [openTransfer, setOpenTransfer] = useState(false);
  const [transferAmount, setTransferAmount] = useState(0);
  const [role, setRole] = useState(0);


  useEffect(() => {
    // let user = localStorage.getItem('user');
    // if (user) {
    //   userDispatch({ type: 'login', payload: user });
    // }
    // console.log(localStorage.getItem('user'))

    let status = localStorage.getItem('status');
    if (status) {
      status = JSON.parse(status);
      setStatus(status);
      if (status.turnstile_check) {
        setTurnstileEnabled(true);
        setTurnstileSiteKey(status.turnstile_site_key);
      }
    }
    getUserData().then(
      (res) => {
        console.log(userState)
      }
    );
    loadModels().then();
    setTransferAmount(getQuotaPerUnit())
  }, []);

  useEffect(() => {
    let countdownInterval = null;
    if (disableButton && countdown > 0) {
      countdownInterval = setInterval(() => {
        setCountdown(countdown - 1);
      }, 1000);
    } else if (countdown === 0) {
      setDisableButton(false);
      setCountdown(30);
    }
    return () => clearInterval(countdownInterval); // Clean up on unmount
  }, [disableButton, countdown]);

  const handleInputChange = (name, value) => {
    setInputs((inputs) => ({ ...inputs, [name]: value }));
  };

  const generateAccessToken = async () => {
    const res = await API.get('/api/user/token');
    const { success, message, data } = res.data;
    if (success) {
      setSystemToken(data);
      await copy(data);
      showSuccess(t('PersonalSetting.PersonalSetting1'));
    } else {
      showError(message);
    }
  };


  const getUserData = async () => {
    let res = await API.get(`/api/user/self`);
    const { success, message, data } = res.data;
    if (success) {
      userDispatch({ type: 'login', payload: data });
      setRole(data.role);
    } else {
      showError(message);
    }
  }

  const loadModels = async () => {
    let res = await API.get(`/api/user/models`);
    const { success, message, data } = res.data;
    if (success) {
      setModels(data);
    } else {
      showError(message);
    }
  }

  const handleAffLinkClick = async (e) => {
    e.target.select();
    await copy(e.target.value);
    showSuccess(t('PersonalSetting.PersonalSetting2'));
  };

  const handleSystemTokenClick = async (e) => {
    e.target.select();
    await copy(e.target.value);
    showSuccess(t('PersonalSetting.PersonalSetting3'));
  };

  const deleteAccount = async () => {
    if (inputs.self_account_deletion_confirmation !== userState.user.username) {
      showError(t('PersonalSetting.PersonalSetting4'));
      return;
    }

    const res = await API.delete('/api/user/self');
    const { success, message } = res.data;

    if (success) {
      showSuccess(t('PersonalSetting.PersonalSetting5'));
      await API.get('/api/user/logout');
      userDispatch({ type: 'logout' });
      localStorage.removeItem('user');
      navigate('/login');
    } else {
      showError(message);
    }
  };

  const bindWeChat = async () => {
    if (inputs.wechat_verification_code === '') return;
    const res = await API.get(
      `/api/oauth/wechat/bind?code=${inputs.wechat_verification_code}`
    );
    const { success, message } = res.data;
    if (success) {
      showSuccess(t('PersonalSetting.PersonalSetting6'));
      setShowWeChatBindModal(false);
    } else {
      showError(message);
    }
  };

  const changePassword = async () => {
    if (inputs.set_new_password !== inputs.set_new_password_confirmation) {
      showError('两次输入的密码不一致！');
      return;
    }
    const res = await API.put(
      `/api/user/self`,
      {
        password: inputs.set_new_password
      }
    );
    const { success, message } = res.data;
    if (success) {
      showSuccess('密码修改成功！');
      setShowWeChatBindModal(false);
    } else {
      showError(message);
    }
    setShowChangePasswordModal(false);
  };

  const transfer = async () => {
    if (transferAmount < getQuotaPerUnit()) {
      showError(t('PersonalSetting.PersonalSetting32') + renderQuota(getQuotaPerUnit()));
      return;
    }
    const res = await API.post(
      `/api/user/aff_transfer`,
      {
        quota: transferAmount
      }
    );
    const { success, message } = res.data;
    if (success) {
      showSuccess(message);
      setOpenTransfer(false);
      getUserData().then();
    } else {
      showError(message);
    }
  };

  const sendVerificationCode = async () => {
    if (inputs.email === '') {
      showError(t('PersonalSetting.PersonalSetting33'));
      return;
    }
    setDisableButton(true);
    if (turnstileEnabled && turnstileToken === '') {
      showInfo(t('PersonalSetting.PersonalSetting7'));
      return;
    }
    setLoading(true);
    const res = await API.get(
      `/api/verification?email=${inputs.email}&turnstile=${turnstileToken}`
    );
    const { success, message } = res.data;
    if (success) {
      showSuccess(t('PersonalSetting.PersonalSetting8'));
    } else {
      showError(message);
    }
    setLoading(false);
  };

  const bindEmail = async () => {
    if (inputs.email_verification_code === '') {
      showError(t('PersonalSetting.PersonalSetting34'));
      return;
    }
    setLoading(true);
    const res = await API.get(
      `/api/oauth/email/bind?email=${inputs.email}&code=${inputs.email_verification_code}`
    );
    const { success, message } = res.data;
    if (success) {
      showSuccess(t('PersonalSetting.PersonalSetting6'));
      setShowEmailBindModal(false);
      userState.user.email = inputs.email;
    } else {
      showError(message);
    }
    setLoading(false);
  };

  const getUsername = () => {
    if (userState.user) {
      return userState.user.username;
    } else {
      return 'null';
    }
  }

  const handleCancel = () => {
    setOpenTransfer(false);
  }

  const copyText = async (text) => {
    if (await copy(text)) {
      showSuccess('已复制：' + text);
    } else {
      // setSearchKeyword(text);
      Modal.error({ title: t('PersonalSetting.PersonalSetting36'), content: text });
    }
  }

  const renderRole = (role) => {
    switch (role) {
      case 1:
        return <Tag>{t('UsersTable.UsersTable5')}</Tag>;
      case 3:
        return <Tag color='yellow'>{t('UsersTable.UsersTable39')}</Tag>;
      case 10:
        return <Tag color='yellow'>{t('UsersTable.UsersTable8')}</Tag>;
      case 100:
        return <Tag color='orange'>{t('UsersTable.UsersTable6')}</Tag>;
      default:
        return <Tag color='red'>{t('UsersTable.UsersTable7')}</Tag>;
    }
  }

  return (
    <div>
      <Layout>
        <Layout.Content>
          <Modal
            title={t('PersonalSetting.PersonalSetting37')}
            visible={openTransfer}
            onOk={transfer}
            onCancel={handleCancel}
            maskClosable={false}
            size={'small'}
            centered={true}
          >
            <div style={{ marginTop: 20 }}>
              <Typography.Text>{`${t('PersonalSetting.PersonalSetting39')}${renderQuotaWithPrompt(userState?.user?.aff_quota)}`}</Typography.Text>
              <Input style={{ marginTop: 5 }} value={userState?.user?.aff_quota} disabled={true}></Input>
            </div>
            <div style={{ marginTop: 20 }}>
              <Typography.Text>{`${t('PersonalSetting.PersonalSetting40')}${renderQuotaWithPrompt(transferAmount)} ${t('PersonalSetting.PersonalSetting41')}` + renderQuota(getQuotaPerUnit())}</Typography.Text>
              <div>
                <InputNumber min={0} style={{ marginTop: 5 }} value={transferAmount} onChange={(value) => setTransferAmount(value)} disabled={false}></InputNumber>
              </div>
            </div>
          </Modal>
          <div style={{ marginTop: 20 }}>
            <Card
              title={
                <Card.Meta
                  avatar={<Avatar size="default" color={stringToColor(getUsername())}
                    style={{ marginRight: 4 }}>
                    {typeof getUsername() === 'string' && getUsername().slice(0, 1)}
                  </Avatar>}
                  title={<Typography.Text>{getUsername()}</Typography.Text>}
                  description={renderRole(role)}
                ></Card.Meta>
              }
              headerExtraContent={
                <>
                  <Space vertical align="start">
                    <Tag color="green">{'ID: ' + userState?.user?.id}</Tag>
                    <Tag color="blue">{userState?.user?.group}</Tag>
                  </Space>
                </>
              }
              footer={
                <Descriptions row>
                  <Descriptions.Item itemKey={t('PersonalSetting.PersonalSetting44')}>{renderQuota(userState?.user?.quota)}</Descriptions.Item>
                  <Descriptions.Item itemKey={t('PersonalSetting.PersonalSetting45')}>{renderQuota(userState?.user?.used_quota)}</Descriptions.Item>
                  <Descriptions.Item itemKey={t('PersonalSetting.PersonalSetting46')}>{userState.user?.request_count}</Descriptions.Item>
                </Descriptions>
              }
            >
              <Typography.Title heading={6}>{t('PersonalSetting.PersonalSetting47')}</Typography.Title>
              <div style={{ marginTop: 10 }}>
                <Space wrap>
                  {models.map((model) => (
                    <Tag key={model} color="cyan" onClick={() => {
                      copyText(model)
                    }}>
                      {model}
                    </Tag>
                  ))}
                </Space>
              </div>

            </Card>
            <Card>
              <Typography.Title heading={6}>{t('PersonalSetting.PersonalSetting54')}</Typography.Title>
              <div style={{ marginTop: 20 }}>
                <Typography.Text strong>{t('PersonalSetting.PersonalSetting55')}</Typography.Text>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div>
                    <Input
                      value={userState.user && userState.user.email !== '' ? userState.user.email : t('PersonalSetting.PersonalSetting59')}
                      readonly={true}
                    ></Input>
                  </div>
                  <div>
                    <Button onClick={() => { setShowEmailBindModal(true) }} disabled={userState.user && userState.user.email !== ''}>{t('PersonalSetting.PersonalSetting56')}</Button>
                  </div>
                </div>
              </div>
              <div style={{ marginTop: 10 }}>
                <Typography.Text strong>{t('PersonalSetting.PersonalSetting57')}</Typography.Text>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div>
                    <Input
                      value={userState.user && userState.user.wechat_id !== '' ? t('PersonalSetting.PersonalSetting58') : t('PersonalSetting.PersonalSetting59')}
                      readonly={true}
                    ></Input>
                  </div>
                  <div>
                    <Button disabled={(userState.user && userState.user.wechat_id !== '') || !status.wechat_login}>
                      {
                        status.wechat_login ? t('PersonalSetting.PersonalSetting60') : t('PersonalSetting.PersonalSetting61')
                      }
                    </Button>
                  </div>
                </div>
              </div>
              <div style={{ marginTop: 10 }}>
                <Typography.Text strong>GitHub</Typography.Text>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div>
                    <Input
                      value={userState.user && userState.user.github_id !== '' ? userState.user.github_id : t('PersonalSetting.PersonalSetting59')}
                      readonly={true}
                    ></Input>
                  </div>
                  <div>
                    <Button
                      onClick={() => { onGitHubOAuthClicked(status.github_client_id) }}
                      disabled={(userState.user && userState.user.github_id !== '') || !status.github_oauth}
                    >
                      {
                        status.github_oauth ? t('PersonalSetting.PersonalSetting60') : t('PersonalSetting.PersonalSetting61')
                      }
                    </Button>
                  </div>
                </div>
              </div>

              <div style={{ marginTop: 10 }}>
                <Space>
                  <Button onClick={generateAccessToken}>生成系统访问令牌</Button>
                  <Button onClick={() => {
                    setShowChangePasswordModal(true);
                  }}>修改密码</Button>
                  <Button type={'danger'} onClick={() => {
                    setShowAccountDeleteModal(true);
                  }}>删除个人账户</Button>
                </Space>
                {systemToken && (
                  <Input
                    readOnly
                    value={systemToken}
                    onClick={handleSystemTokenClick}
                    style={{ marginTop: '10px' }}
                  />
                )}
                {
                  status.wechat_login && (
                    <Button
                      onClick={() => {
                        setShowWeChatBindModal(true);
                      }}
                    >
                      {t('PersonalSetting.PersonalSetting17')}
                    </Button>
                  )
                }
                <Modal
                  onCancel={() => setShowWeChatBindModal(false)}
                  // onOpen={() => setShowWeChatBindModal(true)}
                  visible={showWeChatBindModal}
                  size={'mini'}
                >
                  <Image src={status.wechat_qrcode} />
                  <div style={{ textAlign: 'center' }}>
                    <p>
                      {t('PersonalSetting.PersonalSetting18')}
                    </p>
                  </div>
                  <Input
                    placeholder={t('PersonalSetting.PersonalSetting19')}
                    name='wechat_verification_code'
                    value={inputs.wechat_verification_code}
                    onChange={(v) => handleInputChange('wechat_verification_code', v)}
                  />
                  <Button color='' fluid size='large' onClick={bindWeChat}>
                    {t('PersonalSetting.PersonalSetting20')}
                  </Button>
                </Modal>
                <Modal
                  onCancel={() => setShowEmailBindModal(false)}
                  // onOpen={() => setShowEmailBindModal(true)}
                  onOk={bindEmail}
                  visible={showEmailBindModal}
                  size={'small'}
                  centered={true}
                  maskClosable={false}
                >
                  <Typography.Title heading={6}>{t('PersonalSetting.PersonalSetting23')}</Typography.Title>
                  <div style={{ marginTop: 20, display: 'flex', justifyContent: 'space-between' }}>
                    <Input
                      fluid
                      placeholder={t('PersonalSetting.PersonalSetting23')}
                      onChange={(value) => handleInputChange('email', value)}
                      name='email'
                      type='email'
                    />
                    <Button onClick={sendVerificationCode}
                      disabled={disableButton || loading}>
                      {disableButton ? t('PersonalSetting.PersonalSetting24', { countdown }) : t('PersonalSetting.PersonalSetting25')}
                    </Button>
                  </div>
                  <div style={{ marginTop: 10 }}>
                    <Input
                      fluid
                      placeholder={t('PersonalSetting.PersonalSetting19')}
                      name='email_verification_code'
                      value={inputs.email_verification_code}
                      onChange={(value) => handleInputChange('email_verification_code', value)}
                    />
                  </div>
                  {turnstileEnabled ? (
                    <Turnstile
                      sitekey={turnstileSiteKey}
                      onVerify={(token) => {
                        setTurnstileToken(token);
                      }}
                    />
                  ) : (
                    <></>
                  )}
                </Modal>
                <Modal
                  onCancel={() => setShowAccountDeleteModal(false)}
                  visible={showAccountDeleteModal}
                  size={'small'}
                  centered={true}
                  onOk={deleteAccount}
                >
                  <div style={{ marginTop: 20 }}>
                    <Banner
                      type="danger"
                      description={t('PersonalSetting.PersonalSetting29')}
                      closeIcon={null}
                    />
                  </div>
                  <div style={{ marginTop: 20 }}>
                    <Input
                      placeholder={t('PersonalSetting.PersonalSetting30', { username: userState?.user?.username })}
                      name='self_account_deletion_confirmation'
                      value={inputs.self_account_deletion_confirmation}
                      onChange={(value) => handleInputChange('self_account_deletion_confirmation', value)}
                    />
                    {turnstileEnabled ? (
                      <Turnstile
                        sitekey={turnstileSiteKey}
                        onVerify={(token) => {
                          setTurnstileToken(token);
                        }}
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                </Modal>
                <Modal
                  onCancel={() => setShowChangePasswordModal(false)}
                  visible={showChangePasswordModal}
                  size={'small'}
                  centered={true}
                  onOk={changePassword}
                >
                  <div style={{ marginTop: 20 }}>
                    <Input
                      name='set_new_password'
                      placeholder='新密码'
                      value={inputs.set_new_password}
                      onChange={(value) => handleInputChange('set_new_password', value)}
                    />
                    <Input
                      style={{ marginTop: 20 }}
                      name='set_new_password_confirmation'
                      placeholder='确认新密码'
                      value={inputs.set_new_password_confirmation}
                      onChange={(value) => handleInputChange('set_new_password_confirmation', value)}
                    />
                    {turnstileEnabled ? (
                      <Turnstile
                        sitekey={turnstileSiteKey}
                        onVerify={(token) => {
                          setTurnstileToken(token);
                        }}
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                </Modal>
              </div>
            </Card>
            <Card>
              <Typography.Title heading={6}>通知设置</Typography.Title>
              <NotifySetting />
            </Card>
          </div>
        </Layout.Content>
      </Layout>
    </div>
  );
};

const NotifySetting = () => {
  const [notice_type, setNotificationMethod] = useState('wechat'); // 默认值设置为 'wechat'
  const isAdminUser = isAdmin();
  const isAgentUser = isAgent();

  const [inputsValue, setInputsValue] = useState({});
  const [flag, setFlag] = useState(false)
  let quotaPerUnit = localStorage.getItem('quota_per_unit');

  const handleInputChange = (name, value) => {
    setInputsValue((inputsValue) => ({ ...inputsValue, [name]: value }));
  };

  const loadUserSettings = async () => {
    let res = await API.get(`/api/user/settings`);

    const { success, message, data } = res.data;
    console.log("12312", data);
    if (success) {
      setInputsValue((inputsValue) => ({
        ...data.settings,
        topics: data.settings.topics.split(','),
        quota_too_low_notice_threshold: parseFloat(data.settings.quota_too_low_notice_threshold) / quotaPerUnit,
      }));
      setNotificationMethod(data.settings.notice_type)
      setTimeout(() => {
        setFlag(true)
      })
    } else {
      showError(message);
    }
  }

  useEffect(() => {
    loadUserSettings().then()
  }, [])

  let topics = [
    {
      "label": "公告、营销信息",
      "key": "notice",
    },
    {
      "label": "用户额度低于预警(最多1小时1封)",
      "key": "user_quota_too_low",
    },
    {
      "label": "key 额度低于预警(单key最多1小时1封)",
      "key": "key_quota_too_low",
    }
  ];

  if (isAdminUser) {
    topics.push(
      {
        "label": "渠道余额低于预警",
        "key": "channel_quota_too_low",
      },
      {
        "label": "mj账号被封禁",
        "key": "mj_account_banned",
      },
    );
  }

  if (isAgentUser) {
    topics.push(
      {
        "label": "新用户注册",
        "key": "new_user",
      },
      {
        "label": "用户充值",
        "key": "user_top_up",
      },
    );
  }

  const handleNotificationMethodChange = (e) => {
    handleInputChange('notice_type', e.target.value);
    setNotificationMethod(e.target.value);
  };

  const handleSubmit = async () => {
    let localInputs = inputsValue;
    if (localInputs.topics && Array.isArray(localInputs.topics)) {
      localInputs.topics = localInputs.topics.join(',');
    }
    if (localInputs.quota_too_low_notice_threshold) {
      const d = localInputs.quota_too_low_notice_threshold * quotaPerUnit
      localInputs.quota_too_low_notice_threshold = parseInt(d.toString());
    }

    const res = await API.put('/api/user/settings', localInputs)
    const { success, message } = res.data;
    if (success) {
      showSuccess('设置成功');
    } else {
      showError(message);
    }
  };

  return (
    <>
      {flag && <Form onSubmit={handleSubmit}>
        <div style={{ marginTop: 10 }}>
          <Typography.Text >订阅事件后，当事件触发，将会发送消息到您设置的通道上</Typography.Text>
        </div>
        <Form.Select initValue={inputsValue.topics} multiple style={{ width: '460px' }} field="topics" label="订阅事件" placeholder="请选择订阅事件" onChange={value =>
          handleInputChange('topics', value)
        }>
          {topics.map((topic) => (
            <Select.Option value={topic.key} key={topic.key}>{topic.label}</Select.Option>
          ))}
        </Form.Select>
        <Form.RadioGroup
          field="notice_type"
          label="通知方式"
          onChange={handleNotificationMethodChange}
          initValue={inputsValue.notice_type} // 默认选中企业微信
        >
          <Radio value="qiyewx">企业微信</Radio>
          <Radio value="email">邮件</Radio>
          <Radio value="wxpusher">WxPusher</Radio>
        </Form.RadioGroup>

        {notice_type === 'qiyewx' && (
          <>
            <div>
              <span>通过企业微信机器人发送信息
                <a href='https://developer.work.weixin.qq.com/document/path/91770' target='_blank'
                  rel='noreferrer'>点击此处</a> 查看接入文档</span>
            </div>
            <Form.Input initValue={inputsValue.notice_qiye_wx_key} style={{ width: '460px' }} onChange={(value) => handleInputChange('notice_qiye_wx_key', value)} field="notice_qiye_wx_key" label="企业微信机器人key" placeholder="请输入企业微信机器人key" rules={[{ required: true, message: '请输入企业微信机器人key' }]} />
          </>
        )}

        {notice_type === 'email' && (
          <>
            <div>
              <span>通过邮箱发送信息</span>
            </div>
            <Form.Input initValue={inputsValue.notice_email} style={{ width: '460px' }} onChange={(value) => handleInputChange('notice_email', value)} field="notice_email" label="邮箱" placeholder="请输入邮箱" rules={[{ required: true, message: '请输入邮箱' }, { type: 'email', message: '请输入正确的邮箱地址' }]} />
          </>
        )}

        {notice_type === 'wxpusher' && (
          <>
            <div>
              <span>通过微信WxPusher发送信息
                <a href='https://wxpusher.zjiecode.com/docs' target='_blank'
                  rel='noreferrer'>点击此处</a> 查看接入文档</span>
            </div>
            <Form.Input initValue={inputsValue.notice_app_token} style={{ width: '460px' }} onChange={(value) => handleInputChange('notice_app_token', value)} field="notice_app_token" label="AppToken" placeholder="请输入AppToken" rules={[{ required: true, message: '请输入AppToken' }]} />
            <Form.Input initValue={inputsValue.notice_receiver_uid} style={{ width: '460px' }} onChange={(value) => handleInputChange('notice_receiver_uid', value)} field="notice_receiver_uid" label="用户ID (userId)" placeholder="请输入用户ID (userId)" rules={[{ required: true, message: '请输入用户ID' }]} />
          </>
        )}

        <Form.AutoComplete
          key={`${inputsValue.quota_too_low_notice_threshold}_${inputsValue.notice_type}`}
          label={`用户预警额度，需订阅事件，当余额低于 ${inputsValue.quota_too_low_notice_threshold}$ 将会发送消息提醒`}
          onChange={(value) => handleInputChange('quota_too_low_notice_threshold', value)} style={{ marginTop: 8, marginBottom: 20 }}
          placeholder="当到达预警值自动提醒"
          autoComplete='new-password'
          type='number'
          position={'top'}
          field={"quota_too_low_notice_threshold"}
          data={[
            { value: 1, label: '1$' },
            { value: 10, label: '10$' },
            { value: 50, label: '50$' },
            { value: 100, label: '100$' },
            { value: 500, label: '500$' },
            { value: 1000, label: '1000$' },
          ]}
          initValue={inputsValue.quota_too_low_notice_threshold}
        />
        <Button htmlType="submit" type="primary">保存</Button>
      </Form>}
    </>

  );
}

export default PersonalSetting;
