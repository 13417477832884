import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  API,
  isAdmin,
  isAgent,
  isMobile,
  showError,
  showInfo,
  showSuccess,
  timestamp2string,
  verifyJSON
} from '../../helpers';
import { renderQuotaWithPrompt } from '../../helpers/render';
import Title from "@douyinfe/semi-ui/lib/es/typography/title";
import {
  SideSheet,
  Space,
  Button,
  Spin,
  Input,
  Typography,
  Select,
  Divider,
  TextArea,
  DatePicker,
  Checkbox
} from "@douyinfe/semi-ui";
import { useTranslation } from 'react-i18next';

const TASK_LIMITS_DEFAULT = {
  'fast': 5,
  'turbo': 5,
  'relax': 10
};

const EditUser = (props) => {
  const isAdminUser = isAdmin()
  const { t } = useTranslation();
  const userId = props.editingUser.id;
  const [loading, setLoading] = useState(true);
  const [inputs, setInputs] = useState({
    username: '',
    display_name: '',
    password: '',
    github_id: '',
    wechat_id: '',
    email: '',
    quota: 0,
    group: 'default',
    exclusive_price: '',
    allow_models: '',
    fixed_quota: 0,
    fixed_quota_expire_at: 0,
    agent_user_id: 0,
    task_limits: '',
    models_ratio: '',
  });
  let now = new Date();
  const [fixedQuotaExpireAt, setFixedQuotaExpireAt] = useState(timestamp2string(now.getTime() / 1000 - 3600));

  const [groupOptions, setGroupOptions] = useState([]);
  const { username, display_name, password, github_id, wechat_id, email, quota, group, exclusive_price, allow_models, fixed_quota, fixed_quota_expire_at, agent_user_id, task_limits, models_ratio } =
    inputs;
  const handleInputChange = (name, value) => {
    setInputs((inputs) => ({ ...inputs, [name]: value }));
  };

  const [originModelOptions, setOriginModelOptions] = useState([]);
  const [modelOptions, setModelOptions] = useState([]);

  const fetchGroups = async () => {
    try {
      let res = await API.get(`/api/group/`);
      setGroupOptions(res.data.data.map((group) => ({
        label: group,
        value: group,
      })));
    } catch (error) {
      showError(error.message);
    }
  };
  const handleCancel = () => {
    props.handleClose();
  }
  const loadUser = async () => {
    if (!userId) {
      return
    }
    let url = "";
    if (isAdminUser) {
      url = `/api/user/${userId}`
    } else {
      url = `/api/user/agent/${userId}`
    }
    const res = await API.get(url);
    const { success, message, data } = res.data;
    if (success) {
      data.password = '';
      if (data.task_limits !== '') {
        data.task_limits = JSON.stringify(JSON.parse(data.task_limits), null, 2);
      }
      if (data.models_ratio !== '') {
        data.models_ratio = JSON.stringify(JSON.parse(data.models_ratio), null, 2);
      }

      setInputs(data);
      if (data.fixed_quota_expire_at) {
        setFixedQuotaExpireAt(timestamp2string(data.fixed_quota_expire_at));
      }
    } else {
      showError(message);
    }
    setLoading(false);
  };

  useEffect(() => {
    loadUser().then();
    if (userId && isAdminUser) {
      fetchGroups().then();
    }
    fetchModels().then();
  }, [props.editingUser.id]);

  const submit = async () => {
    setLoading(true);
    let res = undefined;
    if (userId) {
      let data = { ...inputs, id: parseInt(userId) };
      if (typeof data.quota === 'string') {
        data.quota = parseInt(data.quota);
      }
      if (typeof data.agent_user_id === 'string') {
        data.agent_user_id = parseInt(data.agent_user_id);
      }
      if (typeof data.fixed_quota === 'string') {
        data.fixed_quota = parseInt(data.fixed_quota);
      }
      if (typeof data.fixed_quota_expire_at === 'string') {
        const d = Date.parse(data.fixed_quota_expire_at) / 1000
        if (d > (now.getTime() / 1000)) {
          data.fixed_quota_expire_at = d;
        }
      }
      if (data.allow_models) {
        data.allow_models = data.allow_models.join(',');
      }
      if (data.task_limits && !verifyJSON(data.task_limits)) {
        showInfo("task limits 不是json格式");
        return;
      }
      if (data.models_ratio && !verifyJSON(data.models_ratio)) {
        showInfo("models ratio 不是json格式");
        return;
      }

      if (isAdminUser) {
        res = await API.put(`/api/user/`, data);
      } else {
        res = await API.put(`/api/user/agent/`, data);
      }
    } else {
      res = await API.put(`/api/user/self`, inputs);
    }
    const { success, message } = res.data;
    if (success) {
      showSuccess(t('EditUser.EditUser1'));
      props.refresh();
      props.handleClose();
    } else {
      showError(message);
    }
    setLoading(false);
  };

  useEffect(() => {
    let localModelOptions = [...originModelOptions];
    if (inputs.allow_models) {
      if (!Array.isArray(inputs.allow_models)) {
        inputs.allow_models = inputs.allow_models.split(",")
      }
      inputs.allow_models.forEach((model) => {
        if (!localModelOptions.find((option) => option.key === model)) {
          localModelOptions.push({
            label: model,
            value: model
          });
        }
      });
    }
    setModelOptions(localModelOptions);
  }, [originModelOptions, inputs.allow_models]);

  const fetchModels = async () => {
    try {
      let res = await API.get(`/api/models`);
      let localModelOptions = res.data.data.map((model) => ({
        label: model,
        value: model
      }));
      setOriginModelOptions(localModelOptions);
    } catch (error) {
      showError(error.message);
    }
  };

  return (
    <>
      <SideSheet
        placement={'right'}
        title={<Title level={3}>{t('EditUser.EditUser30')}</Title>}
        headerStyle={{ borderBottom: '1px solid var(--semi-color-border)' }}
        bodyStyle={{ borderBottom: '1px solid var(--semi-color-border)' }}
        visible={props.visible}
        footer={
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Space>
              <Button theme='solid' size={'large'} onClick={submit}>{t('EditUser.EditUser28')}</Button>
              <Button theme='solid' size={'large'} type={'tertiary'} onClick={handleCancel}>{t('EditUser.EditUser27')}</Button>
            </Space>
          </div>
        }
        closeIcon={null}
        onCancel={() => handleCancel()}
        width={isMobile() ? '100%' : 600}
      >
        <Spin spinning={loading}>
          <div style={{ marginTop: 20 }}>
            <Typography.Text>{t('EditUser.EditUser3')}</Typography.Text>
          </div>
          <Input
            label={t('EditUser.EditUser3')}
            name='username'
            placeholder={t('EditUser.EditUser4')}
            onChange={value => handleInputChange('username', value)}
            value={username}
            autoComplete='new-password'
          />
          <div style={{ marginTop: 20 }}>
            <Typography.Text>{t('EditUser.EditUser5')}</Typography.Text>
          </div>
          <Input
            label={t('EditUser.EditUser5')}
            name='password'
            type={'password'}
            placeholder={t('EditUser.EditUser6')}
            onChange={value => handleInputChange('password', value)}
            value={password}
            autoComplete='new-password'
          />
          <div style={{ marginTop: 20 }}>
            <Typography.Text>{t('EditUser.EditUser7')}</Typography.Text>
          </div>
          <Input
            label={t('EditUser.EditUser7')}
            name='display_name'
            placeholder={t('EditUser.EditUser8')}
            onChange={value => handleInputChange('display_name', value)}
            value={display_name}
            autoComplete='new-password'
          />
          {
            userId && <>
              <div style={{ marginTop: 20 }}>
                <Typography.Text>{t('EditUser.EditUser9')}</Typography.Text>
              </div>
              <Select
                disabled={!isAdminUser}
                placeholder={t('EditUser.EditUser10')}
                name='group'
                fluid
                search
                selection
                allowAdditions
                additionLabel={t('EditUser.EditUser11')}
                onChange={value => handleInputChange('group', value)}
                value={inputs.group}
                autoComplete='new-password'
                optionList={groupOptions}
              />
              <div style={{ marginTop: 20 }}>
                <Typography.Text>{t('EditUser.EditUser12', { data: renderQuotaWithPrompt(quota) })}</Typography.Text>
              </div>
              <Input
                disabled={!isAdminUser}
                name='quota'
                placeholder={t('EditUser.EditUser13')}
                onChange={value => handleInputChange('quota', value)}
                value={quota}
                type={'number'}
                autoComplete='new-password'
              />
              <div style={{ marginTop: 20 }}>
                <Typography.Text>{t('EditUser.EditUser14')}</Typography.Text>
              </div>
              <Input
                name='exclusive_price'
                placeholder={t('EditUser.EditUser15')}
                onChange={value => handleInputChange('exclusive_price', value)}
                value={exclusive_price}
                type={'number'}
                autoComplete='new-password'
              />
              <div style={{ marginTop: 20 }}>
                <Typography.Text>{t('EditUser.EditUser16')}</Typography.Text>
              </div>
              <Select
                  placeholder={t('EditUser.EditUser17')}
                  name='allow_models'
                  required
                  multiple
                  selection
                  onChange={value =>
                      handleInputChange('allow_models', value)
                  }
                  value={inputs.allow_models}
                  autoComplete='new-password'
                  optionList={modelOptions}
              />
              <div style={{ marginTop: 20 }}>
                <Typography.Text>模型倍率</Typography.Text>
              </div>
              <TextArea
                  name='models_ratio'
                  placeholder={`默认值为：\n${JSON.stringify(TASK_LIMITS_DEFAULT, null, 2)}`}
                  onChange={value => handleInputChange('models_ratio', value)}
                  value={models_ratio}
                  autoComplete='new-password'
                  disabled={!isAdminUser}
              />
              <div style={{ marginTop: 20 }}>
                <Typography.Text>{t('EditUser.EditUser18', { data: renderQuotaWithPrompt(fixed_quota) })}</Typography.Text>
              </div>
              <Input
                name='fixed_quota'
                placeholder={t('EditUser.EditUser19')}
                onChange={value => handleInputChange('fixed_quota', value)}
                value={fixed_quota}
                type={'number'}
                autoComplete='new-password'
                disabled={!isAdminUser}
              />
              <div style={{ marginTop: 20 }}>
                <Typography.Text>{t('EditUser.EditUser20')}</Typography.Text>
              </div>
              <DatePicker
                name='fixed_quota_expire_at'
                type='dateTime'
                onChange={value => {
                  setFixedQuotaExpireAt(value);
                  handleInputChange('fixed_quota_expire_at', value)
                }}
                value={fixedQuotaExpireAt}
                autoComplete='new-password'
                disabled={!isAdminUser}
              />
              <div style={{ marginTop: 20 }}>
                <Typography.Text>并发任务控制</Typography.Text>
              </div>
              <TextArea
                  name='task_limits'
                  placeholder={`默认值为：\n${JSON.stringify(TASK_LIMITS_DEFAULT, null, 2)}`}
                  onChange={value => handleInputChange('task_limits', value)}
                  value={task_limits}
                  autoComplete='new-password'
                  disabled={!isAdminUser}
              />
                {
                  isAdminUser && <>
                      <div style={{ marginTop: 20 }}>
                        <Typography.Text>{t('EditUser.EditUser34')}</Typography.Text>
                      </div>
                      <Input
                          name='agent_user_id'
                          value={agent_user_id}
                          type={'number'}
                          onChange={value => handleInputChange('agent_user_id', value)}
                          autoComplete='new-password'
                          placeholder={t('EditUser.EditUser35')}
                          disabled={!isAdminUser}
                      />
                  </>
                }
            </>
          }
          <Divider style={{ marginTop: 20 }}>{t('EditUser.EditUser32')}</Divider>
          <div style={{ marginTop: 20 }}>
            <Typography.Text>{t('EditUser.EditUser33')}</Typography.Text>
          </div>
          <Input
            name='github_id'
            value={github_id}
            autoComplete='new-password'
            placeholder={t('EditUser.EditUser22')}
            readonly
          />
          <div style={{ marginTop: 20 }}>
            <Typography.Text>{t('EditUser.EditUser23')}</Typography.Text>
          </div>
          <Input
            name='wechat_id'
            value={wechat_id}
            autoComplete='new-password'
            placeholder={t('EditUser.EditUser24')}
            readonly
          />
          <div style={{ marginTop: 20 }}>
            <Typography.Text>{t('EditUser.EditUser25')}</Typography.Text>
          </div>
          <Input
            name='email'
            value={email}
            autoComplete='new-password'
            placeholder={t('EditUser.EditUser26')}
            readonly
          />
        </Spin>

      </SideSheet>
    </>
  );
};

export default EditUser;

