import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { UserContext } from '../context/User';

import { API, getLogo, getSystemName, isAdmin, isAgent, isMobile } from '../helpers';
import '../index.css';

import { Nav, Layout } from '@douyinfe/semi-ui';
import * as Icons from '@douyinfe/semi-icons';

const IconComponent = ({ name }) => {
    if (!name) return '-';
    const IconsComponent = Icons[name];
    return <IconsComponent />
}

const SiderBar = () => {
    const [userState, userDispatch] = useContext(UserContext);
    const [selectedKeys, setSelectedKeys] = useState(['home']);
    const [showSidebar, setShowSidebar] = useState(false);
    const systemName = getSystemName();
    const logo = getLogo();
    const [headerButtons, setHeaderButtons] = useState([])

    const COMPONENT_LIST = {
        'home': {
            itemkey: 'home'
        },
        'channel': {
            itemKey: 'channel',
            className: isAdmin() ? 'semi-navigation-item-normal' : 'tableHiddle'
        },
        'token': {
            itemKey: 'token',
            className: 'driver-step1'
        },
        'redemption': {
            itemKey: 'redemption',
            className: isAdmin() ? 'semi-navigation-item-normal' : 'tableHiddle'
        },
        'user': {
            itemKey: 'user',
            className: isAgent() ? 'semi-navigation-item-normal' : 'tableHiddle'
        },
        'setting': {
            itemKey: 'setting'
        },
        'topup': {
            itemKey: 'topup',
            className: 'driver-step6'
        },
        'log': {
            itemKey: 'log',
            className: 'driver-step12'
        },
        'detail': {
            itemKey: 'detail',
            className: localStorage.getItem('enable_data_export') === 'true' ? 'semi-navigation-item-normal' : 'tableHiddle'
        },
        'midjourney': {
            itemKey: 'midjourney',
            className: 'driver-step9'
        },
        'about': {
            itemKey: 'about',
            className: 'driver-step10'
        },
        'chat': {
            itemKey: 'chat',
            className: localStorage.getItem('chat_link') ? 'semi-navigation-item-normal driver-step5' : 'tableHiddle'
        },
        'agent': {
            itemKey: 'agent',
            className: 'driver-step11'
        },
        'models': {
            itemKey: 'models',
        }
    };

    const setMeun = (data) => {
        const newNavs = data.map((e) => {
            const value = COMPONENT_LIST[e.key]
            if (value) {
                return {
                    ...value,
                    text: e['menu_name'],
                    to: e['menu_path'],
                    icon: <IconComponent name={e['menu_icon']} />,
                    className: `${value.className} ${!e.show ? 'tableHiddle' : ''} ${e['need_auth'] && !userState.user ? 'tableHiddle' : ''}`,
                    jump: e.jump
                }
            } else {
                return {
                    itemKey: e.key,
                    text: e['menu_name'],
                    to: e['menu_path'],
                    icon: <IconComponent name={e['menu_icon']} />,
                    className: `${!e.show ? 'tableHiddle' : ''}  ${e['need_auth'] && !userState.user ? 'tableHiddle' : ''}`,
                    jump: e.jump

                }
            }
        })
        setHeaderButtons(newNavs);
    }

    useEffect(() => {
        if (userState.meun) {
            setMeun(userState.meun);
        }
    }, [userState])


    return (
        <>
            <div style={{ height: '100%' }}>
                <Nav
                    className='siderbar-nav'
                    defaultIsCollapsed={isMobile()}
                    selectedKeys={selectedKeys}
                    renderWrapper={({ itemElement, isSubNav, isInSubNav, props }) => {
                        return (
                            <Link
                                style={{ textDecoration: "none" }}
                                to={props.to}
                                onClick={e => {
                                    if (props.jump) {
                                        e.preventDefault();
                                        window.open(props.to, '_blank')
                                    }
                                }}
                            >
                                {itemElement}
                            </Link>
                        );
                    }}
                    items={headerButtons}
                    onSelect={key => {
                        setSelectedKeys([key.itemKey]);
                    }}
                    header={{
                        logo: <img src={logo} alt='logo' style={{ marginRight: '0.75em' }} />,
                        text: systemName,
                    }}
                >

                    <Nav.Footer collapseButton={true}>
                    </Nav.Footer>
                </Nav>
            </div>
        </>
    );
};



export default SiderBar;