import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { UserContext } from '../context/User';
import { API, getLogo, getSystemName, showSuccess } from '../helpers';
import '../index.css';
import { useTranslation } from 'react-i18next';
import fireworks from 'react-fireworks';
import { Icon } from 'semantic-ui-react';
import {
    IconKey,
    IconUser,
    IconTextRectangle,
    IconHelpCircle,
} from '@douyinfe/semi-icons';
import { Nav, Avatar, Dropdown, Layout, Switch } from '@douyinfe/semi-ui';
import { stringToColor } from "../helpers/render";

const IconExampleDisabled = () => <Icon name='language' style={{ fontSize: 22, color: '#666', transform: 'translateY(2px)' }} />

// HeaderBar Buttons
let headerButtons = [
    {
        text: '关于',
        itemKey: 'about',
        to: '/about',
        icon: <IconHelpCircle />
    },
];

if (localStorage.getItem('chat_link')) {
    headerButtons.splice(1, 0, {
        name: '聊天',
        to: '/chat',
        icon: 'comments'
    });
}

const HeaderBar = () => {
    const { t } = useTranslation();
    const options = [
        { key: 'zh', text: "中文", value: 'zh' },
        { key: 'en', text: "English", value: 'en' },
        { key: 'ko', text: "한국어", value: 'ko' },
        { key: 'ja', text: "日本語", value: 'ja' },
    ]

    const [userState, userDispatch] = useContext(UserContext);
    let navigate = useNavigate();

    const [showSidebar, setShowSidebar] = useState(false);
    const [dark, setDark] = useState(false);
    const systemName = getSystemName();
    const logo = getLogo();
    var themeMode = localStorage.getItem('theme-mode');
    const currentDate = new Date();
    const isNewYear = currentDate.getMonth() === 0 && currentDate.getDate() === 1;

    async function logout() {
        setShowSidebar(false);
        await API.get('/api/user/logout');
        showSuccess('注销成功!');
        userDispatch({ type: 'logout' });
        localStorage.removeItem('user');
        navigate('/login');
    }

    const handleNewYearClick = () => {
        fireworks.init("root", {});
        fireworks.start();
        setTimeout(() => {
            fireworks.stop();
            setTimeout(() => {
                window.location.reload();
            }, 10000);
        }, 3000);
    };

    useEffect(() => {
        if (themeMode === 'dark') {
            switchMode(true);
        }
        if (isNewYear) {
            console.log('Happy New Year!');
        }
    }, []);

    const switchMode = (model) => {
        const body = document.body;
        if (!model) {
            body.removeAttribute('theme-mode');
            localStorage.setItem('theme-mode', 'light');
        } else {
            body.setAttribute('theme-mode', 'dark');
            localStorage.setItem('theme-mode', 'dark');
        }
        setDark(model);
    };

    return (
        <>
            <Layout>
                <div style={{ width: '100%' }}>
                    <Nav
                        mode={'horizontal'}
                        // bodyStyle={{ height: 100 }}
                        renderWrapper={({ itemElement, isSubNav, isInSubNav, props }) => {
                            const routerMap = {
                                about: "/about",
                                login: "/login",
                                register: "/register",
                            };
                            return (
                                <Link
                                    style={{ textDecoration: "none" }}
                                    to={routerMap[props.itemKey]}
                                >
                                    {itemElement}
                                </Link>
                            );
                        }}
                        selectedKeys={[]}
                        // items={headerButtons}
                        onSelect={key => {

                        }}
                        footer={
                            <>
                                <LanguageCom options={options} />
                                {isNewYear &&
                                    // happy new year
                                    <Dropdown
                                        position="bottomRight"
                                        render={
                                            <Dropdown.Menu>
                                                <Dropdown.Item onClick={handleNewYearClick}>Happy New Year!!!</Dropdown.Item>
                                            </Dropdown.Menu>
                                        }
                                    >
                                        <Nav.Item itemKey={'new-year'} text={'🏮'} />
                                    </Dropdown>
                                }
                                <Nav.Item itemKey={'about'} icon={<IconHelpCircle />} />
                                {userState.user ?
                                    <>
                                        <Dropdown
                                            position="bottomRight"
                                            render={
                                                <Dropdown.Menu>
                                                    <Dropdown.Item onClick={logout}>退出</Dropdown.Item>
                                                </Dropdown.Menu>
                                            }
                                        >
                                            <Avatar size="small" color={stringToColor(userState.user.username)} style={{ margin: 4 }}>
                                                {userState.user.username[0]}
                                            </Avatar>
                                            <span>{userState.user.username}</span>
                                        </Dropdown>
                                    </>
                                    :
                                    <>
                                        <Nav.Item itemKey={'login'} text={t('common.login')} icon={<IconKey />} />
                                        <Nav.Item itemKey={'register'} text={t('common.register')} icon={<IconUser />} />
                                    </>
                                }
                            </>
                        }
                    >
                    </Nav>
                </div>
            </Layout>
        </>
    );
};


const LanguageCom = (props) => {
    const { t } = useTranslation();
    const [active, setActive] = useState('zh')
    const handleClick = (key) => {
        localStorage.setItem('language', key)
        window.location.reload()
    }

    useEffect(() => {
        const language = localStorage.getItem('language') || navigator.language
        if (language) {
            setActive(language)
        }
    }, [])

    return (
        <Dropdown
            render={
                <Dropdown.Menu>
                    {
                        props.options.map(v => {
                            return (
                                <Dropdown.Item onClick={() => handleClick(v.key)} className={`language-change-item ${active === v.key ? 'language-change-item-active' : ''}`} key={v.key}>
                                    <div>{v.text}</div>
                                </ Dropdown.Item >
                            )
                        })
                    }
                </Dropdown.Menu>
            }
        >
            <span><IconExampleDisabled /></span>
        </Dropdown>
    )
}

export default HeaderBar;
