import React, {useEffect, useRef, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {API, isMobile, showError, showInfo, showSuccess, verifyJSON} from '../../helpers';
import {CHANNEL_OPTIONS} from '../../constants';
import Title from "@douyinfe/semi-ui/lib/es/typography/title";
import {SideSheet, Space, Spin, Button, Input, Typography, Select, TextArea, Checkbox, Banner} from "@douyinfe/semi-ui";

import { useTranslation } from 'react-i18next';

const MODEL_MAPPING_EXAMPLE = {
    'gpt-3.5-turbo-0301': 'gpt-3.5-turbo',
    'gpt-4-0314': 'gpt-4',
    'gpt-4-32k-0314': 'gpt-4-32k'
};


const EditChannel = (props) => {
    const navigate = useNavigate();
    const channelId = props.editingChannel.id;
    const isEdit = channelId !== undefined;
    const [loading, setLoading] = useState(isEdit);
    const handleCancel = () => {
        props.handleClose()
    };
    const originInputs = {
        name: '',
        type: 1,
        key: '',
        openai_organization: '',
        base_url: '',
        other: '',
        model_mapping: '',
        models: [],
        auto_ban: 1,
        test_model: '',
        groups: ['default'],
        params: '',
        support_tools: null,
    };
    const [batch, setBatch] = useState(false);
    const [autoBan, setAutoBan] = useState(true);
    // const [autoBan, setAutoBan] = useState(true);
    const [inputs, setInputs] = useState(originInputs);
    const [originModelOptions, setOriginModelOptions] = useState([]);
    const [modelOptions, setModelOptions] = useState([]);
    const [groupOptions, setGroupOptions] = useState([]);
    const [basicModels, setBasicModels] = useState([]);
    const [fullModels, setFullModels] = useState([]);
    const [customModel, setCustomModel] = useState('');
    const { t } = useTranslation();
    const handleInputChange = (name, value) => {
        setInputs((inputs) => ({...inputs, [name]: value}));
        if (name === 'type' && inputs.models.length === 0) {
            let localModels = [];
            switch (value) {
                case 14:
                    localModels = ['claude-instant-1', 'claude-2'];
                    break;
                case 11:
                    localModels = ['PaLM-2'];
                    break;
                case 15:
                    localModels = ['ERNIE-Bot', 'ERNIE-Bot-turbo', 'ERNIE-Bot-4', 'Embedding-V1'];
                    break;
                case 17:
                    localModels = ['qwen-turbo', 'qwen-plus', 'text-embedding-v1'];
                    break;
                case 16:
                    localModels = ['chatglm_pro', 'chatglm_std', 'chatglm_lite'];
                    break;
                case 18:
                    localModels = ['SparkDesk'];
                    break;
                case 19:
                    localModels = ['360GPT_S2_V9', 'embedding-bert-512-v1', 'embedding_s1_v1', 'semantic_similarity_s1_v1'];
                    break;
                case 23:
                    localModels = ['hunyuan'];
                    break;
                case 24:
                    localModels = ['gemini-pro'];
                    break;
            }
            setInputs((inputs) => ({...inputs, models: localModels}));
        }
        //setAutoBan
    };


    const loadChannel = async () => {
        setLoading(true)
        let res = await API.get(`/api/channel/${channelId}`);
        const {success, message, data} = res.data;
        if (success) {
            if (data.models === '') {
                data.models = [];
            } else {
                data.models = data.models.split(',');
            }
            if (data.group === '') {
                data.groups = [];
            } else {
                data.groups = data.group.split(',');
            }
            if (data.model_mapping !== '') {
                data.model_mapping = JSON.stringify(JSON.parse(data.model_mapping), null, 2);
            }
            setInputs(data);
            if (data.auto_ban === 0) {
                setAutoBan(false);
            } else {
                setAutoBan(true);
            }
            // console.log(data);
        } else {
            showError(message);
        }
        setLoading(false);
    };

    const fetchModels = async () => {
        try {
            let res = await API.get(`/api/channel/models`);
            let localModelOptions = res.data.data.map((model) => ({
                label: model,
                value: model
            }));
            setOriginModelOptions(localModelOptions);
            setFullModels(res.data.data.map((model) => model.id));
            setBasicModels(res.data.data.filter((model) => {
                return model.startsWith('gpt-3') || model.startsWith('text-');
            }).map((model) => model.id));
        } catch (error) {
            showError(error.message);
        }
    };

    const fetchGroups = async () => {
        try {
            let res = await API.get(`/api/group/`);
            setGroupOptions(res.data.data.map((group) => ({
                label: group,
                value: group
            })));
        } catch (error) {
            showError(error.message);
        }
    };

    useEffect(() => {
        let localModelOptions = [...originModelOptions];
        inputs.models.forEach((model) => {
            if (!localModelOptions.find((option) => option.key === model)) {
                localModelOptions.push({
                    label: model,
                    value: model
                });
            }
        });
        setModelOptions(localModelOptions);
    }, [originModelOptions, inputs.models]);

    useEffect(() => {
        fetchModels().then();
        fetchGroups().then();
        if (isEdit) {
            loadChannel().then(
                () => {

                }
            );
        } else {
            setInputs(originInputs)
        }
    }, [props.editingChannel.id]);


    const submit = async () => {
        if (!isEdit && (inputs.name === '' || inputs.key === '')) {
            showInfo(t('EditChannel.EditChannel1'));
            return;
        }
        if (inputs.models.length === 0) {
            showInfo(t('EditChannel.EditChannel2'));
            return;
        }
        if (inputs.model_mapping !== '' && !verifyJSON(inputs.model_mapping)) {
          showInfo(t('EditChannel.EditChannel3'));
            return;
        }
        let localInputs = {...inputs};
        if (localInputs.base_url && localInputs.base_url.endsWith('/')) {
            localInputs.base_url = localInputs.base_url.slice(0, localInputs.base_url.length - 1);
        }
        if (localInputs.type === 3 && localInputs.other === '') {
            localInputs.other = '2023-06-01-preview';
        }
        if (localInputs.type === 18 && localInputs.other === '') {
            localInputs.other = 'v2.1';
        }
        let res;
        if (!Array.isArray(localInputs.models)) {
            showError(t('EditChannel.EditChannel4'));
            handleCancel();
            return;
        }
        localInputs.models = localInputs.models.join(',');
        localInputs.group = localInputs.groups.join(',');
        if (isEdit) {
            res = await API.put(`/api/channel/`, {...localInputs, id: parseInt(channelId)});
        } else {
            res = await API.post(`/api/channel/`, localInputs);
        }
        const {success, message} = res.data;
        if (success) {
            if (isEdit) {
                showSuccess(t('EditChannel.EditChannel5'));
            } else {
                showSuccess(t('EditChannel.EditChannel6'));
                setInputs(originInputs);
            }
            props.refresh();
            props.handleClose();
        } else {
            showError(message);
        }
    };

    const addCustomModel = () => {
        if (customModel.trim() === '') return;
        if (inputs.models.includes(customModel)) return;
        let localModels = [...inputs.models];
        localModels.push(customModel);
        let localModelOptions = [];
        localModelOptions.push({
            key: customModel,
            text: customModel,
            value: customModel
        });
        setModelOptions(modelOptions => {
            return [...modelOptions, ...localModelOptions];
        });
        setCustomModel('');
        handleInputChange('models', localModels);
    };

  const handleItemDoubleClick = (event, data) => {
    navigator.clipboard.writeText(event.target.innerText);
    showInfo(t('EditChannel.EditChannel7'));
  };

  const type2secretPrompt = (type) => {
        // inputs.type === 15 ? '按照如下格式输入：APIKey|SecretKey' : (inputs.type === 18 ? '按照如下格式输入：APPID|APISecret|APIKey' : '请输入渠道对应的鉴权密钥')
        switch (type) {
            case 15:
                return t('EditChannel.EditChannel8');
            case 18:
                return t('EditChannel.EditChannel9');
            case 22:
                return t('EditChannel.EditChannel10');
            case 23:
                return t('EditChannel.EditChannel11');
            default:
                return t('EditChannel.EditChannel12');
        }
    }

  return (
        <>
            <SideSheet
                maskClosable={false}
                placement={isEdit ? 'right' : 'left'}
                title={<Title level={3}>{isEdit ? t('EditChannel.EditChannel13') : t('EditChannel.EditChannel14')}</Title>}
                headerStyle={{borderBottom: '1px solid var(--semi-color-border)'}}
                bodyStyle={{borderBottom: '1px solid var(--semi-color-border)'}}
                visible={props.visible}
                footer={
                    <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                        <Space>
                            <Button theme='solid' size={'large'} onClick={submit}>{t('EditChannel.EditChannel55')}</Button>
                            <Button theme='solid' size={'large'} type={'tertiary'} onClick={handleCancel}>{t('EditChannel.EditChannel54') }</Button>
                        </Space>
                    </div>
                }
                closeIcon={null}
                onCancel={() => handleCancel()}
                width={isMobile() ? '100%' : 600}
            >
                <Spin spinning={loading}>
                    <div style={{marginTop: 10}}>
                        <Typography.Text strong>{t('EditChannel.EditChannel15')}</Typography.Text>
                    </div>
                    <Select
                        name='type'
                        required
                        optionList={CHANNEL_OPTIONS}
                        value={inputs.type}
                        onChange={value => handleInputChange('type', value)}
                        style={{width: '50%'}}
                    />
                    {
                        inputs.type === 3 && (
                            <>
                                <div style={{marginTop: 10}}>
                                    <Banner type={"warning"} description={
                                        <>
                                        {t('EditChannel.EditChannel16')}<strong>{t('EditChannel.EditChannel17') }</strong>，{t('EditChannel.EditChannel18')}
                                        {t('EditChannel.EditChannel19') }<a target='_blank'
                                                                            href='https://github.com/songquanpeng/one-api/issues/133?notification_referrer_id=NT_kwDOAmJSYrM2NjIwMzI3NDgyOjM5OTk4MDUw#issuecomment-1571602271' rel="noreferrer">{t('EditChannel.EditChannel20') }</a>
                                        </>
                                    }>
                                    </Banner>
                                </div>
                                <div style={{marginTop: 10}}>
                                    <Typography.Text strong>AZURE_OPENAI_ENDPOINT：</Typography.Text>
                                </div>
                                <Input
                                    label='AZURE_OPENAI_ENDPOINT'
                                    name='azure_base_url'
                                    placeholder={t('EditChannel.EditChannel21')}
                                    onChange={value => {
                                        handleInputChange('base_url', value)
                                    }}
                                    value={inputs.base_url}
                                    autoComplete='new-password'
                                />
                                <div style={{marginTop: 10}}>
                                    <Typography.Text strong>{t('EditChannel.EditChannel22')}</Typography.Text>
                                </div>
                                <Input
                                    label={t('EditChannel.EditChannel22')}
                                    name='azure_other'
                                    placeholder={t('EditChannel.EditChannel23')}
                                    onChange={value => {
                                        handleInputChange('other', value)
                                    }}
                                    value={inputs.other}
                                    autoComplete='new-password'
                                />
                            </>
                        )
                    }
                    {
                        inputs.type === 8 && (
                            <>
                                <div style={{marginTop: 10}}>
                                    <Typography.Text strong>Base URL：</Typography.Text>
                                </div>
                                <Input
                                    name='base_url'
                                    placeholder={t('EditChannel.EditChannel24')}
                                    onChange={value => {
                                        handleInputChange('base_url', value)
                                    }}
                                    value={inputs.base_url}
                                    autoComplete='new-password'
                                />
                            </>
                        )
                    }
                    <div style={{marginTop: 10}}>
                        <Typography.Text strong>{t('EditChannel.EditChannel57')}</Typography.Text>
                    </div>
                    <Input
                        required
                        name='name'
                        placeholder={t('EditChannel.EditChannel25')}
                        onChange={value => {
                            handleInputChange('name', value)
                        }}
                        value={inputs.name}
                        autoComplete='new-password'
                    />
                    <div style={{marginTop: 10}}>
                        <Typography.Text strong>{t('EditChannel.EditChannel58')}</Typography.Text>
                    </div>
                    <Select
                        placeholder={t('EditChannel.EditChannel26')}
                        name='groups'
                        required
                        multiple
                        selection
                        allowAdditions
                        additionLabel={t('EditChannel.EditChannel27')}
                        onChange={value => {
                            handleInputChange('groups', value)
                        }}
                        value={inputs.groups}
                        autoComplete='new-password'
                        optionList={groupOptions}
                    />
                    {
                        inputs.type === 18 && (
                            <>
                                <div style={{marginTop: 10}}>
                                    <Typography.Text strong>{t('EditChannel.EditChannel28')}</Typography.Text>
                                </div>
                                <Input
                                    name='other'
                                    placeholder={t('EditChannel.EditChannel29')}
                                    onChange={value => {
                                        handleInputChange('other', value)
                                    }}
                                    value={inputs.other}
                                    autoComplete='new-password'
                                />
                            </>
                        )
                    }
                    {
                        inputs.type === 21 && (
                            <>
                                <div style={{marginTop: 10}}>
                                    <Typography.Text strong>{t('EditChannel.EditChannel30')}</Typography.Text>
                                </div>
                                <Input
                                    label={t('EditChannel.EditChannel30')}
                                    name='other'
                                    placeholder={t('EditChannel.EditChannel31')}
                                    onChange={value => {
                                        handleInputChange('other', value)
                                    }}
                                    value={inputs.other}
                                    autoComplete='new-password'
                                />
                            </>
                        )
                    }
                    <div style={{marginTop: 10}}>
                        <Typography.Text strong>{t('EditChannel.EditChannel32')}</Typography.Text>
                    </div>
                    <Select
                        placeholder={t('EditChannel.EditChannel33')}
                        name='models'
                        required
                        multiple
                        selection
                        onChange={value => {
                            handleInputChange('models', value)
                        }}
                        value={inputs.models}
                        autoComplete='new-password'
                        optionList={modelOptions}
                        onDoubleClick={handleItemDoubleClick}
                    />
                    <div style={{lineHeight: '40px', marginBottom: '12px'}}>
                        <Space>
                            <Button type='primary' onClick={() => {
                                handleInputChange('models', basicModels);
                            }}>{t('EditChannel.EditChannel34') }</Button>
                            <Button type='secondary' onClick={() => {
                                handleInputChange('models', fullModels);
                            }}>{t('EditChannel.EditChannel35') }</Button>
                            <Button type='warning' onClick={() => {
                                handleInputChange('models', []);
                            }}>{t('EditChannel.EditChannel36')}</Button>
                        </Space>
                        <Input
                            addonAfter={
                                <Button type='primary' onClick={addCustomModel}>{t('EditChannel.EditChannel37') }</Button>
                            }
                            placeholder={t('EditChannel.EditChannel38')}
                            value={customModel}
                            onChange={(value) => {
                                setCustomModel(value);
                            }}
                        />
                    </div>
                    <div style={{marginTop: 10}}>
                        <Typography.Text strong>{t('EditChannel.EditChannel39')}</Typography.Text>
                    </div>
                    <TextArea
                        placeholder={t('EditChannel.EditChannel40', {data: JSON.stringify(MODEL_MAPPING_EXAMPLE, null, 2)})}
                        name='model_mapping'
                        onChange={value => {
                            handleInputChange('model_mapping', value)
                        }}
                        autosize
                        value={inputs.model_mapping}
                        autoComplete='new-password'
                    />
                    <Typography.Text style={{
                        color: 'rgba(var(--semi-blue-5), 1)',
                        userSelect: 'none',
                        cursor: 'pointer'
                    }} onClick={
                        () => {
                            handleInputChange('model_mapping', JSON.stringify(MODEL_MAPPING_EXAMPLE, null, 2))
                        }
                    }>
                        {t('EditChannel.EditChannel59')}
                    </Typography.Text>
                    <div style={{marginTop: 10}}>
                        <Typography.Text strong>{t('EditChannel.EditChannel43')}</Typography.Text>
                    </div>
                    {
                        batch ?
                            <TextArea
                                name='key'
                                required
                                placeholder={t('EditChannel.EditChannel42')}
                                onChange={value => {
                                    handleInputChange('key', value)
                                }}
                                value={inputs.key}
                                style={{minHeight: 150, fontFamily: 'JetBrains Mono, Consolas'}}
                                autoComplete='new-password'
                            />
                            :
                            <Input
                                name='key'
                                required
                                placeholder={type2secretPrompt(inputs.type)}
                                onChange={value => {
                                    handleInputChange('key', value)
                                }}
                                value={inputs.key}
                                autoComplete='new-password'
                            />
                    }
                    <div style={{marginTop: 10}}>
                        <Typography.Text strong>{t('EditChannel.EditChannel44')}</Typography.Text>
                    </div>
                    <Input
                        name='openai_organization'
                        placeholder={t('EditChannel.EditChannel45')}
                        onChange={value => {
                            handleInputChange('openai_organization', value)
                        }}
                        value={inputs.openai_organization}
                    />
                  <div style={{marginTop: 10}}>
                    <Typography.Text strong>{t('EditChannel.EditChannel46')}</Typography.Text>
                  </div>
                  <Input
                      name='test_model'
                      placeholder={t('EditChannel.EditChannel47')}
                      onChange={value => {
                        handleInputChange('test_model', value)
                      }}
                      value={inputs.test_model}
                  />

                    <div style={{marginTop: 10, display: 'flex'}}>
                        <Space>
                            <Checkbox
                                name='auto_ban'
                                checked={autoBan}
                                onChange={
                                    () => {
                                        setAutoBan(!autoBan);
                                    }
                                }
                            />
                            <Typography.Text
                                strong>{t('EditChannel.EditChannel48')}</Typography.Text>
                        </Space>
                    </div>

                    {
                        !isEdit && (
                            <div style={{marginTop: 10, display: 'flex'}}>
                                <Space>
                                    <Checkbox
                                        checked={batch}
                                        label={t('EditChannel.EditChannel49')}
                                        name='batch'
                                        onChange={() => setBatch(!batch)}
                                    />
                                    <Typography.Text strong>{t('EditChannel.EditChannel49')}</Typography.Text>
                                </Space>
                            </div>
                        )
                    }
                    {
                        inputs.type !== 3 && inputs.type !== 8 && inputs.type !== 22 && (
                            <>
                                <div style={{marginTop: 10}}>
                                    <Typography.Text strong>{t('EditChannel.EditChannel50')}</Typography.Text>
                                </div>
                                <Input
                                    name='base_url'
                                    placeholder={t('EditChannel.EditChannel51')}
                                    onChange={value => {
                                        handleInputChange('base_url', value)
                                    }}
                                    value={inputs.base_url}
                                    autoComplete='new-password'
                                />
                            </>
                        )
                    }
                    {
                        inputs.type === 22 && (
                            <>
                                <div style={{marginTop: 10}}>
                                    <Typography.Text strong>{t('EditChannel.EditChannel52')}</Typography.Text>
                                </div>
                                <Input
                                    name='base_url'
                                    placeholder={t('EditChannel.EditChannel53')}
                                    onChange={value => {
                                        handleInputChange('base_url', value)
                                    }}
                                    value={inputs.base_url}
                                    autoComplete='new-password'
                                />
                            </>
                        )
                    }
                    <div style={{marginTop: 10}}>
                        <Typography.Text strong>渠道参数</Typography.Text>
                    </div>
                    <TextArea
                        placeholder='渠道参数，需要查看文档'
                        name='params'
                        onChange={value => {
                            handleInputChange('params', value)
                        }}
                        autosize
                        value={inputs.params}
                        autoComplete='new-password'
                    />

                </Spin>
            </SideSheet>
        </>
    );
};

export default EditChannel;
