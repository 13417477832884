/*
 * @Author: heyan
 * @Date: 2023-12-26 23:41:53
 * @LastEditors: heyan
 * @LastEditTime: 2024-03-27 18:03:55
 * @Description: 
 */
import React from 'react';
import LogsTable from '../../components/LogsTable';
import { Layout, Card } from "@douyinfe/semi-ui";
import { isMobile } from "../../helpers";

const layoutType = localStorage.getItem("layout_type");
const showSider = isMobile() || layoutType === '0';

const Token = () => (
  <Layout>
    {
      !showSider &&
      <Layout.Header style={{ textAlign: 'center', margin: '24px 0px' }}>
        <h3>日志</h3>
      </Layout.Header>
    }
    <Layout.Content>
      <Card style={{ maxWidth: '1800px', minHeight: '580px', margin: 'auto' }}>
        <LogsTable />
      </Card>
    </Layout.Content>
  </Layout>
);

export default Token;
