

import ReactMarkdown from 'react-markdown';
const Custom = ({url}) => {
    console.log(url)
    return  <>
        {
            url ? (url.startsWith('http://') || url.startsWith('https://')) ? <iframe style={{width: '100%', height: '100%', border: 'none'}} src={url}></iframe> : <div style={{ fontSize: 'larger' }} dangerouslySetInnerHTML={{ __html: url }}></div> : ''
        }
    </>
}

export default Custom