import React, { useEffect, useState } from 'react';
import { API, getPrice, isAdmin, isAgent, showError, showSuccess, timestamp2string } from '../helpers';
import { Button, Modal, Popconfirm, Popover, Table, Tag, Form, Tooltip, Space, Select, Dropdown } from "@douyinfe/semi-ui";
import { ITEMS_PER_PAGE } from '../constants';
import { renderGroup, renderNumber, renderQuota, renderText, stringToColor } from '../helpers/render';
import AddUser from "../pages/User/AddUser";
import EditUser from "../pages/User/EditUser";
import { useTranslation } from 'react-i18next';
import { IconChevronDown } from '@douyinfe/semi-icons';


const UsersTable = () => {
  const isAdminUser = isAdmin()
  const isAgentUser = isAgent()
  const price = getPrice()
  const [agentDomain, setAgentDomain] = useState("")
  const [updateKey, setUpdateKey] = useState(1)
  const { t } = useTranslation()
  const columns = [{
    title: 'ID', dataIndex: 'id',
  }, {
    title: t('UsersTable.UsersTable28'), dataIndex: 'username',
  }, {
    title: t('UsersTable.UsersTable29'), dataIndex: 'group', render: (text, record, index) => {
      return (<div>
        {renderGroup(text)}
      </div>);
    },
  }, {
    title: t('UsersTable.UsersTable12'), dataIndex: 'info', render: (text, record, index) => {
      return (<div>
        <Space spacing={1}>
          <Tooltip content={t('UsersTable.UsersTable17')}>
            <Tag color='white' size='large'>{renderQuota(record.quota)}</Tag>
          </Tooltip>
          <Tooltip content={t('UsersTable.UsersTable18')}>
            <Tag color='white' size='large'>{renderQuota(record.used_quota)}</Tag>
          </Tooltip>
          <Tooltip content={t('UsersTable.UsersTable30')}>
            <Tag color='white' size='large'>{renderNumber(record.request_count)}</Tag>
          </Tooltip>
        </Space>
      </div>);
    }
  }, {
    title: '邀请信息', dataIndex: 'invite', render: (text, record, index) => {
      return (<div>
        <Space spacing={1}>
          <Tooltip content={t('UsersTable.UsersTable31')}>
            <Tag color='white' size='large'>{renderNumber(record.aff_count)}</Tag>
          </Tooltip>
          <Tooltip content={t('UsersTable.UsersTable32')}>
            <Tag color='white' size='large'>{renderQuota(record.aff_history_quota)}</Tag>
          </Tooltip>
          <Tooltip content={t('UsersTable.UsersTable33')}>
            {record.inviter_id === 0 ? <Tag color='white' size='large'>{t('common.null')}</Tag> :
              <Tag color='white' size='large'>{record.inviter_id}</Tag>}
          </Tooltip>
        </Space>
      </div>);
    }
  }, {
    title: t('UsersTable.UsersTable34'),
    dataIndex: 'role', render: (text, record, index) => {
      return (<div>
        {renderRole(text)}
      </div>);
    },
  }, {
    title: t('UsersTable.UsersTable14'), dataIndex: 'status', render: (text, record, index) => {
      return (<div>
        {renderStatus(text)}
      </div>);
    },
  }, {
    title: "销售信息", dataIndex: 'order_num', render: (text, record, index) => {
      return (<div>
        <Space spacing={1}>
          <Tooltip content={"售价"}>
            <Tag color='white' size='large'>{record.exclusive_price ? record.exclusive_price : price}</Tag>
          </Tooltip>
          <Tooltip content={"订单数"}>
            <Tag color='white' size='large'>{record.order_num}</Tag>
          </Tooltip>
          {
            isAdminUser && (
              <Tooltip content={"上级代理"}>
                {record.agent_user_id === 0 ? <Tag color='white' size='large'>{t('common.null')}</Tag> :
                  <Tag color='white' size='large'>{record.agent_user_id}</Tag>}
              </Tooltip>
            )
          }
        </Space>
      </div>);
    },
  },
  {
    title: "Email", dataIndex: 'email'
  },
  {
    title: "注册时间", dataIndex: 'created_at', render: (text, record, index) => {
      return (<div>
        {text ? timestamp2string(text) : "-"}
      </div>);
    }
  },
  {
    title: "登录IP", dataIndex: 'last_login_ip'
  },
  {
    title: "登录时间", dataIndex: 'last_login_time', render: (text, record, index) => {
      return (<div>
        {text ? timestamp2string(text) : "-"}
      </div>);
    }
  },
  {
    title: '', dataIndex: 'operate', render: (text, record, index) => (
      <div>
        <Dropdown
          zIndex="1000"
          render={
            <Dropdown.Menu>
              {
                record.DeletedAt !== null ? <></> : <>
                  <Dropdown.Item>
                    <Popconfirm
                      disabled={!isAdminUser}
                      title={t('UsersTable.UsersTable35')}
                      okType={'warning'}
                      content={record.role < 3 && (({ initialFocusRef }) => {
                        return <input value={agentDomain} onInput={event => {
                          setAgentDomain(event.target.value);
                        }} placeholder="请输入代理的域名" />;
                      })}
                      onConfirm={() => {
                        manageUser(record.id, 'promote', record, agentDomain)
                      }}
                    >
                      <Button disabled={!isAdminUser} theme='light' type='warning' style={{ marginRight: 1 }}>{t('UsersTable.UsersTable20')}</Button>
                    </Popconfirm>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <Popconfirm
                      disabled={!isAdminUser}
                      title={t('UsersTable.UsersTable35')}
                      okType={'warning'}
                      onConfirm={() => {
                        manageUser(record.id, 'demote', record)
                      }}
                    >
                      <Button disabled={!isAdminUser} theme='light' type='secondary' style={{ marginRight: 1 }}>{t('UsersTable.UsersTable21')}</Button>
                    </Popconfirm>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    {record.status === 1 ?
                      <Button theme='light' type='warning' style={{ marginRight: 1 }} onClick={async () => {
                        manageUser(record.id, 'disable', record)
                      }}>{t('UsersTable.UsersTable24')}</Button> :
                      <Button theme='light' type='secondary' style={{ marginRight: 1 }} onClick={async () => {
                        manageUser(record.id, 'enable', record);
                      }} disabled={record.status === 3}>{t('UsersTable.UsersTable25')}</Button>
                    }
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <Button theme='light' type='tertiary' style={{ marginRight: 1 }} onClick={() => {
                      setEditingUser(record);
                      setShowEditUser(true);
                    }}>{t('UsersTable.UsersTable26')}</Button>
                  </Dropdown.Item>
                </>
              }
              <Dropdown.Item>
                <Popconfirm
                  disabled={!isAdminUser}
                  title={t('UsersTable.UsersTable36')}
                  content={t('UsersTable.UsersTable37')}
                  okType={'danger'}
                  position={'left'}
                  onConfirm={() => {
                    manageUser(record.id, 'delete', record).then(() => {
                      removeRecord(record.id);
                    })
                  }}
                >
                  <Button disabled={!isAdminUser} theme='light' type='danger' style={{ marginRight: 1 }}>{t('UsersTable.UsersTable22')}</Button>
                </Popconfirm>
              </Dropdown.Item>
            </Dropdown.Menu>
          }
        >
          <Button theme='borderless' type='primary' icon={<IconChevronDown />} iconPosition="right">展开操作</Button>
        </Dropdown>
      </div>
    ),
  },
  ];
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activePage, setActivePage] = useState(1);
  const [userCount, setUserCount] = useState(ITEMS_PER_PAGE);
  const [showAddUser, setShowAddUser] = useState(false);
  const [showEditUser, setShowEditUser] = useState(false);
  const [editingUser, setEditingUser] = useState({
    id: undefined,
  });
  const [groupOptions, setGroupOptions] = useState([]);

  const [inputs, setInputs] = useState({
    user_id: 0,
    inviter_id: 0,
    agent_user_id: 0,
    keyword: "",
    group: "",
    order: [],
    preOrder: []
  });
  const { user_id, inviter_id, agent_user_id, keyword, order, preOrder, group } = inputs;
  const handleInputChange = (value, name) => {
    setInputs((inputs) => ({ ...inputs, [name]: value }));
  };

  const reSetting = () => {
    setInputs(prevInputs => ({
      ...prevInputs,
      user_id: 0,
      inviter_id: 0,
      agent_user_id: 0,
      keyword: "",
      group: "",
      order: [],
      preOrder: []
    }))
    setUpdateKey(updateKey + 1)
  }

  const fetchGroups = async () => {
    try {
      let res = await API.get(`/api/group/`);
      setGroupOptions(res.data.data.map((group) => ({
        label: group,
        value: group,
      })));
    } catch (error) {
      showError(error.message);
    }
  };
  useEffect(() => {
    fetchGroups().then();
  }, []);

  const setCount = (data) => {
    if (data.length >= (activePage) * ITEMS_PER_PAGE) {
      setUserCount(data.length + 1);
    } else {
      setUserCount(data.length);
    }
  }

  const removeRecord = key => {
    let newDataSource = [...users];
    if (key != null) {
      let idx = newDataSource.findIndex(data => data.id === key);

      if (idx > -1) {
        newDataSource.splice(idx, 1);
        setUsers(newDataSource);
      }
    }
  };


  const orderHanle = (v, prop) => {
    let otherProp = prop === 'order' ? 'preOrder' : 'order'; // 获取另一个属性名
    let otherValue = inputs[otherProp]; // 获取另一个数组的值
    // 移除另一个数组中的重复元素
    otherValue = otherValue.filter((item) => !v.includes(item));
    handleInputChange(v, prop);
    handleInputChange(otherValue, otherProp);
    console.log(v)
    console.log(otherValue)
    //handleInputChange(v, prop)
    //loadUsers(activePage)
  }


  const loadUsers = async (startIdx) => {
    let url = ""

    let orders = ""
    if (inputs.order || inputs.preOrder) {
      console.log("inputs", inputs)
      const orderQuery = []
      inputs.order.forEach(v => {
        orderQuery.push(v);
      })
      inputs.preOrder.forEach(v => {
        orderQuery.push("-" + v);
      })
      orders = orderQuery.join(',');
      console.log('orders', orders)
    }

    if (isAdminUser) {
      url = `/api/user/?p=${startIdx}&group=${group}&user_id=${user_id}&inviter_id=${inviter_id}&agent_user_id=${agent_user_id}&keyword=${keyword}&order=${orders}`
    } else {
      url = `/api/user/agent/?p=${startIdx}&group=${group}&user_id=${user_id}&inviter_id=${inviter_id}&agent_user_id=${agent_user_id}&keyword=${keyword}&order=${orders}`
    }

    const res = await API.get(url);
    const { success, message, data } = res.data;
    if (success) {
      if (startIdx === 0) {
        setUsers(data);
        setCount(data);
      } else {
        let newUsers = users;
        newUsers.push(...data);
        setUsers(newUsers);
        setCount(newUsers);
      }
    } else {
      showError(message);
    }
    setLoading(false);
  };

  useEffect(() => {
    loadUsers(0)
      .then()
      .catch((reason) => {
        showError(reason);
      });
  }, []);

  const manageUser = async (user_id, action, record, agent_domain) => {
    let url = ``
    if (isAdminUser) {
      url = `/api/user/manage`
    } else {
      url = `/api/user/agent/manage`
    }
    const res = await API.post(url, {
      user_id, action, agent_domain
    });
    const { success, message } = res.data;
    if (success) {
      showSuccess(t('UsersTable.UsersTable1'));
      let user = res.data.data;
      let newUsers = [...users];
      if (action === 'delete') {

      } else {
        record.status = user.status;
        record.role = user.role;
      }
      setUsers(newUsers);
    } else {
      showError(message);
    }
  };

  const renderStatus = (status) => {
    switch (status) {
      case 1:
        return <Tag size='large'>{t('UsersTable.UsersTable2')}</Tag>;
      case 2:
        return (<Tag size='large' color='red'>
          {t('UsersTable.UsersTable3')}
        </Tag>);
      default:
        return (<Tag size='large' color='grey'>
          {t('UsersTable.UsersTable4')}
        </Tag>);
    }
  };

  const handlePageChange = page => {
    setActivePage(page);
    if (page === Math.ceil(users.length / ITEMS_PER_PAGE) + 1) {
      // In this case we have to load more data and then append them.
      loadUsers(page - 1).then(r => {
      });
    }
  };

  const pageData = users.slice((activePage - 1) * ITEMS_PER_PAGE, activePage * ITEMS_PER_PAGE);

  const closeAddUser = () => {
    setShowAddUser(false);
  }

  const closeEditUser = () => {
    setShowEditUser(false);
    setEditingUser({
      id: undefined,
    });
  }

  const refresh = async () => {
    await loadUsers(activePage - 1);
  };

  const renderRole = (role) => {
    switch (role) {
      case 1:
        return <Tag>{t('UsersTable.UsersTable5')}</Tag>;
      case 3:
        return <Tag color='yellow'>{t('UsersTable.UsersTable39')}</Tag>;
      case 10:
        return <Tag color='yellow'>{t('UsersTable.UsersTable8')}</Tag>;
      case 100:
        return <Tag color='orange'>{t('UsersTable.UsersTable6')}</Tag>;
      default:
        return <Tag color='red'>{t('UsersTable.UsersTable7')}</Tag>;
    }
  }

  const orders = [
    { value: 'role', label: '角色', type: 1 },
    { value: 'quota', label: '余额', type: 2 },
    { value: 'used_quota', label: '已使用额度', type: 3 },
    { value: 'request_count', label: '请求次数', type: 4 },
    { value: 'exclusive_price', label: '销售单价', type: 5 },
    { value: 'order_num', label: '订单数', type: 6 },
    { value: 'aff_count', label: '邀请数', type: 7 },
    { value: 'aff_quota', label: '邀请剩余激励', type: 8 },
    { value: 'aff_history', label: '邀请历史激励', type: 9 },
  ]

  return (
    <>
      <AddUser refresh={refresh} visible={showAddUser} handleClose={closeAddUser}></AddUser>
      <EditUser refresh={refresh} visible={showEditUser} handleClose={closeEditUser} editingUser={editingUser}></EditUser>
      <Form layout='horizontal' key={updateKey}>
        <Form.Input
          field="keyword"
          label='查询关键字'
          style={{ width: 176, marginRight: '10px' }}
          value={inputs.keyword}
          placeholder='关键字支持id、username、email、name' name='keyword'
          onChange={value => handleInputChange(value, 'keyword')}
          onEnterPress={() => loadUsers(0)}
        />
        <Form.Input
          field="user_id"
          label='用户ID'
          style={{ width: 176, marginRight: '10px' }}
          value={inputs.user_id}
          placeholder='输入查询的用户ID' name='user_id'
          onChange={value => handleInputChange(value, 'user_id')}
          onEnterPress={() => loadUsers(0)}
        />
        <Form.Input
          field="agent_user_id"
          label='上级代理ID'
          style={{ width: 176, marginRight: '10px' }}
          value={inputs.agent_user_id}
          placeholder='输入查询的上级代理ID' name='agent_user_id'
          onChange={value => handleInputChange(value, 'agent_user_id')}
          onEnterPress={() => loadUsers(0)}
        />
        <Form.Input
          field="inviter_id"
          label='邀请人ID'
          style={{ width: 176, marginRight: '10px' }}
          value={inputs.inviter_id}
          placeholder='输入查询的邀请人ID' name='inviter_id'
          onChange={value => handleInputChange(value, 'inviter_id')}
          onEnterPress={() => loadUsers(0)}
        />
      </Form>
      <Form.Section>
        <Select
          style={{ width: '260px', marginRight: '24px', marginTop: '8px' }}
          placeholder={"正序"}
          name='order'
          filter
          remote
          multiple
          selection
          onChange={value => {
            orderHanle(value, 'order')
          }}
          value={inputs.order}
          autoComplete='new-password'
          optionList={orders}
        />
        <Select
          style={{ width: '260px', marginRight: '24px', marginTop: '8px' }}
          placeholder={"倒序"}
          name='preOrder'
          filter
          remote
          multiple
          selection
          onChange={value => {
            orderHanle(value, 'preOrder')
          }}
          value={inputs.preOrder}
          autoComplete='new-password'
          optionList={orders}
        />
        <Select
          style={{ width: '260px', marginRight: '24px', marginTop: '8px' }}
          placeholder={t('EditUser.EditUser10')}
          name='group'
          fluid
          search
          selection
          allowAdditions
          additionLabel={t('EditUser.EditUser11')}
          onChange={value => handleInputChange(value, 'group')}
          value={inputs.group}
          autoComplete='new-password'
          optionList={groupOptions}
        />
        <Button style={{ marginRight: '8px', marginTop: '8px' }} label='查询' loading={loading} type="primary" htmlType="submit" onClick={async () => {
          setActivePage(1);
          await loadUsers(0);
        }
        }>查询</Button>
        <Button style={{ marginRight: '8px', marginTop: '8px', marginLeft: '8px' }} label='重置' type="primary" className="btn-margin-right" onClick={reSetting}  >重置</Button>
      </Form.Section>

      <Table columns={columns} dataSource={pageData} pagination={{
        currentPage: activePage,
        pageSize: ITEMS_PER_PAGE,
        total: userCount,
        pageSizeOpts: [10, 20, 50, 100],
        onPageChange: handlePageChange,
      }} loading={loading} />
      <Button disabled={!isAdminUser} theme='light' type='primary' style={{ marginRight: 8 }} onClick={
        () => {
          setShowAddUser(true);
        }
      }>{t('UsersTable.UsersTable27')}</Button>
    </>
  );
};

export default UsersTable;
