export const reducer = (state, action) => {
  switch (action.type) {
    case 'login':
      return {
        ...state,
        user: action.payload
      };
    case 'logout':
      return {
        ...state,
        user: undefined
      };
    case 'meun':
      return {
        ...state,
        meun: action.payload
      };
    case 'update':
      return {
        ...state,
        update: action.payload
      };
    default:
      return state;
  }
};

export const initialState = {
  user: undefined
};