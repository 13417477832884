import React, { useEffect, useState } from 'react';
import {Button, Divider, Form, Grid, Header, Modal, Message, Radio} from 'semantic-ui-react';
import {API, isRoot, removeTrailingSlash, showError, showSuccess, verifyJSON} from '../helpers';
import { useTranslation } from 'react-i18next';


const SystemSetting = () => {
    const isRootAdmin = isRoot()
    let [inputs, setInputs] = useState({
        PasswordLoginEnabled: '',
        PasswordRegisterEnabled: '',
        EmailVerificationEnabled: '',
        GitHubOAuthEnabled: '',
        GitHubClientId: '',
        GitHubClientSecret: '',
        Notice: '',
        SMTPServer: '',
        SMTPPort: '',
        SMTPAccount: '',
        SMTPFrom: '',
        SMTPToken: '',
        ServerAddress: '',
        EpayId: '',
        EpayKey: '',
        Price: 7.3,
        MinCharge: 1,
        FeeMode: 1,
        TopupGroupRatio: '',
        TopUpCountOptions: '',
        PayEnabled: '',
        PayAddress: '',
        Footer: '',
        WeChatAuthEnabled: '',
        WeChatServerAddress: '',
        WeChatServerToken: '',
        WeChatAccountQRCodeImageURL: '',
        TurnstileCheckEnabled: '',
        TurnstileSiteKey: '',
        TurnstileSecretKey: '',
        RegisterEnabled: '',
        EmailDomainRestrictionEnabled: '',
        EmailDomainWhitelist: ''
    });
    const [originInputs, setOriginInputs] = useState({});
    let [loading, setLoading] = useState(false);
    const [EmailDomainWhitelist, setEmailDomainWhitelist] = useState([]);
    const [restrictedDomainInput, setRestrictedDomainInput] = useState('');
    const [showPasswordWarningModal, setShowPasswordWarningModal] = useState(false);
    const { t } = useTranslation();
    const TOP_UP_COUNT_OPTIONS_EXAMPLE = [
        {
            "label": t('SystemSetting.SystemSetting1'),
            "value": 1
        },
        {
            "label": t('SystemSetting.SystemSetting2'),
            "value": 30
        },
        {
            "label": t('SystemSetting.SystemSetting3'),
            "value": 10000
        }
    ];

    const getOptions = async () => {
        const res = await API.get('/api/option/');
        const { success, message, data } = res.data;
        if (success) {
            let newInputs = {};
            data.forEach((item) => {
                if (item.key === 'TopupGroupRatio') {
                    item.value = JSON.stringify(JSON.parse(item.value), null, 2);
                }
                if (item.key === 'TopUpCountOptions' && item.value) {
                    item.value = JSON.stringify(JSON.parse(item.value), null, 2);
                }
                newInputs[item.key] = item.value;
            });
            setInputs({
                ...newInputs,
                EmailDomainWhitelist: newInputs.EmailDomainWhitelist.split(',')
            });
            setOriginInputs(newInputs);

            setEmailDomainWhitelist(newInputs.EmailDomainWhitelist.split(',').map((item) => {
                return { key: item, text: item, value: item };
            }));
        } else {
            showError(message);
        }
    };

    useEffect(() => {
        getOptions().then();
    }, []);

    const updateOption = async (key, value) => {
        setLoading(true);
        switch (key) {
            case 'PasswordLoginEnabled':
            case 'PasswordRegisterEnabled':
            case 'EmailVerificationEnabled':
            case 'GitHubOAuthEnabled':
            case 'WeChatAuthEnabled':
            case 'TurnstileCheckEnabled':
            case 'EmailDomainRestrictionEnabled':
            case 'RegisterEnabled':
            case 'PayEnabled':
                value = inputs[key] === 'true' ? 'false' : 'true';
                break;
            default:
                break;
        }
        const res = await API.put('/api/option/', {
            key,
            value
        });

        const { success, message } = res.data;
        if (success) {
            if (key === 'EmailDomainWhitelist') {
                value = value.split(',');
            }
            if (key === 'Price') {
                value = parseFloat(value);
            }
            setInputs((inputs) => ({
                ...inputs, [key]: value
            }));
            showSuccess(`配置修改成功`);
        } else {
            showError(message);
        }
        setLoading(false);
    };

    const handleInputChange = async (e, { name, value }) => {
        if (name === 'PasswordLoginEnabled' && inputs[name] === 'true') {
            // block disabling password login
            setShowPasswordWarningModal(true);
            return;
        }
        if (
            name === 'Notice' ||
            name.startsWith('SMTP') ||
            name === 'ServerAddress' ||
            name === 'EpayId' ||
            name === 'EpayKey' ||
            name === 'MinCharge' ||
            name === 'FeeMode' ||
            name === 'Price' ||
            name === 'PayAddress' ||
            name === 'TopUpCountOptions' ||
            name === 'GitHubClientId' ||
            name === 'GitHubClientSecret' ||
            name === 'WeChatServerAddress' ||
            name === 'WeChatServerToken' ||
            name === 'WeChatAccountQRCodeImageURL' ||
            name === 'TurnstileSiteKey' ||
            name === 'TurnstileSecretKey' ||
            name === 'EmailDomainWhitelist' ||
            name === 'TopupGroupRatio'
        ) {
            setInputs((inputs) => ({ ...inputs, [name]: value }));
        } else {
            await updateOption(name, value);
        }
    };

    const submitServerAddress = async () => {
        let ServerAddress = removeTrailingSlash(inputs.ServerAddress);
        await updateOption('ServerAddress', ServerAddress);
    };

    const submitPayAddress = async () => {
        if (inputs.ServerAddress === '') {
            showError(t('SystemSetting.SystemSetting4'));
            return
        }
        if (originInputs['TopupGroupRatio'] !== inputs.TopupGroupRatio) {
            if (!verifyJSON(inputs.TopupGroupRatio)) {
                showError(t('SystemSetting.SystemSetting5'));
                return;
            }
            await updateOption('TopupGroupRatio', inputs.TopupGroupRatio);
        }
        if (originInputs['TopUpCountOptions'] !== inputs.TopUpCountOptions) {
            if (!verifyJSON(inputs.TopUpCountOptions)) {
                showError(t('SystemSetting.SystemSetting6'));
                return;
            }
            await updateOption('TopUpCountOptions', inputs.TopUpCountOptions);
        }

        if (originInputs['PayAddress'] !== inputs.PayAddress) {
            let PayAddress = removeTrailingSlash(inputs.PayAddress);
            await updateOption('PayAddress', PayAddress);
        }

        if (originInputs['EpayId'] !== inputs.EpayId) {
            await updateOption('EpayId', inputs.EpayId);
        }

        if (originInputs['EpayKey'] !== inputs.EpayKey) {
            await updateOption('EpayKey', inputs.EpayKey);
        }
        if (originInputs['Price'] !== inputs.Price) {
            await updateOption('Price', "" + inputs.Price);
        }
        if (originInputs['MinCharge'] !== inputs.MinCharge) {
            await updateOption('MinCharge', "" + inputs.MinCharge);
        }
        if (originInputs['FeeMode'] !== inputs.FeeMode) {
            await updateOption('FeeMode', "" + inputs.FeeMode);
        }
    };

    const submitSMTP = async () => {
        if (originInputs['SMTPServer'] !== inputs.SMTPServer) {
            await updateOption('SMTPServer', inputs.SMTPServer);
        }
        if (originInputs['SMTPAccount'] !== inputs.SMTPAccount) {
            await updateOption('SMTPAccount', inputs.SMTPAccount);
        }
        if (originInputs['SMTPFrom'] !== inputs.SMTPFrom) {
            await updateOption('SMTPFrom', inputs.SMTPFrom);
        }
        if (
            originInputs['SMTPPort'] !== inputs.SMTPPort &&
            inputs.SMTPPort !== ''
        ) {
            await updateOption('SMTPPort', inputs.SMTPPort);
        }
        if (
            originInputs['SMTPToken'] !== inputs.SMTPToken &&
            inputs.SMTPToken !== ''
        ) {
            await updateOption('SMTPToken', inputs.SMTPToken);
        }
    };


    const submitEmailDomainWhitelist = async () => {
        if (
            originInputs['EmailDomainWhitelist'] !== inputs.EmailDomainWhitelist.join(',') &&
            inputs.SMTPToken !== ''
        ) {
            await updateOption('EmailDomainWhitelist', inputs.EmailDomainWhitelist.join(','));
        }
    };

    const submitWeChat = async () => {
        if (originInputs['WeChatServerAddress'] !== inputs.WeChatServerAddress) {
            await updateOption(
                'WeChatServerAddress',
                removeTrailingSlash(inputs.WeChatServerAddress)
            );
        }
        if (
            originInputs['WeChatAccountQRCodeImageURL'] !==
            inputs.WeChatAccountQRCodeImageURL
        ) {
            await updateOption(
                'WeChatAccountQRCodeImageURL',
                inputs.WeChatAccountQRCodeImageURL
            );
        }
        if (
            originInputs['WeChatServerToken'] !== inputs.WeChatServerToken &&
            inputs.WeChatServerToken !== ''
        ) {
            await updateOption('WeChatServerToken', inputs.WeChatServerToken);
        }
    };

    const submitGitHubOAuth = async () => {
        if (originInputs['GitHubClientId'] !== inputs.GitHubClientId) {
            await updateOption('GitHubClientId', inputs.GitHubClientId);
        }
        if (
            originInputs['GitHubClientSecret'] !== inputs.GitHubClientSecret &&
            inputs.GitHubClientSecret !== ''
        ) {
            await updateOption('GitHubClientSecret', inputs.GitHubClientSecret);
        }
    };

    const submitTurnstile = async () => {
        if (originInputs['TurnstileSiteKey'] !== inputs.TurnstileSiteKey) {
            await updateOption('TurnstileSiteKey', inputs.TurnstileSiteKey);
        }
        if (
            originInputs['TurnstileSecretKey'] !== inputs.TurnstileSecretKey &&
            inputs.TurnstileSecretKey !== ''
        ) {
            await updateOption('TurnstileSecretKey', inputs.TurnstileSecretKey);
        }
    };

    const submitNewRestrictedDomain = () => {
        const localDomainList = inputs.EmailDomainWhitelist;
        if (restrictedDomainInput !== '' && !localDomainList.includes(restrictedDomainInput)) {
            setRestrictedDomainInput('');
            setInputs({
                ...inputs,
                EmailDomainWhitelist: [...localDomainList, restrictedDomainInput],
            });
            setEmailDomainWhitelist([...EmailDomainWhitelist, {
                key: restrictedDomainInput,
                text: restrictedDomainInput,
                value: restrictedDomainInput,
            }]);
        }
    }

    return (
        <Grid columns={1}>
            <Grid.Column>
                <Form loading={loading}>
                    <Header as='h3'>{t('SystemSetting.SystemSetting7')}</Header>
                    <Form.Group widths='equal'>
                        <Form.Input
                            label={t('SystemSetting.SystemSetting8')}
                            placeholder={t('SystemSetting.SystemSetting9')}
                            value={inputs.ServerAddress}
                            name='ServerAddress'
                            onChange={handleInputChange}
                            disabled={!originInputs.hasOwnProperty("ServerAddress")}
                        />
                    </Form.Group>
                    <Form.Button onClick={submitServerAddress}>
                        {t('SystemSetting.SystemSetting10')}
                    </Form.Button>
                    <Divider />
                    <Header as='h3'>{t('SystemSetting.SystemSetting11')}</Header>
                    <Form.Group widths={3}>
                        <Form.Checkbox
                            label={t('SystemSetting.SystemSetting12')}
                            name='PayEnabled'
                            onChange={handleInputChange}
                            checked={inputs.PayEnabled === 'true'}
                            disabled={!originInputs.hasOwnProperty("PayEnabled")}
                        />
                    </Form.Group>
                    <Form.Group widths='equal'>
                        <Form.Input
                            label={t('SystemSetting.SystemSetting13')}
                            placeholder={t('SystemSetting.SystemSetting9')}
                            value={inputs.PayAddress}
                            name='PayAddress'
                            onChange={handleInputChange}
                            disabled={!originInputs.hasOwnProperty("PayAddress")}
                        />
                        <Form.Input
                            label={t('SystemSetting.SystemSetting14')}
                            placeholder={t('SystemSetting.SystemSetting15')}
                            value={inputs.EpayId}
                            name='EpayId'
                            onChange={handleInputChange}
                            disabled={!originInputs.hasOwnProperty("EpayId")}
                        />
                        <Form.Input
                            label={t('SystemSetting.SystemSetting16')}
                            placeholder={t('SystemSetting.SystemSetting17')}
                            value={inputs.EpayKey}
                            name='EpayKey'
                            onChange={handleInputChange}
                            
                            disabled={!isRootAdmin && !originInputs.hasOwnProperty("EpayKey")}
                        />
                        <Form.Input
                            label={t('SystemSetting.SystemSetting18')}
                            placeholder={t('SystemSetting.SystemSetting19')}
                            value={inputs.Price}
                            name='Price'
                            min={0}
                            onChange={handleInputChange}
                            disabled={!originInputs.hasOwnProperty("Price")}
                        />
                        <Form.Input
                            label={t('SystemSetting.SystemSetting20')}
                            placeholder={t('SystemSetting.SystemSetting21')}
                            value={inputs.MinCharge}
                            name='MinCharge'
                            min={1}
                            onChange={handleInputChange}
                            disabled={!originInputs.hasOwnProperty("MinCharge")}
                        />
                        <Form.Field>
                            <label>{t('Agent.Agent15')}</label>
                            <Radio
                                style= {{marginRight: '16px'}}
                                label={t('Agent.Agent16')}
                                name='FeeMode'
                                value={'1'}
                                checked={inputs.FeeMode === '1' || inputs.FeeMode === '0'}
                                onChange={handleInputChange}
                            />
                            <Radio
                                label={t('Agent.Agent17')}
                                name='FeeMode'
                                value={'2'}
                                checked={inputs.FeeMode === '2'}
                                onChange={handleInputChange}
                            />
                        </Form.Field>
                    </Form.Group>
                    <Form.Group widths='equal'>
                        <Form.TextArea
                            label={t('SystemSetting.SystemSetting22')}
                            name='TopupGroupRatio'
                            onChange={handleInputChange}
                            style={{ minHeight: 150, fontFamily: 'JetBrains Mono, Consolas' }}
                            autoComplete='new-password'
                            value={inputs.TopupGroupRatio}
                            placeholder={t('SystemSetting.SystemSetting23')}
                            disabled={!originInputs.hasOwnProperty("TopupGroupRatio")}
                        />
                    </Form.Group>
                    <Form.Group widths={3}>
                        <Form.TextArea
                            label={t('SystemSetting.SystemSetting24')}
                            name='TopUpCountOptions'
                            onChange={handleInputChange}
                            style={{ minHeight: 250, fontFamily: 'JetBrains Mono, Consolas' }}
                            value={inputs.TopUpCountOptions}
                            placeholder={t('SystemSetting.SystemSetting25', { data: JSON.stringify(TOP_UP_COUNT_OPTIONS_EXAMPLE, null, 2) })}
                            disabled={!originInputs.hasOwnProperty("TopUpCountOptions")}
                        />
                    </Form.Group>
                    <Form.Button onClick={submitPayAddress}>
                        {t('SystemSetting.SystemSetting26')}
                    </Form.Button>
                    <Divider />
                    <Header as='h3'>{t('SystemSetting.SystemSetting27')}</Header>
                    <Form.Group inline>
                        <Form.Checkbox
                            checked={inputs.PasswordLoginEnabled === 'true'}
                            label={t('SystemSetting.SystemSetting28')}
                            name='PasswordLoginEnabled'
                            onChange={handleInputChange}
                            disabled={!originInputs.hasOwnProperty("PasswordLoginEnabled")}
                        />
                        {
                            showPasswordWarningModal &&
                            <Modal
                                open={showPasswordWarningModal}
                                onClose={() => setShowPasswordWarningModal(false)}
                                size={'tiny'}
                                style={{ maxWidth: '450px' }}
                            >
                                <Modal.Header>{t('SystemSetting.SystemSetting29')}</Modal.Header>
                                <Modal.Content>
                                    <p>{t('SystemSetting.SystemSetting30')}</p>
                                </Modal.Content>
                                <Modal.Actions>
                                    <Button onClick={() => setShowPasswordWarningModal(false)}>{t('SystemSetting.SystemSetting31')}</Button>
                                    <Button
                                        color='yellow'
                                        onClick={async () => {
                                            setShowPasswordWarningModal(false);
                                            await updateOption('PasswordLoginEnabled', 'false');
                                        }}
                                    >
                                        {t('SystemSetting.SystemSetting32')}
                                    </Button>
                                </Modal.Actions>
                            </Modal>
                        }
                        <Form.Checkbox
                            checked={inputs.PasswordRegisterEnabled === 'true'}
                            label={t('SystemSetting.SystemSetting33')}
                            name='PasswordRegisterEnabled'
                            onChange={handleInputChange}
                            disabled={!originInputs.hasOwnProperty("PasswordRegisterEnabled")}
                        />
                        <Form.Checkbox
                            checked={inputs.EmailVerificationEnabled === 'true'}
                            label={t('SystemSetting.SystemSetting34')}
                            name='EmailVerificationEnabled'
                            onChange={handleInputChange}
                            disabled={!originInputs.hasOwnProperty("EmailVerificationEnabled")}
                        />
                        <Form.Checkbox
                            checked={inputs.GitHubOAuthEnabled === 'true'}
                            label={t('SystemSetting.SystemSetting35')}
                            name='GitHubOAuthEnabled'
                            onChange={handleInputChange}
                            disabled={!originInputs.hasOwnProperty("GitHubOAuthEnabled")}
                        />
                        <Form.Checkbox
                            checked={inputs.WeChatAuthEnabled === 'true'}
                            label={t('SystemSetting.SystemSetting36')}
                            name='WeChatAuthEnabled'
                            onChange={handleInputChange}
                            disabled={!originInputs.hasOwnProperty("WeChatAuthEnabled")}
                        />
                    </Form.Group>
                    <Form.Group inline>
                        <Form.Checkbox
                            checked={inputs.RegisterEnabled === 'true'}
                            label={t('SystemSetting.SystemSetting37')}
                            name='RegisterEnabled'
                            onChange={handleInputChange}
                            disabled={!originInputs.hasOwnProperty("RegisterEnabled")}
                        />
                        <Form.Checkbox
                            checked={inputs.TurnstileCheckEnabled === 'true'}
                            label={t('SystemSetting.SystemSetting38')}
                            name='TurnstileCheckEnabled'
                            onChange={handleInputChange}
                            disabled={!originInputs.hasOwnProperty("TurnstileCheckEnabled")}
                        />
                    </Form.Group>
                    <Divider />
                    <Header as='h3'>
                        { t('SystemSetting.SystemSetting39') }
                        <Header.Subheader>{t('SystemSetting.SystemSetting40') }</Header.Subheader>
                    </Header>
                    <Form.Group widths={3}>
                        <Form.Checkbox
                            label={t('SystemSetting.SystemSetting41')}
                            name='EmailDomainRestrictionEnabled'
                            onChange={handleInputChange}
                            checked={inputs.EmailDomainRestrictionEnabled === 'true'}
                            disabled={!originInputs.hasOwnProperty("EmailDomainRestrictionEnabled")}
                        />
                    </Form.Group>
                    <Form.Group widths={2}>
                        <Form.Dropdown
                            label={t('SystemSetting.SystemSetting42')}
                            placeholder={t('SystemSetting.SystemSetting42')}
                            name='EmailDomainWhitelist'
                            required
                            fluid
                            multiple
                            selection
                            onChange={handleInputChange}
                            value={inputs.EmailDomainWhitelist}
                            autoComplete='new-password'
                            options={EmailDomainWhitelist}
                            disabled={!isRootAdmin || !originInputs.hasOwnProperty("EmailDomainWhitelist")}
                        />
                        <Form.Input
                            label={t('SystemSetting.SystemSetting43')}
                            action={
                                <Button type='button' onClick={() => {
                                    submitNewRestrictedDomain();
                                }}>填入</Button>
                            }
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    submitNewRestrictedDomain();
                                }
                            }}
                            autoComplete='new-password'
                            placeholder={t('SystemSetting.SystemSetting44')}
                            value={restrictedDomainInput}
                            onChange={(e, { value }) => {
                                setRestrictedDomainInput(value);
                            }}
                            disabled={!isRootAdmin || !originInputs.hasOwnProperty("EmailDomainWhitelist")}
                        />
                    </Form.Group>
                    <Form.Button onClick={submitEmailDomainWhitelist}>{t('SystemSetting.SystemSetting45') }</Form.Button>
                    <Divider />
                    <Header as='h3'>
                        { t('SystemSetting.SystemSetting46') }
                        <Header.Subheader>{t('SystemSetting.SystemSetting47') }</Header.Subheader>
                    </Header>
                    <Form.Group widths={3}>
                        <Form.Input
                            label={t('SystemSetting.SystemSetting48')}
                            name='SMTPServer'
                            onChange={handleInputChange}
                            autoComplete='new-password'
                            value={inputs.SMTPServer}
                            placeholder={t('SystemSetting.SystemSetting9')}
                            disabled={!originInputs.hasOwnProperty("SMTPServer")}
                        />
                        <Form.Input
                            label={t('SystemSetting.SystemSetting49')}
                            name='SMTPPort'
                            onChange={handleInputChange}
                            autoComplete='new-password'
                            value={inputs.SMTPPort}
                            placeholder={t('SystemSetting.SystemSetting50')}
                            disabled={!originInputs.hasOwnProperty("SMTPPort")}
                        />
                        <Form.Input
                            label={t('SystemSetting.SystemSetting51')}
                            name='SMTPAccount'
                            onChange={handleInputChange}
                            autoComplete='new-password'
                            value={inputs.SMTPAccount}
                            placeholder={t('SystemSetting.SystemSetting52')}
                            disabled={!originInputs.hasOwnProperty("SMTPAccount")}
                        />
                    </Form.Group>
                    <Form.Group widths={3}>
                        <Form.Input
                            label={t('SystemSetting.SystemSetting53')}
                            name='SMTPFrom'
                            onChange={handleInputChange}
                            autoComplete='new-password'
                            value={inputs.SMTPFrom}
                            placeholder={t('SystemSetting.SystemSetting54')}
                            disabled={!originInputs.hasOwnProperty("SMTPFrom")}
                        />
                        <Form.Input
                            label={t('SystemSetting.SystemSetting55')}
                            name='SMTPToken'
                            onChange={handleInputChange}
                            type='password'
                            autoComplete='new-password'
                            checked={inputs.RegisterEnabled === 'true'}
                            placeholder={t('SystemSetting.SystemSetting56')}
                        />
                    </Form.Group>
                    <Form.Button onClick={submitSMTP}>{t('SystemSetting.SystemSetting57') }</Form.Button>
                    <Divider />
                    <Header as='h3'>
                        { t('SystemSetting.SystemSetting58')}
                        <Header.Subheader>
                            {t('SystemSetting.SystemSetting59') }
                            <a href='https://github.com/settings/developers' target='_blank' rel="noreferrer">
                                {t('SystemSetting.SystemSetting60')}
                            </a>
                            {t('SystemSetting.SystemSetting61')}
                        </Header.Subheader>
                    </Header>
                    <Message>
                        {t('SystemSetting.SystemSetting62') } <code>{inputs.ServerAddress}</code>
                        ，Authorization callback URL 填{' '}
                        <code>{`${inputs.ServerAddress}/oauth/github`}</code>
                    </Message>
                    <Form.Group widths={3}>
                        <Form.Input
                            label='GitHub Client ID'
                            name='GitHubClientId'
                            onChange={handleInputChange}
                            autoComplete='new-password'
                            value={inputs.GitHubClientId}
                            placeholder={t('SystemSetting.SystemSetting63')}
                            disabled={!originInputs.hasOwnProperty("GitHubClientId")}
                        />
                        <Form.Input
                            label='GitHub Client Secret'
                            name='GitHubClientSecret'
                            onChange={handleInputChange}
                            type='password'
                            autoComplete='new-password'
                            value={inputs.GitHubClientSecret}
                            placeholder={t('SystemSetting.SystemSetting64')}
                        />
                    </Form.Group>
                    <Form.Button onClick={submitGitHubOAuth}>
                       { t('SystemSetting.SystemSetting65')}
                    </Form.Button>
                    <Divider />
                    <Header as='h3'>
                        { t('SystemSetting.SystemSetting66')}
                        <Header.Subheader>
                            {t('SystemSetting.SystemSetting67')}
                            <a
                                href='https://github.com/songquanpeng/wechat-server'
                                target='_blank' rel="noreferrer"
                            >
                                {t('SystemSetting.SystemSetting68')}
                            </a>
                            {t('SystemSetting.SystemSetting69')}
                        </Header.Subheader>
                    </Header>
                    <Form.Group widths={3}>
                        <Form.Input
                            label={t('SystemSetting.SystemSetting70')}
                            name='WeChatServerAddress'
                            placeholder='例如：https://yourdomain.com'
                            onChange={handleInputChange}
                            autoComplete='new-password'
                            value={inputs.WeChatServerAddress}
                            disabled={!originInputs.hasOwnProperty("WeChatServerAddress")}
                        />
                        <Form.Input
                            label={t('SystemSetting.SystemSetting71')}
                            name='WeChatServerToken'
                            type='password'
                            onChange={handleInputChange}
                            autoComplete='new-password'
                            value={inputs.WeChatServerToken}
                            placeholder={t('SystemSetting.SystemSetting72')}
                            disabled={!originInputs.hasOwnProperty("WeChatServerToken")}
                        />
                        <Form.Input
                            label={t('SystemSetting.SystemSetting73')}
                            name='WeChatAccountQRCodeImageURL'
                            onChange={handleInputChange}
                            autoComplete='new-password'
                            value={inputs.WeChatAccountQRCodeImageURL}
                            placeholder={t('SystemSetting.SystemSetting74')}
                            disabled={!originInputs.hasOwnProperty("WeChatAccountQRCodeImageURL")}
                        />
                    </Form.Group>
                    <Form.Button onClick={submitWeChat}>
                       { t('SystemSetting.SystemSetting75')}
                    </Form.Button>
                    <Divider />
                    <Header as='h3'>
                        { t('SystemSetting.SystemSetting76')}
                        <Header.Subheader>
                            {t('SystemSetting.SystemSetting77')}
                            <a href='https://dash.cloudflare.com/' target='_blank' rel="noreferrer">
                                {t('SystemSetting.SystemSetting78')}
                            </a>
                            {t('SystemSetting.SystemSetting79')}
                        </Header.Subheader>
                    </Header>
                    <Form.Group widths={3}>
                        <Form.Input
                            label='Turnstile Site Key'
                            name='TurnstileSiteKey'
                            onChange={handleInputChange}
                            autoComplete='new-password'
                            value={inputs.TurnstileSiteKey}
                            placeholder={t('SystemSetting.SystemSetting80')}
                            disabled={!originInputs.hasOwnProperty("TurnstileSiteKey")}
                        />
                        <Form.Input
                            label='Turnstile Secret Key'
                            name='TurnstileSecretKey'
                            onChange={handleInputChange}
                            type='password'
                            autoComplete='new-password'
                            value={inputs.TurnstileSecretKey}
                            placeholder={t('SystemSetting.SystemSetting81')}
                            disabled={!originInputs.hasOwnProperty("TurnstileSecretKey")}
                        />
                    </Form.Group>
                    <Form.Button onClick={submitTurnstile}>
                        { t('SystemSetting.SystemSetting82')}
                    </Form.Button>
                </Form>
            </Grid.Column>
        </Grid>
    );
};

export default SystemSetting;
