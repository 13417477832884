/*
 * @Author: heyan
 * @Date: 2024-01-23 14:14:41
 * @LastEditors: heyan
 * @LastEditTime: 2024-03-27 18:10:21
 * @Description: 
 */
import { initVChartSemiTheme } from '@visactor/vchart-semi-theme';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import HeaderBar from './components/HeaderBar';
import MeunScroll from './components/MeunScroll';
import 'semantic-ui-css/semantic.min.css';
import './index.css';
import { UserProvider } from './context/User';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { StatusProvider } from './context/Status';
import { Layout } from "@douyinfe/semi-ui";
import SiderBar from "./components/SiderBar";
import Footer from "./components/Footer";
import { isMobile } from './helpers';

// initialization
initVChartSemiTheme({
    isWatchingThemeSwitch: true,
});

const layoutType = localStorage.getItem("layout_type");
const showSider = isMobile() || layoutType === '0';

const root = ReactDOM.createRoot(document.getElementById('root'));
const { Sider, Content, Header } = Layout;
root.render(
    <React.StrictMode>
        <StatusProvider>
            <UserProvider>
                <BrowserRouter>
                    <Layout>
                        {
                            showSider &&
                            <Sider>
                                <SiderBar />
                            </Sider>
                        }
                        <Layout>
                            <Header className={showSider ? '' : 'pc-header'}>
                                {!showSider && <MeunScroll />}
                                {showSider && <HeaderBar />}
                            </Header>
                            <Content style={{ margin: showSider ? '' : '64px 20px 0px' }}>
                                <App />
                            </Content>
                            <Layout.Footer>
                                <Footer></Footer>
                            </Layout.Footer>
                        </Layout>
                        <ToastContainer />
                    </Layout>
                </BrowserRouter>
            </UserProvider>
        </StatusProvider>
    </React.StrictMode>
);