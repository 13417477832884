import React, {useEffect, useState} from 'react';
import {Label} from 'semantic-ui-react';
import {API, copy, isAdmin, isAgent, showError, showSuccess, timestamp2string} from '../helpers';

import {
    Table,
    Avatar,
    Tag,
    Form,
    Button,
    Layout,
    Select,
    Popover,
    Modal,
    ImagePreview,
    Typography, Progress
} from '@douyinfe/semi-ui';
import {ITEMS_PER_PAGE} from '../constants';
import {renderNumber, renderQuota, stringToColor} from '../helpers/render';
import { useTranslation } from 'react-i18next';

const colors = ['amber', 'blue', 'cyan', 'green', 'grey', 'indigo',
    'light-blue', 'lime', 'orange', 'pink',
    'purple', 'red', 'teal', 'violet', 'yellow'
]


const renderTimestamp = (timestampInSeconds) => {
    const date = new Date(timestampInSeconds * 1000); // 从秒转换为毫秒

    const year = date.getFullYear(); // 获取年份
    const month = ('0' + (date.getMonth() + 1)).slice(-2); // 获取月份，从0开始需要+1，并保证两位数
    const day = ('0' + date.getDate()).slice(-2); // 获取日期，并保证两位数
    const hours = ('0' + date.getHours()).slice(-2); // 获取小时，并保证两位数
    const minutes = ('0' + date.getMinutes()).slice(-2); // 获取分钟，并保证两位数
    const seconds = ('0' + date.getSeconds()).slice(-2); // 获取秒钟，并保证两位数

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`; // 格式化输出
};


const LogsTable = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState('');
    const isAdminUser = isAdmin();
    const isAgentUser = isAgent();
    const {t} = useTranslation()
    const columns = [
        {
            title: t('MjLogsTable.MjLogsTable18'),
            dataIndex: 'submit_time',
            render: (text, record, index) => {
                return (
                    <div>
                        {text ? renderTimestamp(text / 1000): "-"}
                    </div>
                );
            },
        },
        {
            title: "结束时间",
            dataIndex: 'finish_time',
            render: (text, record, index) => {
                return (
                    <div>
                        {text ? renderTimestamp(text / 1000): "-"}
                    </div>
                );
            },
        },
        {
            title: t('MjLogsTable.MjLogsTable33'),
            dataIndex: 'channel_id',
            className: isAdminUser ? 'tableShow' : 'tableHiddle',
            render: (text, record, index) => {
                return (

                    <div>
                        <Tag color={colors[parseInt(text) % colors.length]} size='large' onClick={() => {
                            copyText(text); // 假设copyText是用于文本复制的函数
                        }}> {text} </Tag>
                    </div>

                );
            },
        },
        {
            title: "代理",
            dataIndex: 'agent_id',
            className: isAdminUser ? 'tableShow' : 'tableHiddle',
            render: (text, record, index) => {
                return (
                    text ? text : "-"
                );
            },
        },
        {
            title: t('MjLogsTable.MjLogsTable55'),
            dataIndex: 'user_id',
            className: isAgentUser ? 'tableShow' : 'tableHiddle',
            render: (text, record, index) => {
                return (
                    <div>
                        {text}
                    </div>
                );
            },
        },
        {
            title: t('MjLogsTable.MjLogsTable19'),
            dataIndex: 'action',
            render: (text, record, index) => {
                return (
                    <div>
                        {renderType(text)}
                    </div>
                );
            },
        },
        {
            title: t('MjLogsTable.MjLogsTable20'),
            dataIndex: 'mj_id',
            render: (text, record, index) => {
                return (<Typography.Text
                    ellipsis={{showTooltip: true}}
                    //style={{width: 100}}
                    onClick={() => {
                        setModalContent(JSON.stringify(record, null, 2));
                        setIsModalOpen(true);
                    }}
                >
                    <div>
                        {text}
                    </div>
                </Typography.Text>);
            },
        },
        {
            title: "模式",
            dataIndex: 'mode',
            render: (text, record, index) => {
                return (
                    <div>
                        {text}
                    </div>
                );
            },
        },
        {
            title: t('MjLogsTable.MjLogsTable21'),
            dataIndex: 'code',
            render: (text, record, index) => {
                return (
                    <div>
                        {renderCode(text)}
                    </div>
                );
            },
        },
        {
            title: t('MjLogsTable.MjLogsTable22'),
            dataIndex: 'status',
            render: (text, record, index) => {
                return (
                    <div>
                        {renderStatus(text)}
                    </div>
                );
            },
        },
        {
            title: t('MjLogsTable.MjLogsTable23'),
            dataIndex: 'progress',
            render: (text, record, index) => {
                return (
                    <div>
                        {
                            // 转换例如100%为数字100，如果text未定义，返回0
                            <Progress stroke={record.status === "FAILURE"?"var(--semi-color-warning)":null} percent={text ? parseInt(text.replace('%', '')) : 0} showInfo={true}
                                      aria-label="drawing progress"/>
                        }
                    </div>
                );
            },
        },
        {
            title: "费用",
            dataIndex: 'quota',
            render: (text, record, index) => {
                return (
                    <div>
                        {renderQuota(text, 3)}
                    </div>
                );
            },
        },
        {
            title: t('MjLogsTable.MjLogsTable24'),
            dataIndex: 'image_url',
            render: (text, record, index) => {
                if (!text) {
                    return '-';
                }
                return (
                    <Button
                        onClick={() => {
                            setModalImageUrl(text);  // 更新图片URL状态
                            setIsModalOpenurl(true);    // 打开模态框
                        }}
                    >
                        查看图片
                    </Button>
                );
            }
        },
        {
            title: 'Prompt',
            dataIndex: 'prompt',
            render: (text, record, index) => {
                // 如果text未定义，返回替代文本，例如空字符串''或其他
                if (!text) {
                    return '-';
                }

                return (
                    <Typography.Text
                        ellipsis={{showTooltip: true}}
                        style={{width: 100}}
                        onClick={() => {
                            setModalContent(text);
                            setIsModalOpen(true);
                        }}
                    >
                        {text}
                    </Typography.Text>
                );
            }
        },
        {
            title: 'PromptEn',
            dataIndex: 'prompt_en',
            render: (text, record, index) => {
                // 如果text未定义，返回替代文本，例如空字符串''或其他
                if (!text) {
                    return '-';
                }

                return (
                    <Typography.Text
                        ellipsis={{showTooltip: true}}
                        style={{width: 100}}
                        onClick={() => {
                            setModalContent(text);
                            setIsModalOpen(true);
                        }}
                    >
                        {text}
                    </Typography.Text>
                );
            }
        },
        {
            title: t('MjLogsTable.MjLogsTable27'),
            dataIndex: 'fail_reason',
            render: (text, record, index) => {
                // 如果text未定义，返回替代文本，例如空字符串''或其他
                if (!text) {
                    return '无';
                }

                return (
                    <Typography.Text
                        ellipsis={{showTooltip: true}}
                        style={{width: 100}}
                        onClick={() => {
                            setModalContent(text);
                            setIsModalOpen(true);
                        }}
                    >
                        {text}
                    </Typography.Text>
                );
            }
        }
    ];

    const [logs, setLogs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [activePage, setActivePage] = useState(1);
    const [logCount, setLogCount] = useState(ITEMS_PER_PAGE);
    const [logType, setLogType] = useState(0);

    const [isModalOpenurl, setIsModalOpenurl] = useState(false);

    // 定义模态框图片URL的状态和更新函数
    const [modalImageUrl, setModalImageUrl] = useState('');
    let now = new Date();
    // 初始化start_timestamp为前一天
    let zeroNow = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const [inputs, setInputs] = useState({
        channel_id: '',
        mj_id: '',
        user_id: '',
        action: '',
        status: '',
        mode: '',
        agent_id: '',
        start_timestamp: timestamp2string(zeroNow.getTime() / 1000),
        end_timestamp: timestamp2string(now.getTime() / 1000 + 3600),
    });
    const {channel_id, mj_id, user_id, action, status, mode, agent_id, start_timestamp, end_timestamp} = inputs;

    const [stat, setStat] = useState({
        quota: 0,
        token: 0
    });

    const handleInputChange = (value, name) => {
        setInputs((inputs) => ({...inputs, [name]: value}));
    };


    const setLogsFormat = (logs) => {
        for (let i = 0; i < logs.length; i++) {
            logs[i].timestamp2string = timestamp2string(logs[i].created_at);
            logs[i].key = '' + logs[i].id;
        }
        // data.key = '' + data.id
        setLogs(logs);
        setLogCount(logs.length + ITEMS_PER_PAGE);
        // console.log(logCount);
    }

    const loadLogs = async (startIdx) => {
        setLoading(true);

        let url = '';
        let localStartTimestamp = Date.parse(start_timestamp);
        let localEndTimestamp = Date.parse(end_timestamp);
        if (isAdminUser) {
            url = `/api/mj/?p=${startIdx}&agent_id=${agent_id}&mode=${mode}&status=${status}&action=${action}&channel_id=${channel_id}&mj_id=${mj_id}&user_id=${user_id}&start_timestamp=${localStartTimestamp}&end_timestamp=${localEndTimestamp}`;
        } else if (isAgentUser) {
            url = `/api/mj/agent?p=${startIdx}&mode=${mode}&status=${status}&action=${action}&mj_id=${mj_id}&user_id=${user_id}&start_timestamp=${localStartTimestamp}&end_timestamp=${localEndTimestamp}`;
        } else {
            url = `/api/mj/self?p=${startIdx}&mode=${mode}&status=${status}&action=${action}&mj_id=${mj_id}&start_timestamp=${localStartTimestamp}&end_timestamp=${localEndTimestamp}`;
        }
        const res = await API.get(url);
        const {success, message, data} = res.data;
        if (success) {
            if (startIdx === 0) {
                setLogsFormat(data);
            } else {
                let newLogs = [...logs];
                newLogs.splice(startIdx * ITEMS_PER_PAGE, data.length, ...data);
                setLogsFormat(newLogs);
            }
        } else {
            showError(message);
        }
        setLoading(false);
    };

    const pageData = logs.slice((activePage - 1) * ITEMS_PER_PAGE, activePage * ITEMS_PER_PAGE);

    const handlePageChange = page => {
        setActivePage(page);
        if (page === Math.ceil(logs.length / ITEMS_PER_PAGE) + 1) {
            // In this case we have to load more data and then append them.
            loadLogs(page - 1).then(r => {
            });
        }
    };

    const refresh = async () => {
        // setLoading(true);
        setActivePage(1);
        await loadLogs(0);
    };


    const showStat = async () => {
        if (!isAdminUser) {
            return;
        }
        let localStartTimestamp = Date.parse(start_timestamp);
        let localEndTimestamp = Date.parse(end_timestamp);
        const res = await API.get(`/api/dashboard/mj?agent_id=${agent_id}&mode=${mode}&status=${status}&action=${action}&channel_id=${channel_id}&mj_id=${mj_id}&user_id=${user_id}&start_timestamp=${localStartTimestamp}&end_timestamp=${localEndTimestamp}`);
        const {success, message, data} = res.data;
        if (success) {
            Modal.info({
                title: "统计信息",
                content: <div style={{padding: 12}}>
                    <p>{JSON.stringify(data, null, 2)}</p>
                </div>,
                centered: true,
            })
        } else {
            showError(message);
        }
    };

  const copyText = async (text) => {
    if (await copy(text)) {
      showSuccess('已复制：' + text);
    } else {
      // setSearchKeyword(text);
      Modal.error({ title: t('MjLogsTable.MjLogsTable35'), content: text });
    }
  }

    useEffect(() => {
        refresh().then();
    }, [logType]);

  const renderType = (type) => {
      switch (type) {
          case 'IMAGINE':
              return <Label basic color='blue'> {t('MjLogsTable.MjLogsTable2')} </Label>;
          case "VARIATION":
              return <Label basic color='teal'> {t('MjLogsTable.MjLogsTable45')} </Label>;
          case "PAN":
              return <Label basic color='olive'> {t('MjLogsTable.MjLogsTable46')} </Label>;
          case "INPAINT":
              return <Label basic color='green'> {t('MjLogsTable.MjLogsTable47')} </Label>;
          case "OUTPAINT":
              return <Label basic color='violet'> {t('MjLogsTable.MjLogsTable48')} </Label>;
          case "REROLL":
              return <Label basic color='red'> {t('MjLogsTable.MjLogsTable49')} </Label>;
          case "RIC_READER":
              return <Label basic color='grey'> {t('MjLogsTable.MjLogsTable50')} </Label>;
          case "UPSCALE_2X":
              return <Label basic color='orange'> {t('MjLogsTable.MjLogsTable51')} </Label>;
          case "UPSCALE_4X":
              return <Label basic color='olive'> {t('MjLogsTable.MjLogsTable52')} </Label>;
          case 'BLEND':
              return <Label basic color='green'> {t('MjLogsTable.MjLogsTable6')} </Label>;

          case 'DESCRIBE':
              return <Label basic color='yellow'> {t('MjLogsTable.MjLogsTable5')} </Label>;
          case 'RICREADER_RETRY':
              return <Label basic color='blue'> {t('MjLogsTable.MjLogsTable53')} </Label>;
          case 'UPSCALE':
              return <Label basic color='orange'> {t('MjLogsTable.MjLogsTable54')} </Label>;
          case 'SWAPFACE':
              return <Label basic color='purple'> {t('MjLogsTable.MjLogsTable3')} </Label>;
          case 'ACTION':
              return <Label basic color='pink'> {t('MjLogsTable.MjLogsTable4')} </Label>;
          case 'SHORTEN':
              return <Label basic color='brown'> {t('MjLogsTable.MjLogsTable44')} </Label>;
          case 'MODAL':
              return <Label basic color='grey'> {t('MjLogsTable.MjLogsTable43')} </Label>;

          default:
              return <Label basic color='black'> {t('MjLogsTable.MjLogsTable7')} </Label>;
      }
  }

    const renderCode = (type) => {
        switch (type) {
            case 1:
                return <Label basic color='green'> {t('MjLogsTable.MjLogsTable8')} </Label>;
            case 21:
                return <Label basic color='olive'> {t('MjLogsTable.MjLogsTable9')} </Label>;
            case 22:
                return <Label basic color='orange'> {t('MjLogsTable.MjLogsTable10')} </Label>;
            default:
                return <Label basic color='black'> {t('MjLogsTable.MjLogsTable11')} </Label>;
        }
    }

    const renderStatus = (type) => {
        switch (type) {
            case 'SUCCESS':
                return <Label basic color='green'> {t('MjLogsTable.MjLogsTable12')} </Label>;
            case 'NOT_START':
                return <Label basic color='black'> {t('MjLogsTable.MjLogsTable13')} </Label>;
            case 'SUBMITTED':
                return <Label basic color='yellow'> {t('MjLogsTable.MjLogsTable14')} </Label>;
            case 'IN_PROGRESS':
                return <Label basic color='blue'> {t('MjLogsTable.MjLogsTable15')} </Label>;
            case 'FAILURE':
                return <Label basic color='red'> {t('MjLogsTable.MjLogsTable16')} </Label>;
            case '':
                return <Label basic color='black'> 正在提交 </Label>;
            default:
                return <Label basic color='black'> {t('MjLogsTable.MjLogsTable17')} </Label>;
        }
    }

    return (
        <>

            <Layout>
                <Form layout='horizontal' style={{marginTop: 10}}>
                    <>
                        {isAdminUser && <Form.Input field="channel_id" label='渠道 ID' style={{width: 176}} value={channel_id}
                                                       placeholder={'可选值'} name='channel_id'
                                                       onChange={value => handleInputChange(value, 'channel_id')}/>
                        }
                        {isAdminUser && <Form.Input field="agent_id" label='代理 ID' style={{width: 176}} value={agent_id}
                                                    placeholder={'可选值'} name='agent_id'
                                                    onChange={value => handleInputChange(value, 'agent_id')}/>
                        }
                        {isAgentUser && <Form.Input field="user_id" label='用户 ID' style={{width: 176}} value={user_id}
                                            placeholder={'可选值'} name='user_id'
                                            onChange={value => handleInputChange(value, 'user_id')}/>
                        }

                        <Form.Input field="mj_id" label={t('MjLogsTable.MjLogsTable38')} style={{width: 176}} value={mj_id}
                                    placeholder={t('MjLogsTable.MjLogsTable37')}
                                    name='mj_id'
                                    onChange={value => handleInputChange(value, 'mj_id')}/>


                        <Form.DatePicker field="start_timestamp" label={t('MjLogsTable.MjLogsTable39')} style={{width: 272}}
                                         initValue={start_timestamp}
                                         value={start_timestamp} type='dateTime'
                                         name='start_timestamp'
                                         onChange={value => handleInputChange(value, 'start_timestamp')}/>
                        <Form.DatePicker field="end_timestamp" fluid label={t('MjLogsTable.MjLogsTable40')} style={{width: 272}}
                                         initValue={end_timestamp}
                                         value={end_timestamp} type='dateTime'
                                         name='end_timestamp'
                                         onChange={value => handleInputChange(value, 'end_timestamp')}/>

                        <div>
                            <div style={{ marginBottom: 4 }}>
                                <Typography.Text>模式</Typography.Text>
                            </div>
                            <Select label={"模式"} defaultValue="" style={{width: 120}} onChange={value => handleInputChange(value, 'mode')}>
                                <Select.Option value="">All</Select.Option>
                                <Select.Option value="fast">Fast</Select.Option>
                                <Select.Option value="relax">Relax</Select.Option>
                                <Select.Option value="turbo">Turbo</Select.Option>
                            </Select>
                        </div>
                        <div style={{ marginLeft: 12 }}>
                            <div style={{ marginBottom: 4 }}>
                                <Typography.Text>类型</Typography.Text>
                            </div>
                            <Select label={"类型"} defaultValue="" style={{width: 120}} onChange={value => handleInputChange(value, 'action')}>
                                <Select.Option value="">All</Select.Option>
                                <Select.Option value="IMAGINE">{t('MjLogsTable.MjLogsTable2')}</Select.Option>
                                <Select.Option value="BLEND">{t('MjLogsTable.MjLogsTable6')}</Select.Option>
                                <Select.Option value="DESCRIBE">{t('MjLogsTable.MjLogsTable5')}</Select.Option>
                                <Select.Option value="SWAPFACE">{t('MjLogsTable.MjLogsTable3')}</Select.Option>
                                <Select.Option value="UPSCALE">{t('MjLogsTable.MjLogsTable54')}</Select.Option>
                                <Select.Option value="VARIATION">{t('MjLogsTable.MjLogsTable45')}</Select.Option>
                                <Select.Option value="PAN">{t('MjLogsTable.MjLogsTable46')}</Select.Option>
                                <Select.Option value="INPAINT">{t('MjLogsTable.MjLogsTable47')}</Select.Option>
                                <Select.Option value="OUTPAINT">{t('MjLogsTable.MjLogsTable48')}</Select.Option>
                                <Select.Option value="REROLL">{t('MjLogsTable.MjLogsTable49')}</Select.Option>
                                <Select.Option value="RIC_READER">{t('MjLogsTable.MjLogsTable50')}</Select.Option>
                                <Select.Option value="UPSCALE_2X">{t('MjLogsTable.MjLogsTable51')}</Select.Option>
                                <Select.Option value="UPSCALE_4X">{t('MjLogsTable.MjLogsTable52')}</Select.Option>
                                <Select.Option value="RICREADER_RETRY">{t('MjLogsTable53.MjLogsTable53')}</Select.Option>
                                <Select.Option value="ACTION">{t('MjLogsTable.MjLogsTable4')}</Select.Option>
                                <Select.Option value="SHORTEN">{t('MjLogsTable.MjLogsTable44')}</Select.Option>
                            </Select>
                        </div>
                        <div style={{ marginLeft: 12 }}>
                            <div style={{ marginBottom: 4 }}>
                                <Typography.Text>状态</Typography.Text>
                            </div>
                            <Select label={"状态"} defaultValue="" style={{width: 120}} onChange={value => handleInputChange(value, 'status')}>
                                <Select.Option value="">All</Select.Option>
                                <Select.Option value="SUCCESS">{t('MjLogsTable.MjLogsTable12')}</Select.Option>
                                <Select.Option value="NOT_START">{t('MjLogsTable.MjLogsTable13')}</Select.Option>
                                <Select.Option value="SUBMITTED">{t('MjLogsTable.MjLogsTable14')}</Select.Option>
                                <Select.Option value="IN_PROGRESS">{t('MjLogsTable.MjLogsTable15')}</Select.Option>
                                <Select.Option value="FAILURE">{t('MjLogsTable.MjLogsTable16')}</Select.Option>
                            </Select>
                        </div>
                        <Form.Section>
                            <Button label={t('MjLogsTable.MjLogsTable41')} type="primary" htmlType="submit" className="btn-margin-right"
                                    onClick={refresh}>{t('MjLogsTable.MjLogsTable41')}</Button>
                            {isAdminUser && <Button label='统计' type="primary" className="btn-margin-right" onClick={showStat} style={{ marginLeft: '8px' }}>统计</Button>}
                        </Form.Section>


                    </>
                </Form>
                <Table style={{marginTop: 5}} columns={columns} dataSource={pageData} pagination={{
                    currentPage: activePage,
                    pageSize: ITEMS_PER_PAGE,
                    total: logCount,
                    pageSizeOpts: [10, 20, 50, 100],
                    onPageChange: handlePageChange,
                }} loading={loading}/>
                <Modal
                    visible={isModalOpen}
                    onOk={() => setIsModalOpen(false)}
                    onCancel={() => setIsModalOpen(false)}
                    closable={null}
                    bodyStyle={{height: '400px', overflow: 'auto'}} // 设置模态框内容区域样式
                    width={800} // 设置模态框宽度
                >
                    <p style={{whiteSpace: 'pre-line'}}>{modalContent}</p>
                </Modal>
                <ImagePreview
                    src={modalImageUrl}
                    visible={isModalOpenurl}
                    onVisibleChange={(visible) => setIsModalOpenurl(visible)}
                />

            </Layout>
        </>
    );
};

export default LogsTable;
