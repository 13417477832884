/*
 * @Author: heyan
 * @Date: 2024-03-09 14:45:17
 * @LastEditors: heyan
 * @LastEditTime: 2024-03-16 11:32:49
 * @Description: 
 */
import React from 'react';
import { useState, useEffect, useContext } from 'react'
import { Popover, Table, Card, Tag, Button, Modal, Form, Toast, Radio } from '@douyinfe/semi-ui';
import * as Icons from '@douyinfe/semi-icons';
import { API, showError } from '../helpers';
import { UserContext } from '../context/User';

const DynamicMenuSetting = () => {
  const [userState, userDispatch] = useContext(UserContext);
  const columns = [
    {
      title: '排序',
      dataIndex: 'order',
    },
    {
      title: '菜单名称',
      dataIndex: 'menu_name',
    },
    {
      title: '菜单类型',
      dataIndex: 'menu_type',
      render: (text, record, index) => {
        return (
          <Tag shape='circle' color={text === 0 ? 'yellow' : 'light-blue'}>
            { text === 0 ? '系统预设' : '自定义设置' }
          </Tag>
        );
      },
    },
    {
      title: '是否打开新窗口',
      dataIndex: 'jump',
      render: (text, record, index) => {
        return (
          <Tag shape='circle' color={text ? 'pink' : 'grey'}>
            {text ? '是' : '否'}
          </Tag>
        );
      },
    },
    {
      title: '是否显示菜单',
      dataIndex: 'show',
      render: (text, record, index) => {
        return (
          <Tag shape='circle' color={text ? 'pink' : 'grey'}>
            {text ? '是' : '否'}
          </Tag>
        );
      },
    },
    {
      title: '是否需要登录访问',
      dataIndex: 'need_auth',
      render: (text, record, index) => {
        return (
          <Tag shape='circle' color={text ? 'light-blue' : 'grey'}>
            {text ? '是' : '否'}
          </Tag>
        );
      },
    },
    {
      title: '菜单图标',
      dataIndex: 'menu_icon',
      render: (text, record, index) => {
        return (
          <span>
            <IconComponent name={text} />
          </span>
        )
      }
    },
    {
      title: '菜单路径',
      dataIndex: 'menu_path',
    },
    {
      title: '页面内容',
      dataIndex: 'menu_data',
      render: (text, record, index) => {
        return record?.['menu_type'] === 0 ? '-' : text
      }
    },
    {
      title: '操作',
      width: 120,
      dataIndex: 'operate',
      fixed: 'right',
      render: (text, record, index) => {
        return (
          <div>
            <SettingDialog btnText="修改配置" checkValidate={checkValidate} isEdit={true} isCreate={record['menu_type'] !== 0} data={record} index={index} submitHandle={submitHandle}/>
            <Button theme='borderless' type='tertiary' onClick={() => hideHanle(index)}>{record?.show ? '隐藏菜单' : '显示菜单'}</Button>
            {
              record['menu_type'] !== 0 && <Button theme='borderless' type='tertiary' onClick={() => delHandle(index)}>删除菜单</Button>
            }
          </div>
        )
      }
    }
  ];
  const [data, setData] = useState([]);
  const [update, setUpdate] = useState(false);
  const [shouldUpdateOption, setShouldUpdateOption] = useState(false);
  const getOptions = async () => {
    const res = await API.get('/api/option/');
    const { success, message, data } = res.data;
    if (success) {
      data.forEach((item) => {
        if (item.key === 'Menus') {
          setData(JSON.parse(item.value));
          console.log(JSON.parse(item.value))
        }
      });
    } else {
      showError(message);
    }
  };

  const submitHandle = (index, v) => {
    // 创建数据的副本，然后修改副本
    const newData = [...data];
    newData[index] = {...newData[index], ...v, order: Number(v.order)};
    setData(newData.sort((a,b) => a.order - b.order));
    setUpdate(prevUpdate => !prevUpdate); 
    setShouldUpdateOption(true);
  }

  const updateOption = async () => {
    const res = await API.put('/api/option/', {
      key: 'Menus',
      value: JSON.stringify(data)
    });
    const { success } = res.data;
    if (success) {
      Toast.success({
        content: '更新成功, 请重新加载页面',
        duration: 3,
        theme: 'light',
      })
      getOptions()
      userDispatch({ type: 'update', payload: !userState.update });
    }
  };

  const hideHanle = (index) => {
    const newData = [...data];
    newData[index] = {...newData[index], show: !newData[index].show};
    
    setData(newData);
    setUpdate(prevUpdate => !prevUpdate);
    setShouldUpdateOption(true);
  }

  const submitHandleCreate = (index, v) => {
    v = {
      ...v,
      key:v['menu_path'].replace('/', ''),
      jump: false,
      show: true,
      order: Number(v.order)
    }
    setData(prevData => {
      // 返回新数组实例以触发更新
      return [v, ...prevData].sort((a,b) => a.order - b.order);
    });
    setUpdate(prevUpdate => !prevUpdate);
    // 设置状态标记以在数据更新后调用updateOption
    setShouldUpdateOption(true);
  }

  const delHandle = (index) => {
    const newData = [...data];
    newData.splice(index, 1)
    setData(newData);
    setUpdate(prevUpdate => !prevUpdate);
    setShouldUpdateOption(true);
  }
  
  const checkValidate = (v, index, isEdit) => {
    const val = !isEdit && data.findIndex(nf => (v['menu_name'] === nf['menu_name'] || v['menu_path'] === nf['menu_path'])) > -1;
    const val2 = isEdit && data.some((nf, i) => i!==index && (v['menu_name'] === nf['menu_name'] || v['menu_path'] === nf['menu_path']))
    if (val || val2) {
        Toast.error({
            content: '菜单名称和路径不能重复，请重新填写',
            duration: 3,
            theme: 'light',
        });
        return false;
    }
    return true;
  }

  useEffect(() => {
    getOptions();
  }, [])

  useEffect(() => {
    // 当shouldUpdateOption为true且data更新后，调用updateOption
    if (shouldUpdateOption) {
      setTimeout(() => {
        updateOption();
        setShouldUpdateOption(false); // 重置标记
      });
    }
  }, [data, shouldUpdateOption]); // 依赖于data和shouldUpdateOption

  return (
    <Card style={{marginTop: '20px'}}>
        <div style={{textAlign: 'right', marginBottom: '20px'}}>
          <SettingDialog checkValidate={checkValidate} btnText="新增菜单" isCreate={true} submitHandle={submitHandleCreate}/>
        </div>
        <Table
          key={update}
          columns={columns} 
          dataSource={data}
          pagination={false}
        />
     </Card>
  )
}

const IconComponent = ({name}) => {
  if (!name) return '-';
  const IconsComponent = Icons[name];
  return <IconsComponent />
}

const IconContent = ({handleClick}) => {
  const iconList = Object.keys(Icons).splice(0,430)
  return (
    <div style={{width: '800px',display: 'flex', flexWrap: 'wrap'}}>
      {
        iconList.map((item) => (
          <span
            style={{ margin: '0px 8px 10px 8px', cursor: 'pointer'}}
            onClick={() => { handleClick(item) }}
          ><IconComponent name={item} /></span>
        ))
      }
    </div>
  )
}

const IconLib = ({ IconLibClick }) => {
  const [visible, setVisible] = useState(false);
  const iconClick = (e) => {
    IconLibClick(e)
    setVisible(false)
  }
  return (
    <Popover showArrow visible={visible} content={<IconContent handleClick={iconClick}/>} position="top" trigger="click">
      <span style={{cursor: 'pointer', marginLeft: '10px'}} onClick={() => setVisible(!visible)} >
        <IconComponent name='IconEditStroked' />
      </span>
    </Popover>
  )
}

const SettingDialog = ({ btnText, data, submitHandle, index, isCreate, checkValidate, isEdit }) => {
  
  const [visible, setVisible] = useState(false);
  const [currentIcon, setCurrentIcon] = useState();

  const showDialog = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const IconLibClick = (icon) => {
    setCurrentIcon(icon);
  }

  const submit = (v) => {
    console.log(v)
    console.log(currentIcon)
    if (!currentIcon) {
      Toast.error({
        content: '请选择菜单图标',
        duration: 3,
        theme: 'light',
      })
      return
    }
    if (!checkValidate(v, index, isEdit)) return
    setVisible(false)
    submitHandle(index, { ...v, menu_icon: currentIcon });
  }

  useEffect(() => {
    setCurrentIcon(data?.['menu_icon'])
  }, [data])

  return (
    <>
      <Button onClick={showDialog} theme='borderless' type='secondary'>{btnText}</Button>
      <Modal
        title={btnText}
        visible={visible}
        onCancel={handleCancel}
        footer={null}
        size="small"
      >
        <Form layout='horizontal' onSubmit={submit} initValues={data}>
          <Form.Input
            style={{width: '398px'}}
            field='order'
            label='菜单排序'
            rules={[
              { required: true, message: '菜单顺序不能为空' }
            ]}
          />
          <div style={{marginTop: '16px'}}></div>
          <Form.Input
            style={{width: '398px'}}
            field='menu_name'
            label="菜单名称"
            rules={[
              { required: true, message: '菜单名称不能为空' }
            ]}
          />
          {
            isCreate && (
              <>
                <div style={{marginTop: '16px'}}></div>
                <Form.Input
                  style={{width: '398px'}}
                  field='menu_path'
                  label='菜单路径'
                  rules={[
                    { required: true, message: '菜单路径不能为空' }
                  ]}
                />
                <div style={{marginTop: '16px'}}></div>
                <Form.TextArea
                    style={{ width: '398px' }}
                    field='menu_data'
                    label='页面内容'
                    placeholder='请输入网页内容，支持 Markdown & HTML 代码，设置后首页的状态信息将不再显示。如果输入的是一个链接（需以http:// 或 https://开头），则会使用该链接作为 iframe 的 src 属性，这允许你设置任意网页作为内容'
                />
              </>
            )
          }
          <div style={{marginTop: '16px'}}></div>
          <Form.RadioGroup
              style={{width: '398px'}}
              field='need_auth'
              label='是否需要登录访问'
          >
            <Radio value={true}>是</Radio>
            <Radio value={false}>否</Radio>
          </Form.RadioGroup>
          <div style={{ marginTop: '16px' }}></div>
          <Form.RadioGroup
            style={{ width: '398px' }}
            field='jump'
            label='是否打开新窗口'
          >
            <Radio value={true}>是</Radio>
            <Radio value={false}>否</Radio>
          </Form.RadioGroup>
          <div style={{width:'398px',display: 'flex', alignItems: 'center', marginTop: '8px'}}>
            <label style={{marginBottom: '0px'}} className='semi-form-field-label semi-form-field-label-left'>
              <div className='semi-form-field-label-text'>菜单图标</div>
            </label>
            <IconComponent name={currentIcon}></IconComponent>
            <IconLib IconLibClick={IconLibClick}/>
          </div>
          <Button style={{margin: '16px auto 20px'}} type="primary" htmlType="submit">提交(submit)</Button>
        </Form>
      </Modal>
    </>
  )
}

export default DynamicMenuSetting;