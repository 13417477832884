/*
 * @Author: heyan
 * @Date: 2023-12-12 22:34:14
 * @LastEditors: heyan
 * @LastEditTime: 2024-03-27 18:01:52
 * @Description: 
 */
import React from 'react';
import ChannelsTable from '../../components/ChannelsTable';
import { Layout, Card } from "@douyinfe/semi-ui";
import { useTranslation } from 'react-i18next';
import { isMobile } from "../../helpers";

const File = () => {
    const { t } = useTranslation();
    const layoutType = localStorage.getItem("layout_type");
    const showSider = isMobile() || layoutType === '0';

    return (
        <>
            <Layout>
                {
                    !showSider &&
                    <Layout.Header style={{ textAlign: 'center', margin: '24px 0px' }}>
                        <h3>{t('EditChannel.EditChannel56')}</h3>
                    </Layout.Header>
                }
                <Layout.Content>
                    <Card style={{ maxWidth: '1800px', minHeight: '580px', margin: 'auto' }}>
                        <ChannelsTable />
                    </Card>
                </Layout.Content>
            </Layout>
        </>
    )
};

export default File;
