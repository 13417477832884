import React, { useEffect, useState, useContext } from 'react';
import { API, isMobile, showError, showInfo, showSuccess, copy } from '../../helpers';
import { renderQuota, getQuotaPerUnit, renderQuotaWithPrompt } from "../../helpers/render";
import { Image, Layout, Avatar, Empty, Typography, Button, Form, Divider, Input, Modal, InputNumber } from "@douyinfe/semi-ui";
import Title from "@douyinfe/semi-ui/lib/es/typography/title";
import { useTranslation } from "react-i18next";
import { IllustrationNoContent, IllustrationNoContentDark } from '@douyinfe/semi-illustrations';
import { UserContext } from '../../context/User';

const layoutType = localStorage.getItem("layout_type");
const showSider = isMobile() || layoutType === '0';

const TopUp = () => {
    const [redemptionCode, setRedemptionCode] = useState('');
    const [topUpCode, setTopUpCode] = useState('');
    const [topUpCount, setTopUpCount] = useState(10);
    const [amount, setAmount] = useState(0.0);
    const [topUpLink, setTopUpLink] = useState('');
    const [userQuota, setUserQuota] = useState(0);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [open, setOpen] = useState(false);
    const [payWay, setPayWay] = useState('');
    const [payEnabled, setPayEnabled] = useState(false);
    const [topUpCountOptions, setTopUpCountOptions] = useState([]);
    const [affLink, setAffLink] = useState("");
    const [userState] = useContext(UserContext);
    const [openTransfer, setOpenTransfer] = useState(false);
    const { t } = useTranslation();
    const topUp = async () => {
        if (redemptionCode === '') {
            showInfo('请输入兑换码！')
            return;
        }
        setIsSubmitting(true);
        try {
            const res = await API.post('/api/user/topup', {
                key: redemptionCode
            });
            const { success, message, data } = res.data;
            if (success) {
                showSuccess(t('TopUp.TopUp1'));
                Modal.success({ title: t('TopUp.TopUp1'), content: t('TopUp.TopUp2') + renderQuota(data), centered: true });
                setUserQuota((quota) => {
                    return quota + data;
                });
                setRedemptionCode('');
            } else {
                showError(message);
            }
        } catch (err) {
            showError(t('TopUp.TopUp3'));
        } finally {
            setIsSubmitting(false);
        }
    };

    const openTopUpLink = () => {
        if (!topUpLink) {
            showError(t('TopUp.TopUp4'));
            return;
        }
        setTimeout(() => {
            window.open(topUpLink);
        });
    };

    const preTopUp = async (payment) => {
        if (amount === 0) {
            await getAmount();
        }
        setPayWay(payment)
        setOpen(true);
    }

    const onlineTopUp = async () => {
        if (amount === 0) {
            await getAmount();
        }
        setOpen(false);
        try {
            const res = await API.post('/api/user/pay', {
                amount: parseInt(topUpCount),
                top_up_code: topUpCode,
                payment_method: payWay
            });
            if (res !== undefined) {
                const { message, data } = res.data;
                // showInfo(message);
                if (message === 'success') {

                    let params = data
                    let url = res.data.url
                    let form = document.createElement('form')
                    form.action = url
                    form.method = 'POST'
                    // 判断是否为safari浏览器
                    let isSafari = navigator.userAgent.indexOf("Safari") > -1 && navigator.userAgent.indexOf("Chrome") < 1;
                    if (!isSafari) {
                        form.target = '_blank'
                    }
                    for (let key in params) {
                        let input = document.createElement('input')
                        input.type = 'hidden'
                        input.name = key
                        input.value = params[key]
                        form.appendChild(input)
                    }
                    document.body.appendChild(form)
                    form.submit()
                    document.body.removeChild(form)
                } else {
                    showError(data);
                    showError(message);
                    // setTopUpCount(parseInt(res.data.count));
                    // setAmount(parseInt(data));
                }
            } else {
                showError(res);
            }
        } catch (err) {
        } finally {
        }
    }

    const getUserQuota = async () => {
        let res = await API.get(`/api/user/self`);
        const { success, message, data } = res.data;
        if (success) {
            setUserQuota(data.quota);
        } else {
            showError(message);
        }
    }

    const getAffLink = async () => {
        const res = await API.get('/api/user/aff');
        const { success, message, data } = res.data;
        if (success) {
            let link = `${window.location.origin}/register?aff=${data}`;
            setAffLink(link);
        } else {
            showError(message);
        }
    };

    useEffect(() => {
        let status = localStorage.getItem('status');
        if (status) {
            status = JSON.parse(status);
            if (status.top_up_link) {
                setTopUpLink(status.top_up_link);
            }
            if (status.top_up_count_options) {
                const newArray = JSON.parse(status.top_up_count_options).map(item => {
                    if (item.label) {
                        return item;
                    }
                    return {
                        "label": item.text,
                        "value": item.value
                    };
                });
                setTopUpCountOptions(newArray);
            }
            setPayEnabled(status.pay_enabled);
        }
        getUserQuota().then();
        getAffLink().then();
        setTransferAmount(getQuotaPerUnit())
    }, []);

    const renderAmount = () => {
        // console.log(amount);
        return amount + '元';
    }

    const getAmount = async (value) => {
        if (value === undefined) {
            value = topUpCount;
        }
        try {
            const res = await API.post('/api/user/amount', {
                amount: parseFloat(value),
                top_up_code: topUpCode
            });
            if (res !== undefined) {
                const { message, data } = res.data;
                // showInfo(message);
                if (message === 'success') {
                    setAmount(parseFloat(data));
                } else {
                    showError(data);
                    showError(message);
                    // setTopUpCount(parseInt(res.data.count));
                    // setAmount(parseInt(data));
                }
            } else {
                showError(res);
            }
        } catch (err) {
        } finally {
        }
    }

    const handleCancel = () => {
        setOpen(false);
    }

    const [transferAmount, setTransferAmount] = useState(0);
    const transfer = async () => {
        if (transferAmount < getQuotaPerUnit()) {
            showError(t('PersonalSetting.PersonalSetting32') + renderQuota(getQuotaPerUnit()));
            return;
        }
        const res = await API.post(
            `/api/user/aff_transfer`,
            {
                quota: transferAmount
            }
        );
        const { success, message } = res.data;
        if (success) {
            showSuccess(message);
            setOpenTransfer(false);
        } else {
            showError(message);
        }
    };

    const handleCancels = () => {
        setOpenTransfer(false);
    }

    return (
        <div>
            <Layout>
                {
                    !showSider &&
                    <Layout.Header style={{ textAlign: 'center', margin: '24px 0px' }}>
                        <h3>{t('TopUp.TopUp5')}</h3>
                    </Layout.Header>
                }
                <Layout.Content style={{ overflowX: isMobile() ? 'scroll' : 'hidden' }}>
                    <Modal
                        title={t('TopUp.TopUp6')}
                        visible={open}
                        onOk={onlineTopUp}
                        onCancel={handleCancel}
                        maskClosable={false}
                        size={'small'}
                        centered={true}
                    >
                        <p>{t('TopUp.TopUp7')}{topUpCount}</p>
                        <p>{t('TopUp.TopUp8')}{renderAmount()}</p>
                        <p>{t('TopUp.TopUp9')}</p>
                    </Modal>
                    <Modal
                        title={t('PersonalSetting.PersonalSetting37')}
                        visible={openTransfer}
                        onOk={transfer}
                        onCancel={handleCancels}
                        maskClosable={false}
                        size={'small'}
                        centered={true}
                    >
                        <div style={{ marginTop: 20 }}>
                            <Typography.Text>{`${t('PersonalSetting.PersonalSetting39')}${renderQuotaWithPrompt(userState?.user?.aff_quota)}`}</Typography.Text>
                            <Input style={{ marginTop: 5 }} value={userState?.user?.aff_quota} disabled={true}></Input>
                        </div>
                        <div style={{ marginTop: 20 }}>
                            <Typography.Text>{`${t('PersonalSetting.PersonalSetting40')}${renderQuotaWithPrompt(transferAmount)} ${t('PersonalSetting.PersonalSetting41')}` + renderQuota(getQuotaPerUnit())}</Typography.Text>
                            <div>
                                <InputNumber min={0} style={{ marginTop: 5 }} value={transferAmount} onChange={(value) => setTransferAmount(value)} disabled={false}></InputNumber>
                            </div>
                        </div>
                    </Modal>
                    <div style={{ marginTop: 20, display: 'flex', justifyContent: 'center', minWidth: '1000px' }}>
                        <div className='topup-left'>
                            <Image
                                src='https://lf3-static.bytednsdoc.com/obj/eden-cn/ptlz_zlp/ljhwZthlaukjlkulzlp/root-web-sites/abstract.jpg'
                                className='topup-left-top'
                            ></Image>
                            <Avatar color="amber" border={{ motion: true }} size={"large"} style={{ marginRight: '8px' }} contentMotion={true}>T</Avatar>
                            <div style={{ width: '400px', margin: '50px auto' }} >
                                <Title className='driver-step7' level={3} style={{ textAlign: 'center' }}>{t('TopUp.TopUp10')} {renderQuota(userQuota)}</Title>
                                <div style={{ marginTop: 20 }}>
                                    <Divider>
                                        {t('TopUp.TopUp11')}
                                    </Divider>
                                    <Form>
                                        <Form.Input
                                            field={'redemptionCode'}
                                            label={t('TopUp.TopUp12')}
                                            placeholder={t('TopUp.TopUp12')}
                                            name='redemptionCode'
                                            value={redemptionCode}
                                            onChange={(value) => {
                                                setRedemptionCode(value);
                                            }}
                                            suffix={<>
                                                {
                                                    topUpLink ?
                                                        <Button style={{ marginRight: '12px' }} type={'primary'} theme={'solid'} onClick={openTopUpLink}>
                                                            {t('TopUp.TopUp13')}
                                                        </Button> : null
                                                }
                                                <Button type={"warning"} theme={'solid'} onClick={topUp}
                                                    disabled={isSubmitting}>
                                                    {isSubmitting ? t('TopUp.TopUp14') : t('TopUp.TopUp15')}
                                                </Button>
                                            </>}
                                        />
                                    </Form>
                                </div>
                                {payEnabled && <div style={{ marginTop: 20 }} className='driver-step8'>
                                    <Divider>
                                        {t('TopUp.TopUp16')}
                                    </Divider>
                                    <Form>
                                        {
                                            (topUpCountOptions && topUpCountOptions.length > 0) ? (
                                                <Form.Select
                                                    label={t('TopUp.TopUp17') + renderAmount()}
                                                    name='redemptionCount'
                                                    placeholder={t('TopUp.TopUp18')}
                                                    optionList={topUpCountOptions}
                                                    onChange={async (value, label) => {
                                                        setTopUpCount(value);
                                                        await getAmount(value);
                                                    }}
                                                />) : (
                                                <Form.Input
                                                    label={t('TopUp.TopUp17') + renderAmount()}
                                                    placeholder={t('TopUp.TopUp19')}
                                                    name='redemptionCount'
                                                    type={'number'}
                                                    value={topUpCount}
                                                    autoComplete={'off'}
                                                    onChange={async (value) => {
                                                        setTopUpCount(value);
                                                        await getAmount(value);
                                                    }}
                                                />
                                            )
                                        }
                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                            <Button style={{ flex: 1, marginRight: '12px' }} type={'primary'} theme={'solid'} onClick={
                                                async () => {
                                                    preTopUp('zfb')
                                                }
                                            }>
                                                {t('TopUp.TopUp20')}
                                            </Button>
                                            <Button style={{ flex: 1, backgroundColor: 'rgba(var(--semi-green-5), 1)' }}
                                                type={'primary'}
                                                theme={'solid'} onClick={
                                                    async () => {
                                                        preTopUp('wx')
                                                    }
                                                }>
                                                {t('TopUp.TopUp21')}
                                            </Button>
                                        </div>
                                    </Form>
                                </div>}
                            </div>
                        </div>
                        <div className='topup-right'>
                            <Empty
                                image={<IllustrationNoContent />}
                                darkModeImage={<IllustrationNoContentDark />}
                                title="邀请奖励"
                                imageStyle={{ background: 'rgba(246,216,111, 0.2)', width: '100%', height: '240px', paddingTop: '16px' }}
                                description="邀请好友注册，好友充值前 3 次，得返现（10%）"
                            >
                                <div>
                                    <div style={{ marginBottom: '6px' }}>
                                        <div className='topup-right-yaoqing'>
                                            {affLink}
                                            <span onClick={() => {
                                                copy(affLink)
                                                showSuccess('复制成功');
                                            }} style={{ color: 'rgba(252,136,0)', cursor: 'pointer', marginLeft: '8px' }}>复制</span>
                                        </div>
                                    </div>
                                    <div className='topup-right-yaoqing-lael'>
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '16px' }}>
                                            <span style={{ marginRight: '6px' }}>总收益：{renderQuota(userState?.user?.aff_history_quota)}</span>
                                            <span>邀请人数：{userState?.user?.aff_count}</span>
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '24px' }}>
                                            <div>
                                                <span>待使用收益：</span>
                                                <span style={{ color: 'rgba(var(--semi-red-5), 1)' }}>
                                                    {
                                                        renderQuota(userState?.user?.aff_quota)
                                                    }
                                                </span>
                                            </div>
                                            <span style={{ color: 'rgba(252,136,0)', cursor: 'pointer', marginLeft: '8px' }} onClick={() => setOpenTransfer(true)}>划转</span>
                                        </div>
                                    </div>
                                </div>
                            </Empty>
                        </div>
                    </div>
                </Layout.Content>
            </Layout>
        </div>

    );
};

export default TopUp;