/*
 * @Author: heyan
 * @Date: 2023-12-26 21:25:48
 * @LastEditors: heyan
 * @LastEditTime: 2023-12-27 20:13:36
 * @Description: 
 */
import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { timestamp2string } from '../helpers';

const ModelUsageBarChart = (props) => {
  const [series, setSeries] = useState([]);
  const [options, setOptions] = useState({
    chart: {
      id: 'bar-chart',
      stacked: true,
      toolbar: {
        show: true
      },
      zoom: {
        enabled: true
      },
      events: {
        legendClick: (chartContext, seriesIndex, config) => {
          // 获取所有系列
          const series = chartContext.w.config.series;

          // 遍历所有系列，根据点击的系列索引来显示或隐藏
          series.forEach((s, index) => {
            chartContext.hideSeries(s.name); // 先隐藏所有系列
          });

          // 显示点击的那个系列
          chartContext.showSeries(series[seriesIndex].name);
        }
      }
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            position: 'bottom',
            offsetX: -10,
            offsetY: 0
          }
        }
      }
    ],
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 10,
        columnWidth: '50%',
        dataLabels: {
          total: {
            position: 'top',
            enabled: true,
            style: {
              fontSize: '10px',
              fontWeight: 900,
              color: '#666'
            },
            formatter(v) {
              return `总计: ${Number(v).toFixed(2)}`;
            }
          }
        }
      }
    },
    xaxis: {
      type: 'category',
      categories: []
    },
    yaxis: {
      title: {
        text: '金额($)'
      }
    },
    legend: {
      show: true,
      fontSize: '10px',
      fontFamily: `'Roboto', sans-serif`,
      position: 'right',
      labels: {
        useSeriesColors: false
      },
      markers: {
        width: 16,
        height: 16,
        radius: 5
      },
      itemMargin: {
        horizontal: 15,
        vertical: 8
      }
    },
    fill: {
      type: 'solid'
    },
    dataLabels: {
      enabled: false
    },
    grid: {
      show: true
    },
    tooltip: {
      theme: 'dark',
      fixed: {
        enabled: false
      },
      marker: {
        show: false
      }
    },
    colors: [
      "#FF5733", // 鲜艳的橙红色
      "#33FF57", // 鲜艳的草绿色
      "#3357FF", // 鲜艳的蓝色
      "#F933FF", // 鲜艳的紫色
      "#4A69BD", // 鲜艳的金色
      "#31FFD5", // 鲜艳的蓝绿色
      "#FF5733", // 鲜艳的红色
      "#C70039", // 鲜艳的暗红色
      "#900C3F", // 鲜艳的栗色
      "#581845", // 鲜艳的紫罗兰色
      "#E74C3C", // 鲜红色
      "#8E44AD", // 紫色
      "#3498DB", // 亮蓝色
      "#2ECC71", // 翠绿色
      "#F1C40F", // 鲜黄色
      "#73C6B6", // 橙色
      "#1ABC9C", // 青色
      "#2C3E50", // 暗蓝灰色
      "#F39C12", // 金黄色
      "#D35400", // 南瓜色
      "#C0392B", // 石榴红
      "#9B59B6", // 紫罗兰色
      "#2980B9", // 靛蓝色
      "#27AE60", // 暗绿色
      "#F4D03F", // 鲜亮黄色
      "#D68910", // 蜜橙色
      "#A6ACAF", // 银灰色
      "#85C1E9", // 天空蓝
      "#D0ECE7", // 浅绿宝石色
      "#5DADE2"
    ]
  });
  const keys = [
    "claude-1-100k",
    "claude-2-100k",
    "dall-e-3",
    "file-upload",
    "google-palm",
    "gpt-3.5-turbo",
    "gpt-3.5-turbo-0301",
    "gpt-3.5-turbo-0613",
    "gpt-3.5-turbo-1106",
    "gpt-3.5-turbo-16k",
    "gpt-3.5-turbo-16k-0613",
    "gpt-4",
    "gpt-4-0314",
    "gpt-4-0613",
    "gpt-4-1106-preview",
    "gpt-4-32k",
    "gpt-4-32k-0613",
    "gpt-4-all",
    "gpt-4-dalle",
    "gpt-4-gizmo",
    "gpt-4-v",
    "gpt-4-vision-preview",
    "llama-2-70b",
    "midjourney",
    "net-gpt-3.5-turbo",
    "net-gpt-3.5-turbo-16k",
    "net-gpt-4",
    "stable-diffusion",
    "text-embedding-ada-002",
    "tts-1",
    "tts-1-hd",
    "whisper-1"
  ]

  const transformData = (rawData) => {
    let transformed = [];
    let tempData = {};

    rawData.forEach(entry => {
      keys.forEach(category => {
        if (!tempData[category]) {
          tempData[category] = [];
        }
        tempData[category].push(entry.categories[category] || 0);
      })
    });
    for (let [key, value] of Object.entries(tempData)) {
      transformed.push({
        name: key,
        data: value.map(v => (Number(v) / 500000).toFixed(2))
      });
    }
    return transformed;
  }

  useEffect(() => {
    const _series = transformData(props.data)
    setSeries(_series)
    setOptions({
     ...options,
      xaxis: {
       ...options.xaxis,
        categories: props.data.map(entry => timestamp2string(entry.created_at))
      }
    })
  }, [props.data])

  return (
    <div id="chart">
      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        height={400}
      />
    </div>
  );
}

export default ModelUsageBarChart;