import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import {Button, Form, Layout, Modal, Select, Table, Tag} from "@douyinfe/semi-ui";
import {ITEMS_PER_PAGE} from "../../constants";
import {API, isAdmin, showError, timestamp2string} from "../../helpers";
import MyForm from "./MyForm";


const colors = ['amber', 'blue', 'cyan', 'green', 'grey', 'indigo',
    'light-blue', 'lime', 'orange', 'pink',
    'purple', 'red', 'teal', 'violet', 'yellow'
]

const TopupsTable = () => {
    const { t } = useTranslation();

    const columns = [
        {
            title: "创建时间",
            dataIndex: 'timestamp2string',
        },
        {
            title: "用户",
            dataIndex: 'user_id',
        },
        {
            title: "状态",
            dataIndex: 'status',
            render: (text, record, index) => {
                return (
                    <div>
                        {renderStatus(text)}
                    </div>
                );
            },
        },
        {
            title: "订单号",
            dataIndex: 'trade_no',
        },
        {
            title: "平台",
            dataIndex: 'platform',
            className: isAdmin() ? 'tableShow' : 'tableHiddle',
            render: (text, record, index) => {
                return (
                    <div>
                        {renderPlatform(text)}
                    </div>
                );
            },
        },
        {
            title: "数量",
            dataIndex: 'amount',
        },
        {
            title: "总金额",
            dataIndex: 'money',
            render: (text, record, index) => {
                return (
                    <div>
                        {(text/100).toFixed(2)}
                    </div>
                );
            },
        },
        {
            title: "代理商",
            dataIndex: 'agent_user_id',
        },
        {
            title: "提成",
            dataIndex: 'share_profit',
            render: (text, record, index) => {
                return (
                    <div>
                        {(text/100).toFixed(2)}
                    </div>
                );
            },
        },
    ]

    const isAdminUser = isAdmin();
    const [topups, setTopups] = useState([]);
    const [loading, setLoading] = useState(true);
    const [activePage, setActivePage] = useState(1);
    const [topupsCount, setTopupsCount] = useState(ITEMS_PER_PAGE);
    let now = new Date();
    let zeroNow = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const [inputs, setInputs] = useState({
        trade_no: '',
        status: '',
        platform: '',
        user_id: '',
        agent_id: '',
        start_timestamp: timestamp2string(zeroNow.getTime() / 1000),
        end_timestamp: timestamp2string(now.getTime() / 1000 + 3600),
    });
    const {trade_no, status, user_id, agent_id, platform, start_timestamp, end_timestamp} = inputs;

    const handleInputChange = (value, name) => {
        setInputs((inputs) => ({...inputs, [name]: value}));
    };

    const setTopupsFormat = (topups) => {
        for (let i = 0; i < topups.length; i++) {
            topups[i].timestamp2string = timestamp2string(topups[i].create_time);
            topups[i].key = '' + topups[i].id;
        }
        // data.key = '' + data.id
        setTopups(topups);
        setTopupsCount(topups.length + ITEMS_PER_PAGE);
    }

    const loadTopups = async (startIdx) => {
        setLoading(true);
        let url = '';
        let localStartTimestamp = Date.parse(start_timestamp) / 1000;
        let localEndTimestamp = Date.parse(end_timestamp) / 1000;
        if (isAdminUser) {
            url = `/api/agent/topups?p=${startIdx}&platform=${platform}&agent_id=${agent_id}&trade_no=${trade_no}&user_id=${user_id}&status=${status}&start_timestamp=${localStartTimestamp}&end_timestamp=${localEndTimestamp}`;
        } else {
            url = `/api/agent/user/topups?p=${startIdx}&trade_no=${trade_no}&user_id=${user_id}&status=${status}&start_timestamp=${localStartTimestamp}&end_timestamp=${localEndTimestamp}`;
        }

        const res = await API.get(url);
        const {success, message, data} = res.data;
        if (success) {
            if (startIdx === 0) {
                setTopupsFormat(data);
            } else {
                let newTopups = [...topups];
                newTopups.splice(startIdx * ITEMS_PER_PAGE, data.length, ...data);
                setTopupsFormat(newTopups);
            }
        } else {
            showError(message);
        }
        setLoading(false);
    };

    const pageData = topups.slice((activePage - 1) * ITEMS_PER_PAGE, activePage * ITEMS_PER_PAGE);

    const handlePageChange = page => {
        setActivePage(page);
        if (page === Math.ceil(topups.length / ITEMS_PER_PAGE) + 1) {
            // In this case we have to load more data and then append them.
            loadTopups(page - 1).then(r => {
            });
        }
    };

    const renderStatus = (status) => {
        switch (status) {
            case "success":
                return <Tag color='cyan' size='large'> 已支付 </Tag>;
            case "pending":
                return <Tag color='lime' size='large'> 未支付 </Tag>;
            default:
                return <Tag color='black' size='large'> 未知状态</Tag>;
        }
    }

    const renderPlatform = (platform) => {
        switch (platform) {
            case "alipay":
                return <Tag color='light-blue' size='large'> 支付宝 </Tag>;
            case "wxpay":
                return <Tag color='light-green' size='large'> 微信 </Tag>;
            default:
                return <Tag color='black' size='large'> 未知 </Tag>;
        }
    }

    const showStat = async () => {
        if (!isAdminUser) {
            return;
        }
        let localStartTimestamp = Date.parse(start_timestamp) / 1000;
        let localEndTimestamp = Date.parse(end_timestamp) / 1000;
        const res = await API.get(`/api/dashboard/topup?platform=${platform}&agent_id=${agent_id}&trade_no=${trade_no}&user_id=${user_id}&status=${status}&start_timestamp=${localStartTimestamp}&end_timestamp=${localEndTimestamp}`);
        const {success, message, data} = res.data;
        if (success) {
            Modal.info({
                title: "统计信息",
                content: <div style={{padding: 12}}>
                    <p>{JSON.stringify(data, null, 2)}</p>
                </div>,
                centered: true,
            })
        } else {
            showError(message);
        }
    };

    const refresh = async () => {
        // setLoading(true);
        setActivePage(1);
        await loadTopups(0);
    };

    useEffect(() => {
        refresh().then();
    }, []);

    return (
        <>
        <Layout>
            <Form style={{display: 'flex', alignItems: 'center'}}>
                <Form.Input field="trade_no" label='订单号' style={{width: 176, marginRight: '10px'}} value={trade_no}
                            placeholder='输入查询的订单号' name='trade_no'
                            onChange={value => handleInputChange(value, 'trade_no')}/>
                <Form.Input field="user_id" label='用户ID' style={{width: 176, marginRight: '10px'}} value={user_id}
                            placeholder='输入查询的用户ID' name='user_id'
                            onChange={value => handleInputChange(value, 'user_id')}/>
                {
                    isAdminUser && (<>

                        <Form.Input field="user_id" label='代理ID' style={{width: 176, marginRight: '10px'}} value={agent_id}
                                    placeholder='输入查询的代理ID' name='agent_id'
                                    onChange={value => handleInputChange(value, 'agent_id')}/>
                        <div style={{width: '120px', marginRight: '10px', marginTop: '28px'}}>
                            <Select label="平台" defaultValue="" style={{width: 120, marginTop: 5, marginBottom: 10}} onChange={
                                (value) => {
                                    handleInputChange(value, 'platform')
                                }
                            }>
                                <Select.Option value="">全部平台</Select.Option>
                                <Select.Option value="alipay">支付宝</Select.Option>
                                <Select.Option value="wxpay">微信</Select.Option>
                            </Select>
                        </div>
                    </>)
                }
                <div style={{width: '120px', marginRight: '10px', marginTop: '28px'}}>
                    <Select label="订单状态" defaultValue="" style={{width: 120, marginTop: 5, marginBottom: 10}} onChange={
                        (value) => {
                            handleInputChange(value, 'status')
                        }
                    }>
                        <Select.Option value="">全部状态</Select.Option>
                        <Select.Option value="success">已支付</Select.Option>
                        <Select.Option value="pending">未支付</Select.Option>
                    </Select>
                </div>
                <Form.DatePicker field="start_timestamp" label='起始时间' style={{width: 272, marginRight: '10px'}}
                                 value={start_timestamp} type='dateTime' initValue={zeroNow}
                                 name='start_timestamp'
                                 onChange={value => handleInputChange(value, 'start_timestamp')}/>
                <Form.DatePicker field="end_timestamp" fluid label='结束时间' style={{width: 272}}
                                 value={end_timestamp} type='dateTime' initValue={now}
                                 name='end_timestamp'
                                 onChange={value => handleInputChange(value, 'end_timestamp')}/>
                <div style={{paddingTop: '28px'}}>
                    <Button label='查询' type="primary" htmlType="submit" style={{marginLeft: 10, marginTop: 5, marginBottom: 10}} onClick={refresh}>查询</Button>
                    {isAdminUser && <Button label='统计' type="primary" className="btn-margin-right" onClick={showStat} style={{ marginLeft: '8px' }}>统计</Button>}
                </div>
            </Form>
            <Table columns={columns} dataSource={pageData} pagination={{
                currentPage: activePage,
                pageSize: ITEMS_PER_PAGE,
                total: topupsCount,
                pageSizeOpts: [10, 20, 50, 100],
                onPageChange: handlePageChange,
            }} loading={loading}/>
        </Layout>
        </>
    )
}

export default TopupsTable;