import React, { useEffect, useState } from 'react';
import { Button, Divider, Form, Grid, Header, Message, Modal } from 'semantic-ui-react';
import { API, showError, showSuccess } from '../helpers';
import { marked } from 'marked';
import { useTranslation } from 'react-i18next';

const OtherSetting = () => {
  let [inputs, setInputs] = useState({
    Footer: '',
    Notice: '',
    About: '',
    SystemName: '',
    Logo: '',
    HomePageContent: '',
    AgentPageContent: '',
    Contact: '',
    SiteDescription: '',
    SiteKeywords: '',
    Models: '',
    LayoutType: '0'
  });
  let [loading, setLoading] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [updateData, setUpdateData] = useState({
    tag_name: '',
    content: ''
  });
  const { t } = useTranslation();

  const getOptions = async () => {
    const res = await API.get('/api/option/');
    const { success, message, data } = res.data;
    if (success) {
      let newInputs = {};
      data.forEach((item) => {
        if (item.key in inputs) {
          newInputs[item.key] = item.value;
        }
      });
      setInputs(newInputs);
    } else {
      showError(message);
    }
  };

  useEffect(() => {
    getOptions().then();
  }, []);

  const updateOption = async (key, value) => {
    setLoading(true);
    const res = await API.put('/api/option/', {
      key,
      value
    });
    const { success, message } = res.data;
    if (success) {
      setInputs((inputs) => ({ ...inputs, [key]: value }));
      showSuccess(`配置修改成功`);
    } else {
      showError(message);
    }
    setLoading(false);
  };

  const handleInputChange = async (e, { name, value }) => {
    setInputs((inputs) => ({ ...inputs, [name]: value }));
  };

  const submitSiteDescription = async () => {
    await updateOption('SiteDescription', inputs.SiteDescription);
  };

  const submitSiteKeywords = async () => {
    await updateOption('SiteKeywords', inputs.SiteKeywords);
  };

  const submitModels = async () => {
    await updateOption('Models', inputs.Models);
  };

  const submitContact = async () => {
    await updateOption('Contact', inputs.Contact);
  };

  const submitNotice = async () => {
    await updateOption('Notice', inputs.Notice);
  };

  const submitFooter = async () => {
    await updateOption('Footer', inputs.Footer);
  };

  const submitSystemName = async () => {
    await updateOption('SystemName', inputs.SystemName);
  };

  const submitLayoutype = async () => {
    await updateOption('LayoutType', inputs.LayoutType);
  };

  const submitLogo = async () => {
    await updateOption('Logo', inputs.Logo);
  };

  const submitAbout = async () => {
    await updateOption('About', inputs.About);
  };

  const submitOption = async (key) => {
    await updateOption(key, inputs[key]);
  };

  const openGitHubRelease = () => {
    window.location =
      'https://github.com/songquanpeng/one-api/releases/latest';
  };

  const checkUpdate = async () => {
    const res = await API.get(
      'https://api.github.com/repos/songquanpeng/one-api/releases/latest'
    );
    const { tag_name, body } = res.data;
    if (tag_name === process.env.REACT_APP_VERSION) {
      showSuccess(t('OtherSetting.OtherSetting1', { tag_name }));
    } else {
      setUpdateData({
        tag_name: tag_name,
        content: marked.parse(body)
      });
      setShowUpdateModal(true);
    }
  };

  return (
    <Grid columns={1}>
      <Grid.Column>
        <Form loading={loading}>
          <Header as='h3'>{t('OtherSetting.OtherSetting2')}</Header>
          {/*<Form.Button onClick={checkUpdate}>检查更新</Form.Button>*/}
          <Form.Group widths='equal'>
            <Form.Input
              label={t('OtherSetting.OtherSetting29')}
              placeholder={t('OtherSetting.OtherSetting31')}
              value={inputs.Contact}
              name='Contact'
              onChange={handleInputChange}
            />
          </Form.Group>
          <Form.Button onClick={submitContact}>{t('OtherSetting.OtherSetting30')}</Form.Button>
          <Form.Group widths='equal'>
            <Form.TextArea
              label={t('OtherSetting.OtherSetting4')}
              placeholder={t('OtherSetting.OtherSetting5')}
              value={inputs.Notice}
              name='Notice'
              onChange={handleInputChange}
              style={{ minHeight: 150, fontFamily: 'JetBrains Mono, Consolas' }}
            />
          </Form.Group>
          <Form.Button onClick={submitNotice}>{t('OtherSetting.OtherSetting6')}</Form.Button>
          <Divider />
          <Header as='h3'>{t('OtherSetting.OtherSetting7')}</Header>
          <label style={{ fontSize: '13px', fontWeight: 500 }}>菜单栏布局</label>
          <Form.Group>
            <Form.Radio
              label='原版'
              value='0'
              name='LayoutType'
              checked={inputs.LayoutType === '0'}
              onChange={handleInputChange}
            />
            <Form.Radio
              label='横版'
              value='1'
              name='LayoutType'
              checked={inputs.LayoutType === '1'}
              onChange={handleInputChange}
            />
          </Form.Group>
          <Form.Button onClick={submitLayoutype}>设置菜单布局</Form.Button>
          <Form.Group widths='equal'>
            <Form.Input
              label={t('OtherSetting.OtherSetting8')}
              placeholder={t('OtherSetting.OtherSetting9')}
              value={inputs.SystemName}
              name='SystemName'
              onChange={handleInputChange}
            />
          </Form.Group>
          <Form.Button onClick={submitSystemName}>{t('OtherSetting.OtherSetting10')}</Form.Button>
          <Form.Group widths='equal'>
            <Form.Input
              label={t('OtherSetting.OtherSetting11')}
              placeholder={t('OtherSetting.OtherSetting12')}
              value={inputs.Logo}
              name='Logo'
              type='url'
              onChange={handleInputChange}
            />
          </Form.Group>
          <Form.Button onClick={submitLogo}>{t('OtherSetting.OtherSetting13')}</Form.Button>
          <Form.Group widths='equal'>
            <Form.TextArea
              label={t('OtherSetting.OtherSetting14')}
              placeholder={t('OtherSetting.OtherSetting15')}
              value={inputs.HomePageContent}
              name='HomePageContent'
              onChange={handleInputChange}
              style={{ minHeight: 150, fontFamily: 'JetBrains Mono, Consolas' }}
            />
          </Form.Group>
          <Form.Button onClick={() => submitOption('HomePageContent')}>{t('OtherSetting.OtherSetting16')}</Form.Button>
          <Form.Group widths='equal'>
            <Form.TextArea
              label={t('OtherSetting.OtherSetting17')}
              placeholder={t('OtherSetting.OtherSetting18')}
              value={inputs.About}
              name='About'
              onChange={handleInputChange}
              style={{ minHeight: 150, fontFamily: 'JetBrains Mono, Consolas' }}
            />
          </Form.Group>
          <Form.Button onClick={submitAbout}>{t('OtherSetting.OtherSetting19')}</Form.Button>
          <Form.Group widths='equal'>
            <Form.Input
              label={t('OtherSetting.OtherSetting20')}
              placeholder={t('OtherSetting.OtherSetting21')}
              value={inputs.Footer}
              name='Footer'
              onChange={handleInputChange}
            />
          </Form.Group>
          <Form.Button onClick={submitFooter}>{t('OtherSetting.OtherSetting22')}</Form.Button>
          <Form.Group widths='equal'>
            <Form.TextArea
              label={t('OtherSetting.OtherSetting26')}
              placeholder={t('OtherSetting.OtherSetting27')}
              value={inputs.AgentPageContent}
              name='AgentPageContent'
              onChange={handleInputChange}
              style={{ minHeight: 150, fontFamily: 'JetBrains Mono, Consolas' }}
            />
          </Form.Group>
          <Form.Button onClick={() => submitOption('AgentPageContent')}>{t('OtherSetting.OtherSetting28')}</Form.Button>
          <Form.Group widths='equal'>
            <Form.TextArea
              label="站点描述 SEO 用"
              placeholder="用于 SEO 以及链接预览"
              value={inputs.SiteDescription}
              name='SiteDescription'
              onChange={handleInputChange}
              style={{ minHeight: 150, fontFamily: 'JetBrains Mono, Consolas' }}
            />
          </Form.Group>
          <Form.Button onClick={() => submitSiteDescription('SiteDescription')}>保存站点描述</Form.Button>
          <Form.Group widths='equal'>
            <Form.TextArea
              label="站点Keywords SEO 用"
              placeholder="用于 SEO Keywords，多个通过,拼接"
              value={inputs.SiteKeywords}
              name='SiteKeywords'
              onChange={handleInputChange}
              style={{ minHeight: 150, fontFamily: 'JetBrains Mono, Consolas' }}
            />
          </Form.Group>
          <Form.Button onClick={() => submitSiteKeywords('SiteKeywords')}>保存站点Keywords</Form.Button>
          <Form.Group widths='equal'>
            <Form.TextArea
              label="支持模型页"
              placeholder=""
              value={inputs.Models}
              name='Models'
              onChange={handleInputChange}
              style={{ minHeight: 150, fontFamily: 'JetBrains Mono, Consolas' }}
            />
          </Form.Group>
          <Form.Button onClick={() => submitModels()}>保存Models</Form.Button>
        </Form>
      </Grid.Column>
    </Grid>
  );
};

export default OtherSetting;
