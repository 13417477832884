import React, { useState } from 'react';
import { API, isMobile, showError, showSuccess } from '../../helpers';
import Title from "@douyinfe/semi-ui/lib/es/typography/title";
import { Button, SideSheet, Space, Input, Spin } from "@douyinfe/semi-ui";
import { useTranslation } from 'react-i18next';

const AddUser = (props) => {
  const originInputs = {
    username: '',
    display_name: '',
    password: '',
  };
  const [inputs, setInputs] = useState(originInputs);
  const [loading, setLoading] = useState(false);
  const { username, display_name, password } = inputs;
  const { t } = useTranslation();

  const handleInputChange = (name, value) => {
    setInputs((inputs) => ({ ...inputs, [name]: value }));
  };

  const submit = async () => {
    setLoading(true);
    if (inputs.username === '' || inputs.password === '') return;
    const res = await API.post(`/api/user/`, inputs);
    const { success, message } = res.data;
    if (success) {
      showSuccess(t('AddUser.AddUser1'));
      setInputs(originInputs);
      props.refresh();
      props.handleClose();
    } else {
      showError(message);
    }
    setLoading(false);
  };

  const handleCancel = () => {
    props.handleClose();
  }

  return (
    <>
      <SideSheet
        placement={'left'}
        title={<Title level={3}>{t('AddUser.AddUser10')}</Title>}
        headerStyle={{ borderBottom: '1px solid var(--semi-color-border)' }}
        bodyStyle={{ borderBottom: '1px solid var(--semi-color-border)' }}
        visible={props.visible}
        footer={
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Space>
              <Button theme='solid' size={'large'} onClick={submit}>{t('AddUser.AddUser9')}</Button>
              <Button theme='solid' size={'large'} type={'tertiary'} onClick={handleCancel}>{t('AddUser.AddUser11')}</Button>
            </Space>
          </div>
        }
        closeIcon={null}
        onCancel={() => handleCancel()}
        width={isMobile() ? '100%' : 600}
      >
        <Spin spinning={loading}>
          <Input
            style={{ marginTop: 20 }}
            label={t('AddUser.AddUser3')}
            name="username"
            addonBefore={t('AddUser.AddUser3')}
            placeholder={t('AddUser.AddUser4')}
            onChange={value => handleInputChange('username', value)}
            value={username}
            autoComplete="off"
          />
          <Input
            style={{ marginTop: 20 }}
            addonBefore={t('AddUser.AddUser5')}
            label={t('AddUser.AddUser5')}
            name="display_name"
            autoComplete="off"
            placeholder={t('AddUser.AddUser6')}
            onChange={value => handleInputChange('display_name', value)}
            value={display_name}
          />
          <Input
            style={{ marginTop: 20 }}
            label={t('AddUser.AddUser7')}
            name="password"
            type={'password'}
            addonBefore={t('AddUser.AddUser7')}
            placeholder={t('AddUser.AddUser8')}
            onChange={value => handleInputChange('password', value)}
            value={password}
            autoComplete="off"
          />
        </Spin>
      </SideSheet>
    </>
  );
};

export default AddUser;
