/*
 * @Author: heyan
 * @Date: 2023-11-24 20:56:09
 * @LastEditors: heyan
 * @LastEditTime: 2023-11-29 15:00:54
 * @Description: 
 */
import { langCommon } from './locales' //公共需要翻译的内容
// 把所有的翻译资源集合
const resources = {
  en: {
    translation: {
      ...langCommon.en_common
    },
  },
  zh: {
    translation: {
      ...langCommon.zh_common
    },
  },
  ja: {
    translation: {
      ...langCommon.ja_common
    },
  },
  ko: {
    translation: {
      ...langCommon.ko_common
    },
  }
}
export { resources }
