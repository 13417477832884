import React, {useEffect, useRef, useState} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import {API, isMobile, downloadTextAsFile, showError, showSuccess, timestamp2string} from '../../helpers';
import {getQuotaPerUnit, renderQuota, renderQuotaNonDoll, renderQuotaWithPrompt} from '../../helpers/render';
import {
    Layout,
    SideSheet,
    Button,
    Space,
    Spin,
    Banner,
    Input,
    DatePicker,
    AutoComplete,
    Typography,
    Checkbox, Select, Tooltip
} from "@douyinfe/semi-ui";
import Title from "@douyinfe/semi-ui/lib/es/typography/title";
import {Divider} from "semantic-ui-react";
import {useTranslation} from "react-i18next";

const EditToken = (props) => {
    const { t } = useTranslation();
    const [isEdit, setIsEdit] = useState(false);
    const [loading, setLoading] = useState(isEdit);
    let quotaPerUnit = localStorage.getItem('quota_per_unit');
    const originInputs = {
        name: '',
        remain_quota: isEdit ? 0 : 10,
        expired_time: -1,
        unlimited_quota: false,
        model_limits_enabled: false,
        models: [],
        ip_whitelist: "",
        notice_threshold: 0,
        num: 1,
    };
    const [inputs, setInputs] = useState(originInputs);
    const {name, remain_quota, expired_time, unlimited_quota, model_limits_enabled, models, ip_whitelist, notice_threshold, num} = inputs;
    // const [visible, setVisible] = useState(false);
    const [allModels, setAllModels] = useState({});
    const navigate = useNavigate();
    const handleInputChange = (name, value) => {
        setInputs((inputs) => ({...inputs, [name]: value}));
    };
    const handleCancel = () => {
        props.handleClose();
    }
    const setExpiredTime = (month, day, hour, minute) => {
        let now = new Date();
        let timestamp = now.getTime() / 1000;
        let seconds = month * 30 * 24 * 60 * 60;
        seconds += day * 24 * 60 * 60;
        seconds += hour * 60 * 60;
        seconds += minute * 60;
        if (seconds !== 0) {
            timestamp += seconds;
            setInputs({...inputs, expired_time: timestamp2string(timestamp)});
        } else {
            setInputs({...inputs, expired_time: -1});
        }
    };

    const setUnlimitedQuota = () => {
        setInputs({...inputs, unlimited_quota: !unlimited_quota});
    };

    const loadModels = async () => {
        let res = await API.get(`/api/user/models`);
        const {success, message, data} = res.data;
        if (success) {
            let localModelOptions = data.map((model) => ({
                label: model,
                value: model
            }));
            setAllModels(localModelOptions);
        } else {
            showError(message);
        }
    }

    const loadToken = async () => {
        setLoading(true);
        let res = await API.get(`/api/token/${props.editingToken.id}`);
        const {success, message, data} = res.data;
        if (success) {
            if (data.expired_time !== -1) {
                data.expired_time = timestamp2string(data.expired_time);
            }
            if (data.models !== '') {
                data.models = data.models.split(',');
            } else {
                data.models = [];
            }
            setInputs({
                ...data,
                remain_quota: parseFloat(data.remain_quota)/quotaPerUnit,
                notice_threshold: parseFloat(data.notice_threshold)/quotaPerUnit,
            });
        } else {
            showError(message);
        }
        setLoading(false);
    };
    useEffect(() => {
        setIsEdit(props.editingToken.id !== undefined);
    }, [props.editingToken.id]);

    useEffect(() => {
       if (!isEdit) {
           setInputs(originInputs);
       } else {
           loadToken().then(
               () => {
                   // console.log(inputs);
               }
           );
       }
       loadModels();
    }, [isEdit]);

    const submit = async () => {
        if (!isEdit && inputs.name === '') return;
        setLoading(true);
        let localInputs = inputs;

        if (localInputs.unlimited_quota) {
            localInputs.remain_quota = 0;
        } else {
            const d = localInputs.remain_quota * quotaPerUnit
            localInputs.remain_quota = parseInt(d.toString());
        }

        if (localInputs.notice_threshold) {
            const d = localInputs.notice_threshold * quotaPerUnit
            localInputs.notice_threshold = parseInt(d.toString());
        }

        if (localInputs.expired_time !== -1) {
            let time = Date.parse(localInputs.expired_time);
            if (isNaN(time)) {
                showError(t('EditToken.EditToken1'));
                return;
            }
            localInputs.expired_time = Math.ceil(time / 1000);
        }
        if (localInputs.models) {
            localInputs.models = localInputs.models.join(',');
        }

        let res;
        if (isEdit) {
            res = await API.put(`/api/token/`, {...localInputs, id: parseInt(props.editingToken.id)});
        } else {
            if (localInputs.num > 1) {
                res = await API.post(`/api/token/bulk?num=${localInputs.num}`, localInputs);
            } else {
                res = await API.post(`/api/token/`, localInputs);
            }
        }
        const {success, message, data} = res.data;
        if (success) {
            if (isEdit) {
                showSuccess(t('EditToken.EditToken2'));
                props.refresh();
                props.handleClose();
            } else {
                showSuccess(t('EditToken.EditToken3'));
                setInputs(originInputs);
                props.refresh();
                props.handleClose();
                if (localInputs.num > 1) {
                    let text = "";
                    for (let i = 0; i < data.length; i++) {
                        text += "sk-"+data[i] + "\n";
                    }
                    downloadTextAsFile(text, `${inputs.name}.txt`);
                }
            }
        } else {
            showError(message);
        }
        setLoading(false);
    };



    return (
        <>
            <SideSheet
                placement={isEdit ? 'right' : 'left'}
                title={<Title level={3}>{isEdit ? t('EditToken.EditToken4') : t('EditToken.EditToken5')}</Title>}
                headerStyle={{borderBottom: '1px solid var(--semi-color-border)'}}
                bodyStyle={{borderBottom: '1px solid var(--semi-color-border)'}}
                visible={props.visiable}
                footer={
                    <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                        <Space>
                            <Button theme='solid' size={'large'} onClick={submit}>{t('EditToken.EditToken6') }</Button>
                            <Button theme='solid' size={'large'} type={'tertiary'} onClick={handleCancel}>{t('EditToken.EditToken7') }</Button>
                        </Space>
                    </div>
                }
                closeIcon={null}
                onCancel={() => handleCancel()}
                width={isMobile() ? '100%' : 600}
            >
                <Spin spinning={loading}>
                    <div style={{ marginTop: 20 }}>
                        <Typography.Text>{t('EditToken.EditToken8')}</Typography.Text>
                    </div>
                    <Input
                        style={{ marginTop: 8 }}
                        label={t('EditToken.EditToken8')}
                        name='name'
                        placeholder={t('EditToken.EditToken9')}
                        onChange={(value) => handleInputChange('name', value)}
                        value={name}
                        autoComplete='new-password'
                        required={!isEdit}
                    />
                    <Divider/>
                    <div style={{ marginBottom: 8 }}>
                        <Typography.Text>{t('EditToken.EditToken10')}</Typography.Text>
                    </div>
                    <DatePicker
                        label={t('EditToken.EditToken10')}
                        name='expired_time'
                        placeholder={t('EditToken.EditToken11')}
                        onChange={(value) => handleInputChange('expired_time', value)}
                        value={expired_time}
                        autoComplete='new-password'
                        type='dateTime'
                    />
                    <div style={{ marginTop: 20 }}>
                        <Space>
                            <Button type={'tertiary'} onClick={() => {
                                setExpiredTime(0, 0, 0, 0);
                            }}>{t('EditToken.EditToken12')}</Button>
                            <Button type={'tertiary'} onClick={() => {
                                setExpiredTime(0, 0, 1, 0);
                            }}>{t('EditToken.EditToken13')}</Button>
                            <Button type={'tertiary'} onClick={() => {
                                setExpiredTime(1, 0, 0, 0);
                            }}>{t('EditToken.EditToken14')}</Button>
                            <Button type={'tertiary'} onClick={() => {
                                setExpiredTime(0, 1, 0, 0);
                            }}>{t('EditToken.EditToken15')}</Button>
                        </Space>
                    </div>

                    <Divider/>
                    <Banner type={'warning'} description={t('EditToken.EditToken16', {unit: 500000})}></Banner>
                    <div style={{ marginTop: 20 }}>
                        <Typography.Text>{t('EditToken.EditToken17', { data: `（等价金额：${remain_quota}$）`})}</Typography.Text>
                    </div>
                    <AutoComplete
                        style={{ marginTop: 8 }}
                        name='remain_quota'
                        placeholder={t('EditToken.EditToken18')}
                        onChange={(value) => handleInputChange('remain_quota', value)}
                        value={remain_quota}
                        autoComplete='new-password'
                        type='number'
                        // position={'top'}
                        data={[
                            {value: 1, label: '1$'},
                            {value: 10, label: '10$'},
                            {value: 50, label: '50$'},
                            {value: 100, label: '100$'},
                            {value: 500, label: '500$'},
                            {value: 1000, label: '1000$'},
                        ]}
                        disabled={unlimited_quota}
                    />

                    {!isEdit && (
                        <>
                            <div style={{marginTop: 20}}>
                                <Typography.Text>{t('EditToken.EditToken19')}</Typography.Text>
                            </div>
                            <AutoComplete
                                style={{ marginTop: 8 }}
                                name='num'
                                placeholder={t('EditToken.EditToken20')}
                                onChange={(value) => handleInputChange('num', value)}
                                onSelect={(value) => handleInputChange('num', value)}
                                value={num}
                                autoComplete='off'
                                type='number'
                                data={[
                                    { value: 10, label: '10个' },
                                    { value: 20, label: '20个' },
                                    { value: 30, label: '30个' },
                                    { value: 100, label: '100个' },
                                ]}
                                disabled={unlimited_quota}
                            />
                        </>
                    )}

                    <div>
                        <Button style={{ marginTop: 8 }} type={'warning'} onClick={() => {
                            setUnlimitedQuota();
                        }}>{unlimited_quota ? t('EditToken.EditToken21') : t('EditToken.EditToken22')}</Button>
                    </div>

                    <Divider/>
                    <div style={{marginTop: 10}}>
                        <Typography.Text strong>{t('EditToken.EditToken26')}</Typography.Text>
                    </div>
                    <div style={{marginTop: 10}}>
                        <Typography.Text >{t('EditToken.EditToken24')}</Typography.Text>
                    </div>
                    <div style={{marginTop: 10}}>
                        <Typography.Text >{t('EditToken.EditToken27')}</Typography.Text>
                    </div>
                    <Input
                        style={{ marginTop: 8 }}
                        label={t('EditToken.EditToken27')}
                        name='ip_whitelist'
                        placeholder={t('EditToken.EditToken28')}
                        onChange={(value) => handleInputChange('ip_whitelist', value)}
                        value={ip_whitelist}
                        autoComplete='new-password'
                    />
                    <div style={{marginTop: 10}}>
                        <Tooltip
                            position='topLeft'
                            content='到达预警额度自动提醒，需要先在个人设置中订阅消息'>
                            <Typography.Text >{`预警额度 ${notice_threshold}$`}</Typography.Text>
                        </Tooltip>
                    </div>
                    <AutoComplete
                        style={{ marginTop: 8 }}
                        name='notice_threshold'
                        placeholder="到达预警额度自动提醒"
                        onChange={(value) => handleInputChange('notice_threshold', value)}
                        value={notice_threshold}
                        autoComplete='new-password'
                        type='number'
                        position={'top'}
                        data={[
                            {value: 1, label: '1$'},
                            {value: 10, label: '10$'},
                            {value: 50, label: '50$'},
                            {value: 100, label: '100$'},
                            {value: 500, label: '500$'},
                            {value: 1000, label: '1000$'},
                        ]}
                        disabled={unlimited_quota}
                    />

                    <div style={{marginTop: 10, display: 'flex'}}>
                        <Space>
                            <Checkbox
                                name='model_limits_enabled'
                                checked={model_limits_enabled}
                                onChange={(e) => handleInputChange('model_limits_enabled', e.target.checked)}
                            >
                            </Checkbox>
                            <Typography.Text>启用模型限制（非必要，不建议启用）</Typography.Text>
                        </Space>
                    </div>

                    <Select
                        style={{marginTop: 8, marginBottom: 20}}
                        placeholder={t('EditToken.EditToken25')}
                        name='models'
                        required
                        multiple
                        selection
                        onChange={value => {
                            handleInputChange('models', value);
                        }}
                        value={inputs.models}
                        autoComplete='new-password'
                        optionList={allModels}
                        disabled={!model_limits_enabled}
                    />


                </Spin>
            </SideSheet>
        </>
    );
};

export default EditToken;
