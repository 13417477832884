import React, { lazy, Suspense, useContext, useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import Loading from './components/Loading';
import User from './pages/User';
import { PrivateRoute } from './components/PrivateRoute';
import RegisterForm from './components/RegisterForm';
import LoginForm from './components/LoginForm';
import NotFound from './pages/NotFound';
import Setting from './pages/Setting';
import EditUser from './pages/User/EditUser';
import { API, getLogo, getSystemName, showError } from './helpers';
import PasswordResetForm from './components/PasswordResetForm';
import GitHubOAuth from './components/GitHubOAuth';
import PasswordResetConfirm from './components/PasswordResetConfirm';
import { UserContext } from './context/User';
import { StatusContext } from './context/Status';
import Channel from './pages/Channel';
import Token from './pages/Token';
import EditChannel from './pages/Channel/EditChannel';
import Redemption from './pages/Redemption';
import TopUp from './pages/TopUp';
import Log from './pages/Log';
import Chat from './pages/Chat';
import { Layout } from "@douyinfe/semi-ui";
import Midjourney from "./pages/Midjourney";
import Detail from "./pages/Detail";
import Custom from "./pages/Custom";
import Agent from "./pages/Agent";
import './i18n/i18n'
import { useTranslation } from 'react-i18next';
const Home = lazy(() => import('./pages/Home'));
const About = lazy(() => import('./pages/About'));
const Introduce = lazy(() => import('./pages/Introduce'));

// 这是你的组件列表
const COMPONENT_LIST = {
    '/': Home,
    '/channel': Channel,
    '/token': Token,
    '/redemption': Redemption,
    '/user': User,
    '/setting': Setting,
    '/topup': TopUp,
    '/log': Log,
    '/detail': Detail,
    '/midjourney': Midjourney,
    '/about': About,
    '/chat': Chat,
    '/agent': Agent,
    '/models': Introduce
};


function App() {
    const [userState, userDispatch] = useContext(UserContext);
    const [statusState, statusDispatch] = useContext(StatusContext);
    const [routesData, setRoutesData] = useState([])

    const loadUser = () => {
        let user = localStorage.getItem('user');
        if (user) {
            let data = JSON.parse(user);
            userDispatch({ type: 'login', payload: data });
        }
    };

    const loadStatus = async () => {
        const res = await API.get('/api/status');
        const { success, data } = res.data;
        if (success) {
            localStorage.setItem('status', JSON.stringify(data));
            statusDispatch({ type: 'set', payload: data });
            localStorage.setItem('price', data.price);
            localStorage.setItem('system_name', data.system_name);
            localStorage.setItem('server_address', data.server_address);
            localStorage.setItem('logo', data.logo);
            localStorage.setItem('footer_html', data.footer_html);
            localStorage.setItem('quota_per_unit', data.quota_per_unit);
            localStorage.setItem('quota_for_new_user', data.quota_for_new_user);
            localStorage.setItem('display_in_currency', data.display_in_currency);
            localStorage.setItem('contact', data.contact);
            localStorage.setItem('enable_drawing', data.enable_drawing);
            localStorage.setItem('enable_data_export', data.enable_data_export);
            localStorage.setItem('data_export_default_time', data.data_export_default_time);
            localStorage.setItem('layout_type', data.layout_type || '1');
            if (data.chat_link) {
                localStorage.setItem('chat_link', data.chat_link);
            } else {
                localStorage.removeItem('chat_link');
            }
        } else {
            showError('无法正常连接至服务器！');
        }
    };

    const getOptions = async () => {
        const res = await API.get('/api/menus');
        const { success, message, data } = res.data;
        if (success) {
            setRoutesData(data || []);
            userDispatch({ type: 'meun', payload: data });
        } else {
            showError(message);
        }
    };

    const [update, setUpdate] = useState(false)
    useEffect(() => {
        setUpdate(true)
        loadUser();
        loadStatus().then();
        let systemName = getSystemName();
        if (systemName) {
            document.title = systemName;
        }
        let logo = getLogo();
        if (logo) {
            let linkElement = document.querySelector("link[rel~='icon']");
            if (linkElement) {
                linkElement.href = logo;
            }
        }
    }, []);

    useEffect(() => {
        if (update) {
            getOptions()
        }
    }, [update, userState.user, userState.update])

    return (
        <Layout>
            <Layout.Content>
                {
                    !routesData.length && <Loading />
                }
                {
                    routesData.length &&
                    <Routes>
                        {routesData.map((route, index) => {
                            const Component = COMPONENT_LIST[route.menu_path] || Custom;
                            if (route['menu_data'] && !route['need_auth']) {
                                return (
                                    <Route
                                        key={index}
                                        path={route.menu_path}
                                        element={
                                            <Suspense fallback={<Loading />}>
                                                <Component url={route['menu_data']} />
                                            </Suspense>
                                        }
                                    />
                                )

                            } else if (route.need_auth) {
                                return (
                                    <Route
                                        key={index}
                                        path={route.menu_path}
                                        element={
                                            <PrivateRoute>
                                                <Suspense fallback={<Loading />}>
                                                    <Component />
                                                </Suspense>
                                            </PrivateRoute>
                                        }
                                    />
                                );
                            } else if (route.show) {
                                return (
                                    <Route
                                        key={index}
                                        path={route.menu_path}
                                        element={
                                            <Suspense fallback={<Loading />}>
                                                <Component />
                                            </Suspense>
                                        }
                                    />
                                );
                            }
                        })}
                        <Route
                            path='/channel/edit/:id'
                            element={
                                <Suspense fallback={<Loading></Loading>}>
                                    <EditChannel />
                                </Suspense>
                            }
                        />
                        <Route
                            path='/channel/add'
                            element={
                                <Suspense fallback={<Loading></Loading>}>
                                    <EditChannel />
                                </Suspense>
                            }
                        />
                        <Route
                            path='/user/edit/:id'
                            element={
                                <Suspense fallback={<Loading></Loading>}>
                                    <EditUser />
                                </Suspense>
                            }
                        />
                        <Route
                            path='/user/edit'
                            element={
                                <Suspense fallback={<Loading></Loading>}>
                                    <EditUser />
                                </Suspense>
                            }
                        />
                        <Route
                            path='/user/reset'
                            element={
                                <Suspense fallback={<Loading></Loading>}>
                                    <PasswordResetConfirm />
                                </Suspense>
                            }
                        />
                        <Route
                            path='/login'
                            element={
                                <Suspense fallback={<Loading></Loading>}>
                                    <LoginForm />
                                </Suspense>
                            }
                        />
                        <Route
                            path='/register'
                            element={
                                <Suspense fallback={<Loading></Loading>}>
                                    <RegisterForm />
                                </Suspense>
                            }
                        />
                        <Route
                            path='/reset'
                            element={
                                <Suspense fallback={<Loading></Loading>}>
                                    <PasswordResetForm />
                                </Suspense>
                            }
                        />
                        <Route
                            path='/oauth/github'
                            element={
                                <Suspense fallback={<Loading></Loading>}>
                                    <GitHubOAuth />
                                </Suspense>
                            }
                        />
                        <Route path='*' element={
                            <NotFound />
                        } />
                    </Routes>
                }
            </Layout.Content>
        </Layout>
    );
}

export default App;
