/*
 * @Author: heyan
 * @Date: 2023-10-24 21:50:41
 * @LastEditors: heyan
 * @LastEditTime: 2023-11-28 14:48:02
 * @Description: 
 */
import React from 'react';
import { Segment, Dimmer, Loader } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

const Loading = ({ prompt: name = 'page' }) => {
  const { t } = useTranslation();
  return (
    <Segment style={{ height: 100 }}>
      <Dimmer active inverted>
        <Loader indeterminate>{t('common.loading', {name})}</Loader>
      </Dimmer>
    </Segment>
  );
};

export default Loading;
