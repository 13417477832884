import React, { useState, useEffect } from 'react';
import { API, showError } from '../../helpers';
import { Tree, Select } from '@douyinfe/semi-ui';
import { Card, TextArea } from 'semantic-ui-react';
import log from "../Log";
const TreeNode = () => {
  const [treeData, setTreeData] = useState([]);
  const [nodeTreeData, setNodeTreeData] = useState([]);
  const [selectedInfo, setSelectedInfo] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const style = {
    width: 320,
    height: '120vh',
    border: '1px solid var(--semi-color-border)',
    margin: '0px 10px 0px 0px'
  };

  const getNodeData = async () => {
    const res = await API.get('/api/agent/agent_tree');
    const { success, message, data } = res.data;
    if (success) {
      setTreeData([{ domain: 'one-api.bltcy.top', ...data }] || [])
    } else {
      showError(message);
    }
  }

  const transformTreeData = (nodes) => {
    return nodes.map(node => {
      const { user_id, domain, info, options, nodes: children } = node;
      return {
        label: (
            <>
              <Card style={{width: '95%'}}>
                <Card.Content>
                  <Card.Header style={{display: 'flex', justifyContent: 'space-between', fontSize: '12px'}}>
                    <span>{domain}</span>
                    <span>{user_id}</span>
                  </Card.Header>
                  {
                    info && (
                        <div style={{fontSize: '11px'}}>
                          <div style={{display: 'flex', justifyContent: 'space-between'}}><div>订单数：{info.order_num}</div><div>订单总金额：{(info.order_money / 100).toFixed(2)}</div></div>
                          <div style={{display: 'flex', justifyContent: 'space-between'}}><div>可提现：{(info.share_profit_quota / 100).toFixed(2)}</div><div>总分红：{(info.share_profit / 100).toFixed(2)}</div></div>
                          <div>用户数：{info.user_num}</div>
                        </div>
                      )
                  }

                </Card.Content>
              </Card>
            </>
        ),
        //label: `${domain}${user_id ? `（${user_id}）` : ''}`,
        key: user_id?.toString() || 'total',
        info,
        options,
        domain,
        children: children ? transformTreeData(children) : []
      };
    });
  }

  const onTreeChange = async (selectedKeys, selected, infos) => {
    if (infos && infos.info) {
      console.log(infos)
      const {user_id} = infos.info
      if (!user_id) return
      const res = await API.get(`/api/agent/detail?user_id=${user_id}`);
      const { success, message, data } = res.data;
      if (success) {
        setSelectedInfo(JSON.stringify(data.info, null, 2))
        setSelectedOptions(JSON.stringify(data.options, null, 2))
      } else {
        showError(message);
        setSelectedInfo(null);
        setSelectedOptions(null);
      }
    }
  }

  const sortByUserNumDesc = (tree, prop) => {
    // A recursive function to sort each node
    function sortNodes(nodes) {
      if (!nodes || !Array.isArray(nodes)) return;

      nodes.sort((a, b) => b.info[prop] - a.info[prop]);

      // Recursively sort child nodes
      for (const node of nodes) {
        sortNodes(node.nodes);
      }
    }

    // Start sorting from the root nodes
    sortNodes(tree);

    return tree;
  }

  const orderChange = (v) => {
    if (v === 1) {
      setNodeTreeData(transformTreeData(JSON.parse(JSON.stringify(treeData))))
    } else {
      const newTree = sortByUserNumDesc(JSON.parse(JSON.stringify(treeData)), v)
      setNodeTreeData(transformTreeData(newTree))
    }
    setRefresh(true)
    setTimeout(() => {
      setRefresh(false)
    })
  }

  const filterTreeData = (nodes, searchValue) => {
    return nodes.reduce((acc, node) => {
      if (node.domain.toLowerCase().includes(searchValue.toLowerCase()) || node.user_id == searchValue) {
        acc.push(node);
      } else if (node.children) {
        const filteredChildren = filterTreeData(node.children, searchValue);
        if (filteredChildren.length > 0) {
          acc.push({ ...node, children: filteredChildren });
        }
      }
      return acc;
    }, []);
  };

  const filterTreeNode = (v,n,node) => {
    console.log(v,n,node)
    // 如果没有搜索值，则所有节点都显示
    if (!v) {
      return true;
    }
    // 检查节点的domain是否包含搜索值
    return node.domain.toLowerCase().includes(v.toLowerCase());
  };

  useEffect(() => {
    getNodeData()
  }, [])

  useEffect(() => {
    const result = transformTreeData(treeData)
    setNodeTreeData(result)
  }, [treeData])

  return (
    <div style={{display: 'flex', justifyContent: 'space-between'}}>
      <div>
        <Select defaultValue="1" style={{ width: '97%' }} onChange={orderChange}>
          <Select.Option value="1">默认排序</Select.Option>
          <Select.Option value="user_num">用户数</Select.Option>
          <Select.Option value="order_num">订单数</Select.Option>
          <Select.Option value="order_money">订单总金额</Select.Option>
          <Select.Option value="share_profit_quota">可提现</Select.Option>
          <Select.Option value="share_profit">总分红</Select.Option>
        </Select>
        <Tree
            showLine={true}
            defaultExpandAll
            treeData={nodeTreeData}
            style={style}
            onSelect={onTreeChange}
            filterTreeNode={filterTreeNode}
            showFilteredOnly={true}
        />
      </div>
      <div style={{display: 'flex', flexDirection: 'column', flex: 1}}>
        <Card
          style={{ width: '100%', minHeight: '200px', margin: '0px' }}
          color='yellow'
        >
          <Card.Content>
            <Card.Header>代理信息</Card.Header>
            {
              selectedInfo &&
              <TextArea
                value={selectedInfo}
                style={{ width: '100%', height:'90%', border: 'none', margin: '5px 0px' }} // 根据需要设置合适的宽度和高度
                onChange={e => setSelectedInfo(e.target.value)}
            />
            }
          </Card.Content>
        </Card>
        <Card
          style={{ width: '100%', height: '100%', margin: '10px 0px 0px 0px' }}
          color='blue'
        >
          <Card.Content>
            <Card.Header>配置信息</Card.Header>
            {
              selectedOptions &&
              <TextArea
                value={selectedOptions}
                style={{ width: '100%', height: '90%', border: 'none', margin: '5px 0px' }} // 根据需要设置合适的宽度和高度
                onChange={e => setSelectedOptions(e.target.value)}
            />
            }
          </Card.Content>
        </Card>
      </div>
    </div>
  );
}

export default TreeNode;