/*
 * @Author: heyan
 * @Date: 2023-12-02 13:03:33
 * @LastEditors: heyan
 * @LastEditTime: 2024-03-27 18:01:10
 * @Description: 
 */
import React, { useState } from 'react';
import { Tab, Segment } from 'semantic-ui-react';
import MyForm from './MyForm'
import Rule from './Rule'
import TopupsTable from './topup'
import TreeNode from './TreeNode'
import { useTranslation } from 'react-i18next';
import { isAdmin, isAgent, isMobile } from "../../helpers";
import { Layout, Card } from "@douyinfe/semi-ui";

const Agent = () => {
  const { t } = useTranslation()
  const isAgentUser = isAgent();
  const isAdminUser = isAdmin();
  const layoutType = localStorage.getItem("layout_type");
  const showSider = isMobile() || layoutType === '0';
  let panes = [
    {
      menuItem: t('Agent.Agent1'),
      render: () => (
        <Tab.Pane attached={false}>
          <Rule />
        </Tab.Pane>
      )
    },
    {
      menuItem: t('Agent.Agent2'),
      render: () => (
        <Tab.Pane attached={false}>
          <MyForm />
        </Tab.Pane>
      )
    }
  ];

  if (isAgentUser) {
    panes.push({
      menuItem: "订单记录",
      render: () => (
        <Tab.Pane attached={false}>
          <TopupsTable />
        </Tab.Pane>
      )
    })
  }
  if (isAdminUser) {
    panes.push({
      menuItem: "代理分析",
      render: () => (
        <Tab.Pane attached={false}>
          <TreeNode />
        </Tab.Pane>
      )
    })
  }

  return (
    <>
      <Layout>
        {
          !showSider &&
          <Layout.Header style={{ textAlign: 'center', margin: '24px 0px' }}>
            <h3>代理商信息</h3>
          </Layout.Header>
        }
        <Layout.Content>
          <Card style={{ maxWidth: '1800px', minHeight: '580px', margin: 'auto' }}>
            <Tab menu={{ secondary: true, pointing: true }} panes={panes} />
          </Card>
        </Layout.Content>
      </Layout>
    </>
  );
};

export default Agent;
