/*
 * @Author: heyan
 * @Date: 2023-11-24 16:11:47
 * @LastEditors: heyan
 * @LastEditTime: 2024-03-24 20:55:25
 * @Description: 
 */
import React from 'react';
import TokensTable from '../../components/TokensTable';
import { Banner, Layout, Card } from "@douyinfe/semi-ui";
import { useTranslation } from 'react-i18next';

const Token = () => {
  const { t } = useTranslation();
  let server_address = localStorage.getItem('server_address');
  if (!server_address) {
    server_address = window.location.origin;
  }

  return (
    <>
      <Layout>
        <Layout.Header style={{ textAlign: 'center', margin: '24px 0px' }}>
          <h3>{t('EditToken.EditToken23') }</h3>
          <Banner fullMode={false} type="info" bordered icon={null} closeIcon={null}
                  title={<div style={{ fontWeight: 600, fontSize: '14px', lineHeight: '20px' }}>
                    将 OpenAI API 基础地址 https://api.openai.com 替换为 {server_address} ，复制下面的密钥 {localStorage.getItem('chat_link') ? "或点击聊天" : ""}即可使用
                  </div>}
          />
            <br/>
        </Layout.Header>
        <Layout.Content>
          <Card style={{ maxWidth: '1800px', minHeight: '580px', margin: 'auto' }}>
            <TokensTable />
          </Card>
        </Layout.Content>
      </Layout>
    </>
  )
};

export default Token;
