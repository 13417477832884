/*
 * @Author: heyan
 * @Date: 2023-12-04 21:11:59
 * @LastEditors: heyan
 * @LastEditTime: 2024-03-27 18:05:34
 * @Description: 
 */
import React from 'react';
import SystemSetting from '../../components/SystemSetting';
import { isAgent, isMobile } from '../../helpers';
import OtherSetting from '../../components/OtherSetting';
import PersonalSetting from '../../components/PersonalSetting';
import OperationSetting from '../../components/OperationSetting';
import DynamicMenuSetting from '../../components/DynamicMenuSetting';
import { Layout, TabPane, Tabs, Card } from "@douyinfe/semi-ui";
import { useTranslation } from 'react-i18next';

const layoutType = localStorage.getItem("layout_type");
const showSider = isMobile() || layoutType === '0';

const Setting = () => {
    const { t } = useTranslation()
    let panes = [
        {
            tab: t('Setting.Setting1'),
            content: <PersonalSetting />,
            itemKey: '1'
        }
    ];

    if (isAgent()) {
        panes.push({
            tab: t('Setting.Setting2'),
            content: <OperationSetting />,
            itemKey: '2'
        });
        panes.push({
            tab: t('Setting.Setting3'),
            content: <SystemSetting />,
            itemKey: '3'
        });
        panes.push({
            tab: t('Setting.Setting4'),
            content: <OtherSetting />,
            itemKey: '4'
        });
        panes.push({
            tab: t('Setting.Setting5'),
            content: <DynamicMenuSetting />,
            itemKey: '5'
        });
    }

    return (
        <div>
            <Layout>
                {
                    !showSider &&
                    <Layout.Header style={{ textAlign: 'center', margin: '24px 0px' }}>
                        <h3>设置</h3>
                    </Layout.Header>
                }
                <Layout.Content>
                    <Card style={{ maxWidth: '1800px', minHeight: '580px', margin: 'auto' }}>
                        <Tabs type="line" defaultActiveKey="1">
                            {panes.map(pane => (
                                <TabPane itemKey={pane.itemKey} tab={pane.tab}>
                                    {pane.content}
                                </TabPane>
                            ))}
                        </Tabs>
                    </Card>
                </Layout.Content>
            </Layout>
        </div>
    );
};

export default Setting;
