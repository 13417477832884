/*
 * @Author: heyan
 * @Date: 2023-10-24 21:50:41
 * @LastEditors: heyan
 * @LastEditTime: 2024-03-24 21:10:30
 * @Description: 
 */
import React from 'react';
import { Segment, Header } from 'semantic-ui-react';
import UsersTable from '../../components/UsersTable';
import { useTranslation } from 'react-i18next';
import { Card } from "@douyinfe/semi-ui";

const User = () => {
  const { t } = useTranslation();
  return (
    <>
      <Header as='h3' style={{ textAlign: 'center', margin: '24px 0px' }}>{t('EditUser.EditUser29')}</Header>
      <Card style={{ maxWidth: '1800px', minHeight: '580px', margin: 'auto' }}>
        <UsersTable />
      </Card>
    </>
  );
}

export default User;
