/*
 * @Author: heyan
 * @Date: 2024-03-16 10:47:02
 * @LastEditors: heyan
 * @LastEditTime: 2024-03-27 18:04:34
 * @Description: 
 */
import React from 'react';
import MjLogsTable from '../../components/MjLogsTable';
import { Banner, Layout } from "@douyinfe/semi-ui";
import { Card } from "@douyinfe/semi-ui";
import { isMobile } from "../../helpers";

const layoutType = localStorage.getItem("layout_type");
const showSider = isMobile() || layoutType === '0';
/*const Midjourney = () => (
  <>
    <MjLogsTable />
  </>
);*/

const Midjourney = () => {
    /*const { t } = useTranslation();
    let server_address = localStorage.getItem('server_address');
    if (!server_address) {
        server_address = window.location.origin;
    }*/

    return (
        <>
            <Layout>
                {
                    !showSider &&
                    <Layout.Header style={{ textAlign: 'center', margin: '24px 0px' }}>
                        <h3>Midjourney 绘图记录</h3>
                    </Layout.Header>
                }
                <Layout.Content>
                    <Card style={{ maxWidth: '1800px', minHeight: '580px', margin: 'auto' }}>
                        <Banner fullMode={false} type="info" bordered icon={null} closeIcon={null}
                            title={<div style={{ fontWeight: 600, fontSize: '14px', lineHeight: '20px' }}>
                                费用为 0 的任务表示未扣费或已补回额度
                            </div>}
                        />
                        <Banner fullMode={false} type="warning" bordered icon={null} closeIcon={null}
                            title={<div style={{ fontWeight: 600, fontSize: '14px', lineHeight: '20px' }}>
                                注：因中转账号多且轮换频繁，绘图后长时间再次操作此图可能会出现关联操作已不存在
                            </div>}
                        />
                        <Banner fullMode={false} type="warning" bordered icon={null} closeIcon={null}
                            title={<div style={{ fontWeight: 600, fontSize: '14px', lineHeight: '20px' }}>
                                提交 prompt 内容中有中文将会自动翻译成英文，如需精准生成，请使用英文提交，不会再次翻译
                            </div>}
                        />
                        <div style={{ marginTop: '20px' }}></div>
                        <MjLogsTable />
                    </Card>
                </Layout.Content>
            </Layout>
        </>
    )
};

export default Midjourney;
