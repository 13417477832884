/*
 * @Author: heyan
 * @Date: 2023-12-02 13:12:18
 * @LastEditors: heyan
 * @LastEditTime: 2024-01-14 20:13:31
 * @Description: 
 */
import React, { useState, useEffect } from 'react';
import { Form, Input, TextArea, Header, Button, Card, Icon } from 'semantic-ui-react';
import {API, showSuccess, showError, isAgent, isAdmin, timestamp2string} from '../../helpers';
import { useTranslation } from 'react-i18next';
import {Tag, Table, Select} from "@douyinfe/semi-ui";


const MyForm = () => {
  const { t } = useTranslation()
  const [agentInfo, setAgentInfo] = useState( {
    "id": 0,
    "created_at": 0,
    "updated_at": 0,
    "user_id": 0,
    "balance_account": "",
    "balance_username": "",
    "balance_platform": "",
    "level": 0,
    "user_num": 0,
    "order_num": 0,
    "order_money": 0,
    "share_profit": 0,
    "share_profit_quota": 0
  });
  const [listCard, setListCard] = useState([])
  const [pageData, setPageData] = useState([])
  const [loading, setLoading] = useState(false)
  const [columns, setColumns] = useState([])
  const [subLoading, setSubLoading] = useState(false)

  const renderStatus = (status) => {
    switch (status) {
      case "success":
        return <Tag color='cyan' size='large'> 已结算 </Tag>;
      case "pending":
        return <Tag color='lime' size='large'> 未结算 </Tag>;
      case "rejected":
        return <Tag color='red' size='large'> 已拒绝 </Tag>;
      default:
        return <Tag color='black' size='large'> 未知状态</Tag>;
    }
  }

  const init = async () => {
    const res = await API.get('/api/agent/');
    const { data } = res.data;
    if (data) {
      setAgentInfo(data)
      setListCard([
        {
          header: `${data.level === 1 ? t('Agent.Agent3') : t('Agent.Agent4')}`,
          meta: t('Agent.Agent5'),
        },
        {
          header: data.user_num,
          meta: t('Agent.Agent6'),
        },
        {
          header: data.order_num,
          meta: t('Agent.Agent7'),
        },
        {
          header: (data.order_money/100).toFixed(2),
          meta: t('Agent.Agent8'),
        },
        {
          header: (data.share_profit/100).toFixed(2),
          meta: t('Agent.Agent9'),
        },
        {
          header: (data.share_profit_quota/100).toFixed(2),
          meta: <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>{t('Agent.Agent10')}<Button loading={subLoading} size='mini' primary onClick={applySettles}>申请结算</Button></div>
        }
      ])
    }
  }

  const initColumn = () => {
    if (isAdmin()) {
      setColumns([
        {
          title: "创建时间",
          dataIndex: 'created_at',
          render: (text, record, index) => {
            return (
                <div>
                  {text ? timestamp2string(text) : '-'}
                </div>
            );
          },
        },
        {
          title: "用户",
          dataIndex: 'user_id',
        },
        {
          title: "结算方式",
          dataIndex: 'balance_platform',
          render: (text, record, index) => {
            return (
              <div style={{display: 'flex', alignItems: 'center'}}>
                {text === '微信' && <img style={{height: '20px'}} src='/wechat.png'/>}
                {text === '支付宝' && <img style={{ height: '20px' }} src='/alipay.png' />}
              </div>
            );
          },
        },
        {
          title: "结算账号/姓名",
          dataIndex: 'balance_pl',
          render: (text, record, index) => {
            return (
              <div>
                {record.balance_account}&nbsp;{record.balance_username}
              </div>
            );
          },
        },
        {
          title: "状态",
          dataIndex: 'status',
          render: (text, record, index) => {
            return (
                <div>
                  {renderStatus(text)}
                </div>
            );
          },
        },
        {
          title: "金额",
          dataIndex: 'money',
          render: (text, record, index) => {
            return (
                <div>
                  {(text/100).toFixed(2)}
                </div>
            );
          },
        },
        {
          title: "结算时间",
          dataIndex: 'balance_time',
          render: (text, record, index) => {
            return (
                <div>
                  {text ? timestamp2string(text) : '-'}
                </div>
            );
          },
        },
        {
          title: "操作",
          dataIndex: 'operate',
          render: (text, record, index) => {
            return (
                <div>
                  <Select placeholder="修改状态" style={{width: '120px'}} defaultValue="" onChange={
                    (value) => {
                      setStatus(value, record)
                    }
                  }
                  >
                    <Select.Option value="pending">待结算</Select.Option>
                    <Select.Option value="success">已结算</Select.Option>
                    <Select.Option value="rejected">拒绝</Select.Option>
                  </Select>
                </div>
            );
          },
        }
      ])
    } else {
      setColumns([
        {
          title: "创建时间",
          dataIndex: 'created_at',
          render: (text, record, index) => {
            return (
                <div>
                  {text ? timestamp2string(text) : '-'}
                </div>
            );
          },
        },
        {
          title: "状态",
          dataIndex: 'status',
          render: (text, record, index) => {
            return (
                <div>
                  {renderStatus(text)}
                </div>
            );
          },
        },
        {
          title: "金额",
          dataIndex: 'money',
          render: (text, record, index) => {
            return (
                <div>
                  {(text/100).toFixed(2)}
                </div>
            );
          },
        },
        {
          title: "结算时间",
          dataIndex: 'balance_time',
          render: (text, record, index) => {
            return (
                <div>
                  {text ? timestamp2string(text) : '-'}
                </div>
            );
          },
        }
      ])
    }
  }

  const setStatus = async (v, record) => {
    const res = await API.put('/api/agent/admin/balance', {
      id:record.id,
      status: v
    });
    const { success, message } = res.data;
    if (success) {
      showSuccess('操作成功');
      getPageData()
    } else {
      showError(message);
    }
  }
  const applySettles = async () => {
    setSubLoading(true)
    const res = await API.post('/api/agent/balance');
    const { success, message } = res.data;
    if (success) {
      showSuccess('申请成功');
      getPageData()
    } else {
      showError(message);
    }
    setSubLoading(false)
  }

  const getPageData = async () => {
    setLoading(true)
    const url = isAdmin() ? '/api/agent/admin/balances' : '/api/agent/balances'
    const res = await API.get(url);
    const { success, message, data } = res.data;
    if (success) {
      setPageData(data)
    } else {
      showError(message);
    }
    setLoading(false)
  }

  useEffect(() => {
    initColumn()
    init()
    getPageData()
  }, [])

  const exampleSubmit = async (v) => {
    const res = await API.put('/api/agent', v);
    const { success, message } = res.data;
    if (success) {
      getPageData()
      showSuccess('操作成功');
    } else {
      showError(message);
    }
  }

  return (
      <>
        <Form>
          <Card.Group items={listCard} />
        </Form>
        <Card style={{width: '100%', padding: '14px'}}>
        {(isAdmin() || isAgent()) ? <FormExampleUnstackable submit={exampleSubmit} info={agentInfo} /> : null}
        </Card>
        <h6 style={{fontSize: '20px', fontWeight: 600, margin: '20px 0px', textAlign: 'center', paddingTop: '10px', 'border-top': '1px solid #ddd'}}>结算记录</h6>
        <Table pagination={null} columns={columns} dataSource={pageData} loading={loading}/>
      </>
  );
};

const FormExampleUnstackable = (props) => {
  // 创建状态钩子来存储表单输入
  const [balancePlatform, setBalancePlatform] = useState(props.info.balance_platform);
  const [balanceAccount, setBalanceAccount] = useState(props.info.balance_account);
  const [balanceUsername, setBalanceUsername] = useState(props.info.balance_username);

  useEffect(() => {
    setBalancePlatform(props.info.balance_platform)
    setBalanceAccount(props.info.balance_account)
    setBalanceUsername(props.info.balance_username)
  }, [props.info])
  // 结算平台的选项
  const platformOptions = [
    { key: 'alipay', text: '支付宝', value: '支付宝' },
    { key: 'wechat', text: '微信', value: '微信' }
  ];

  // 处理表单提交
  const handleSubmit = (event) => {
    event.preventDefault(); // 阻止表单默认提交行为

    // 创建一个对象来保存表单数据
    const formData = {
      balance_platform: balancePlatform,
      balance_account: balanceAccount,
      balance_username: balanceUsername
    };

    // 调用传递给组件的submit函数，并传入表单数据
    props.submit(formData);

    // 这里可以添加保存逻辑，例如发送数据到后端服务器
  };

  // 更新状态的函数
  const handlePlatformChange = (e, { value }) => {
    setBalancePlatform(value);
  };

  const handleInputChange = (setter) => (event) => {
    setter(event.target.value);
  };

  return (
    <Form unstackable size="tiny" onSubmit={handleSubmit}>
      <Form.Group widths={2}>
        <Form.Select
          label='结算平台'
          placeholder='请选择结算平台'
          options={platformOptions}
          value={balancePlatform}
          onChange={handlePlatformChange}
          required
        />
        <Form.Input
          label='结算账户'
          placeholder='请输入结算账户'
          value={balanceAccount}
          onChange={handleInputChange(setBalanceAccount)}
          required
        />
        <Form.Input
          label='账户昵称'
          placeholder='请输入账户昵称'
          value={balanceUsername}
          onChange={handleInputChange(setBalanceUsername)}
          required
        />
      </Form.Group>
      <Button type='submit'>保存</Button>
    </Form>
  );
}


export default MyForm;
