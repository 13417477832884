/*
 * @Author: heyan
 * @Date: 2023-10-24 21:50:41
 * @LastEditors: heyan
 * @LastEditTime: 2023-11-29 10:44:40
 * @Description: 
 */
import React from 'react';
import { Message } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

const NotFound = () => { 
  const { t } = useTranslation();
  return (
    <>
      <Message negative>
        <Message.Header>{t('NotFound.NotFound1')}</Message.Header>
        <p>{t('NotFound.NotFound2')}</p>
      </Message>
    </>
  );
}

export default NotFound;
