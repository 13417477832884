import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { UserContext } from '../context/User';
import { API, getLogo, isMobile, showError, showInfo, showSuccess, showWarning } from '../helpers';
import { onGitHubOAuthClicked } from './utils';
import Turnstile from "react-turnstile";
import { Layout, Card, Image, Form, Button, Divider, Modal } from "@douyinfe/semi-ui";
import Title from "@douyinfe/semi-ui/lib/es/typography/title";
import Text from "@douyinfe/semi-ui/lib/es/typography/text";
import { useTranslation } from "react-i18next";
import { driver } from "driver.js";
import "driver.js/dist/driver.css";

import { IconGithubLogo } from '@douyinfe/semi-icons';
import {renderQuotaNonDoll} from "../helpers/render";

const LoginForm = () => {
    const [inputs, setInputs] = useState({
        username: '',
        password: '',
        wechat_verification_code: ''
    });
    const [searchParams, setSearchParams] = useSearchParams();
    const [submitted, setSubmitted] = useState(false);
    const { username, password } = inputs;
    const [userState, userDispatch] = useContext(UserContext);
    const [turnstileEnabled, setTurnstileEnabled] = useState(false);
    const [turnstileSiteKey, setTurnstileSiteKey] = useState('');
    const [turnstileToken, setTurnstileToken] = useState('');
    let navigate = useNavigate();
    const [status, setStatus] = useState({});
    const [systemName, setSystemName] = useState('');
    const logo = getLogo();
    const { t } = useTranslation();
    const [showWeChatLoginModal, setShowWeChatLoginModal] = useState(false);
    const quota_for_new_user = renderQuotaNonDoll(localStorage.getItem("quota_for_new_user")); //localStorage.getItem("quota_for_new_user");
    useEffect(() => {
        if (searchParams.get('expired')) {
            showError(t('LoginForm.LoginForm1'));
        }
        let status = localStorage.getItem('status');
        if (status) {
            status = JSON.parse(status);
            setStatus(status);
            if (status.turnstile_check) {
                setTurnstileEnabled(true);
                setTurnstileSiteKey(status.turnstile_site_key);
            }
        }
        setSystemName(localStorage.getItem("system_name"))
    }, []);

    const onWeChatLoginClicked = () => {
        setShowWeChatLoginModal(true);
    };

    const onSubmitWeChatVerificationCode = async () => {
        if (turnstileEnabled && turnstileToken === '') {
            showInfo(t('LoginForm.LoginForm2'));
            return;
        }
        const res = await API.get(
            `/api/oauth/wechat?code=${inputs.wechat_verification_code}`
        );
        const { success, message, data } = res.data;
        if (success) {
            userDispatch({ type: 'login', payload: data });
            localStorage.setItem('user', JSON.stringify(data));
            navigate('/');
            showSuccess(t('common.loginSuccess'));
            setShowWeChatLoginModal(false);
        } else {
            showError(message);
        }
    };

    function handleChange(name, value) {
        setInputs((inputs) => ({ ...inputs, [name]: value }));
    }

    async function handleSubmit(e) {
        if (turnstileEnabled && turnstileToken === '') {
            showInfo(t('LoginForm.LoginForm2'));
            return;
        }
        setSubmitted(true);
        if (username && password) {
            const res = await API.post(`/api/user/login?turnstile=${turnstileToken}`, {
                username,
                password
            });
            const { success, message, data } = res.data;
            if (success) {
                userDispatch({ type: 'login', payload: data });
                localStorage.setItem('user', JSON.stringify(data));
                showSuccess(t('common.loginSuccess'));
                if (username === 'root' && password === '123456') {
                    Modal.error({ title: t('LoginForm.LoginForm13'), content: t('LoginForm.LoginForm14'), centered: true });
                }
                navigate('/token');
                if (!data.user_settings.done_getting_start) {
                    setTimeout(() => {
                        drawDriver()
                    }, 2000)
                }
            } else {
                showError(message);
            }
        } else {
            showError(t('LoginForm.LoginForm4'));
        }
    }

    const drawDriver = () => {
        let currentStep = 0;
        const steps = [
            {
                element: ".page-header",
                popover: {
                    title: t('drivier.drivier1'),
                    description: t('drivier.drivier2', {name: systemName}),
                }
            },
            {
                element: ".driver-step1",
                popover: {
                    title: t('drivier.drivier3'),
                    description: t('drivier.drivier4'),
                }
            },
            {
                element: ".driver-step2",
                popover: {
                    title: t('drivier.drivier3'),
                    description: t('drivier.drivier15'),
                }
            },
            {
                element: ".driver-step3",
                popover: {
                    title: t('drivier.drivier3'),
                    description: t('drivier.drivier16'),
                },
            },
            {
                element: ".driver-step4",
                popover: {
                    title: t('drivier.drivier3'),
                    description: t('drivier.drivier17'),
                },
            },
            // 聊天
            {
                element: ".driver-step6",
                popover: {
                    title: t('drivier.drivier7'),
                    description: t('drivier.drivier8'),
                },
            },
            {
                element: ".driver-step7",
                popover: {
                    title: t('drivier.drivier7'),
                    description: t('drivier.drivier18', {quota_for_new_user: quota_for_new_user}),
                },
            },
            {
                element: ".driver-step8",
                popover: {
                    title: t('drivier.drivier7'),
                    description: t('drivier.drivier19'),
                },
            },
            {
                element: ".driver-step9",
                popover: {
                    title: t('drivier.drivier9'),
                    description: t('drivier.drivier10'),
                },
            },
            {
                element: ".driver-step10",
                popover: {
                    title: t('drivier.drivier11'),
                    description: t('drivier.drivier12'),
                },
            },
            {
                element: ".driver-step11",
                popover: {
                    title: t('drivier.drivier13'),
                    description: t('drivier.drivier14'),
                },
            },
            {
                element: ".driver-step12",
                popover: {
                    title: t('drivier.drivier20'),
                    description: t('drivier.drivier21'),
                },
            }
        ]
        if (localStorage.getItem('chat_link')) {
            steps.splice(4, 0,
                {
                    element: ".driver-step5",
                    popover: {
                        title: t('drivier.drivier5'),
                        description: t('drivier.drivier6'),
                    },
                }
            )
        }
        const driverObj = driver({
            onHighlighted: (element) => {
                currentStep++;
                if (currentStep === 2) {
                    navigate('/token');
                }
                if (currentStep === 6) {
                    navigate('/topup');
                }
            },
            nextBtnText: "下一个",
            prevBtnText: "上一个",
            doneBtnText: "完成",
            showProgress: true,
            steps,
            onDestroyStarted: async () => {
                await API.put('/api/user/settings', {
                    "done_getting_start": true
                })
                driverObj.destroy();
            }
        });

        driverObj.drive();
    };

    return (
        <div>
            <Layout>
                <Layout.Header>
                </Layout.Header>
                <Layout.Content>
                    <div style={{ justifyContent: 'center', display: "flex", marginTop: 120 }}>
                        <div style={{ width: 500 }}>
                            <Card>
                                <Title heading={2} style={{ textAlign: 'center' }}>
                                    {t('LoginForm.LoginForm5')}
                                </Title>
                                <Form>
                                    <Form.Input
                                        field={'username'}
                                        label={t('LoginForm.LoginForm6')}
                                        placeholder={t('LoginForm.LoginForm6')}
                                        name='username'
                                        onChange={(value) => handleChange('username', value)}
                                    />
                                    <Form.Input
                                        field={'password'}
                                        label={t('LoginForm.LoginForm7')}
                                        placeholder={t('LoginForm.LoginForm7')}
                                        name='password'
                                        type='password'
                                        onChange={(value) => handleChange('password', value)}
                                    />

                                    <Button theme='solid' style={{ width: '100%' }} type={'primary'} size='large'
                                        htmlType={'submit'} onClick={handleSubmit}>
                                        {t('common.login')}
                                    </Button>
                                </Form>
                                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 20 }}>
                                    <Text>
                                        {t('LoginForm.LoginForm8')} <Link to='/register'>{t('LoginForm.LoginForm10')}</Link>
                                    </Text>
                                    <Text>
                                        {t('LoginForm.LoginForm11')} <Link to='/reset'>{t('LoginForm.LoginForm12')}</Link>
                                    </Text>
                                </div>
                                {status.github_oauth || status.wechat_login ? (
                                    <>
                                        <Divider margin='12px' align='center'>
                                            {t('LoginForm.LoginForm9')}
                                        </Divider>
                                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
                                            {status.github_oauth ? (
                                                <Button
                                                    type='primary'
                                                    icon={<IconGithubLogo />}
                                                    onClick={() => onGitHubOAuthClicked(status.github_client_id)}
                                                />
                                            ) : (
                                                <></>
                                            )}
                                            {/*{status.wechat_login ? (*/}
                                            {/*    <Button*/}
                                            {/*        circular*/}
                                            {/*        color='green'*/}
                                            {/*        icon='wechat'*/}
                                            {/*        onClick={onWeChatLoginClicked}*/}
                                            {/*    />*/}
                                            {/*) : (*/}
                                            {/*    <></>*/}
                                            {/*)}*/}
                                        </div>
                                    </>
                                ) : (
                                    <></>
                                )}
                                {/*<Modal*/}
                                {/*    onClose={() => setShowWeChatLoginModal(false)}*/}
                                {/*    onOpen={() => setShowWeChatLoginModal(true)}*/}
                                {/*    open={showWeChatLoginModal}*/}
                                {/*    size={'mini'}*/}
                                {/*>*/}
                                {/*    <Modal.Content>*/}
                                {/*        <Modal.Description>*/}
                                {/*            <Image src={status.wechat_qrcode} fluid/>*/}
                                {/*            <div style={{textAlign: 'center'}}>*/}
                                {/*                <p>*/}
                                {/*                    微信扫码关注公众号，输入「验证码」获取验证码（三分钟内有效）*/}
                                {/*                </p>*/}
                                {/*            </div>*/}
                                {/*            <Form size='large'>*/}
                                {/*                <Form.Input*/}
                                {/*                    field={'wechat_verification_code'}*/}
                                {/*                    placeholder='验证码'*/}
                                {/*                    name='wechat_verification_code'*/}
                                {/*                    value={inputs.wechat_verification_code}*/}
                                {/*                    onChange={handleChange}*/}
                                {/*                />*/}
                                {/*                <Button*/}
                                {/*                    color=''*/}
                                {/*                    fluid*/}
                                {/*                    size='large'*/}
                                {/*                    onClick={onSubmitWeChatVerificationCode}*/}
                                {/*                >*/}
                                {/*                    登录*/}
                                {/*                </Button>*/}
                                {/*            </Form>*/}
                                {/*        </Modal.Description>*/}
                                {/*    </Modal.Content>*/}
                                {/*</Modal>*/}
                            </Card>
                            {turnstileEnabled ? (
                                <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
                                    <Turnstile
                                        sitekey={turnstileSiteKey}
                                        onVerify={(token) => {
                                            setTurnstileToken(token);
                                        }}
                                    />
                                </div>
                            ) : (
                                <></>
                            )}
                        </div>
                    </div>

                </Layout.Content>
            </Layout>
        </div>
    );
};

export default LoginForm;
