/*
 * @Author: heyan
 * @Date: 2024-03-27 12:27:42
 * @LastEditors: heyan
 * @LastEditTime: 2024-03-27 14:34:28
 * @Description: 
 */
import React, { useContext, useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { IconDoubleChevronLeft, IconDoubleChevronRight } from '@douyinfe/semi-icons'
import { getLogo, getSystemName, isAdmin, isAgent } from '../helpers';
import * as Icons from '@douyinfe/semi-icons';
import { UserContext } from '../context/User';
import HeaderBar from '../components/HeaderBar';

const IconComponent = ({ name }) => {
  if (!name) return '-';
  const IconsComponent = Icons[name];
  return <IconsComponent />
}

const MeunScroll = () => {
  const systemName = getSystemName();
  const logo = getLogo();
  const [headerButtons, setHeaderButtons] = useState([])
  const [userState] = useContext(UserContext);
  let navigate = useNavigate();

  const COMPONENT_LIST = {
    'home': {
      itemkey: 'home'
    },
    'channel': {
      itemKey: 'channel',
      className: isAdmin() ? '' : 'tableHiddle'
    },
    'token': {
      itemKey: 'token',
      className: 'driver-step1'
    },
    'redemption': {
      itemKey: 'redemption',
      className: isAdmin() ? '' : 'tableHiddle'
    },
    'user': {
      itemKey: 'user',
      className: isAgent() ? '' : 'tableHiddle'
    },
    'setting': {
      itemKey: 'setting'
    },
    'topup': {
      itemKey: 'topup',
      className: 'driver-step6'
    },
    'log': {
      itemKey: 'log',
      className: 'driver-step12'
    },
    'detail': {
      itemKey: 'detail',
      className: localStorage.getItem('enable_data_export') === 'true' ? '' : 'tableHiddle'
    },
    'midjourney': {
      itemKey: 'midjourney',
      className: 'driver-step9'
    },
    'about': {
      itemKey: 'about',
      className: 'driver-step10'
    },
    'chat': {
      itemKey: 'chat',
      className: localStorage.getItem('chat_link') ? ' driver-step5' : 'tableHiddle'
    },
    'agent': {
      itemKey: 'agent',
      className: 'driver-step11'
    },
    'models': {
      itemKey: 'models',
    }
  };

  const setMeun = (data) => {
    const newNavs = data.map((e) => {
      const value = COMPONENT_LIST[e.key]
      if (value) {
        return {
          ...value,
          text: e['menu_name'],
          to: e['menu_path'],
          menu_icon: e['menu_icon'],
          className: `${value.className} ${!e.show ? 'tableHiddle' : ''} ${e['need_auth'] && !userState.user ? 'tableHiddle' : ''}`,
          jump: e.jump
        }
      } else {
        return {
          itemKey: e.key,
          text: e['menu_name'],
          to: e['menu_path'],
          menu_icon: e['menu_icon'],
          className: `${!e.show ? 'tableHiddle' : ''}  ${e['need_auth'] && !userState.user ? 'tableHiddle' : ''}`,
          jump: e.jump

        }
      }
    })
    setHeaderButtons(newNavs);
  }

  useEffect(() => {
    if (userState.meun) {
      setMeun(userState.meun);
    }
  }, [userState])

  const scrollContainer = useRef(null);
  const parentContainer = useRef(null);
  const [showScrollButtons, setShowScrollButtons] = useState(false);
  const scrollStep = 200; // 您可以根据需要调整这个值

  useEffect(() => {
    const checkScroll = () => {
      if (scrollContainer.current && parentContainer.current) {
        if (scrollContainer.current.scrollWidth > parentContainer.current.offsetWidth) {
          setShowScrollButtons(true);
        } else {
          setShowScrollButtons(false);
        }
      }
    }
    checkScroll()
    window.addEventListener('resize', checkScroll);
    return () => window.removeEventListener('resize', checkScroll);
  }, [headerButtons]);

  const handleScrollLeft = () => {
    if (scrollContainer.current) {
      scrollContainer.current.scrollTo({
        left: scrollContainer.current.scrollLeft - scrollStep,
        behavior: 'smooth'
      });
    }
  }

  const handleScrollRight = () => {
    if (scrollContainer.current) {
      scrollContainer.current.scrollTo({
        left: scrollContainer.current.scrollLeft + scrollStep,
        behavior: 'smooth'
      });
    }
  }

  return (
    <div className="MeunScroll">
      <div className="MeunScroll__left">
        <div className="MeunScroll__logo">
          <img src={logo} alt='logo' />
          <div className="MeunScroll__name">
            {systemName}
          </div>
        </div>
      </div>
      <div className="line"></div>
      {showScrollButtons && <IconDoubleChevronLeft onClick={handleScrollLeft} className='MeunScroll-icon' />}
      <div ref={parentContainer} className='MeunScroll_center'>
        <div ref={scrollContainer} className='MeunScroll_center_box'>
          {
            headerButtons.map(e => {
              return (
                <div
                  key={e.itemKey}
                  className={`MeunScroll_center_list ${window.location.pathname === '/' ? e.itemKey ? '' : 'bar-active' : window.location.pathname.includes(e.itemKey) ? 'bar-active' : ''} ${e.className}`}
                  onClick={() => {
                    if (e.jump) {
                      window.open(e.to, '_blank');
                    } else {
                      navigate(e.to);
                    }
                  }}
                >
                  <IconComponent name={e['menu_icon']} />
                  <span style={{ marginLeft: '6px' }} >
                    {e.text}
                  </span>
                </div>
              )
            })
          }
        </div>
      </div>
      {showScrollButtons && <IconDoubleChevronRight onClick={handleScrollRight} className='MeunScroll-icon' />}
      <div className='MeunScroll_right'>
        <HeaderBar style={{ position: 'absolute', right: '0px' }} />
      </div>
    </div>
  )
}

export default MeunScroll

