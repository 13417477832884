import React, { useEffect, useState } from 'react';
import { Label } from 'semantic-ui-react';
import { API, copy, isAdmin, isAgent, showError, showSuccess, timestamp2string } from '../helpers';

import {
    Table,
    Avatar,
    Tag,
    Form,
    Button,
    Layout,
    Select,
    Popover,
    Modal,
    Spin,
    Collapse,
    RadioGroup, Radio, Col, Row, DatePicker, Input, Typography
} from '@douyinfe/semi-ui';
import { ITEMS_PER_PAGE } from '../constants';
import { renderNumber, renderQuota, stringToColor } from '../helpers/render';
import {
    IconAt,
    IconHistogram,
    IconGift,
    IconKey,
    IconUser,
    IconLayers,
    IconSetting,
    IconCreditCard,
    IconSemiLogo,
    IconHome,
    IconMore
} from '@douyinfe/semi-icons';
import { useTranslation } from 'react-i18next';
import ModelUsageBarChart from './ModelUsageBarChart';
import moment from 'moment';
const { Sider, Content, Header } = Layout;
const { Column } = Table;


function renderTimestamp(timestamp) {
    return (
        <>
            {timestamp2string(timestamp)}
        </>
    );
}

const MODE_OPTIONS = [
    { key: 'all', text: '全部用户', value: 'all' },
    { key: 'self', text: '当前用户', value: 'self' }
];

const colors = ['amber', 'blue', 'cyan', 'green', 'grey', 'indigo',
    'light-blue', 'lime', 'orange', 'pink',
    'purple', 'red', 'teal', 'violet', 'yellow'
]

const LogsTable = () => {
    const { t } = useTranslation();
    const isAdminUser = isAdmin();
    const isAgentUser = isAgent();

    const columns = [
        {
            title: t('LogsTable.LogsTable1'),
            dataIndex: 'timestamp2string',
        },
        {
            title: '分组',
            dataIndex: 'group',
            className: isAgentUser ? 'tableShow' : 'tableHiddle',
            render: (text, record, index) => {
                return (
                    isAgentUser ? {text} : <></>
                );
            },
        },
        {
            title: t('LogsTable.LogsTable2'),
            dataIndex: 'channel_id',
            className: isAdmin() ? 'tableShow' : 'tableHiddle',
            render: (text, record, index) => {
                return (
                    isAdminUser ?
                        record.type === 0 || record.type === 2 ?
                            <div>
                                {<Tag color={colors[parseInt(text) % colors.length]} size='large'> {text} </Tag>}
                            </div>
                            :
                            <></>
                        :
                        <></>
                );
            },
        },
        {
            title: "代理 ID",
            dataIndex: 'agent_id',
            className: isAdminUser ? 'tableShow' : 'tableHiddle',
            render: (text, record, index) => {
                return (
                    text ? text : "-"
                );
            },
        },
        {
            title: t('LogsTable.LogsTable3'),
            dataIndex: 'username',
            className: isAgentUser ? 'tableShow' : 'tableHiddle',
            render: (text, record, index) => {
                return (
                    <div>
                        <Avatar size="small" color={stringToColor(text)} style={{ marginRight: 4 }}
                            onClick={() => showUserInfo(record.user_id)}>
                            {typeof text === 'string' && text.slice(0, 1)}
                        </Avatar>
                        {text}
                    </div>
                );
            },
        },
        {
            title: t('LogsTable.LogsTable4'),
            dataIndex: 'token_name',
            render: (text, record, index) => {
                return (
                    record.type === 0 || record.type === 2 ?
                        <div>
                            <Tag color='grey' size='large' onClick={() => {
                                copyText(text)
                            }}> {text} </Tag>
                        </div>
                        :
                        <></>
                );
            },
        },
        {
            title: t('LogsTable.LogsTable5'),
            dataIndex: 'type',
            render: (text, record, index) => {
                return (
                    <div>
                        {renderType(text)}
                    </div>
                );
            },
        },
        {
            title: t('LogsTable.LogsTable6'),
            dataIndex: 'model_name',
            render: (text, record, index) => {
                return (
                    record.type === 0 || record.type === 2 ?
                        <div>
                            <Tag color={stringToColor(text)} size='large' onClick={() => {
                                copyText(text)
                            }}> {text} </Tag>
                        </div>
                        :
                        <></>
                );
            },
        },
        {
            title: t('LogsTable.LogsTable7'),
            dataIndex: 'prompt_tokens',
            render: (text, record, index) => {
                return (
                    record.type === 0 || record.type === 2 ?
                        <div>
                            {<span> {text} </span>}
                        </div>
                        :
                        <></>
                );
            },
        },
        {
            title: t('LogsTable.LogsTable8'),
            dataIndex: 'completion_tokens',
            render: (text, record, index) => {
                return (
                    parseInt(text) > 0 && (record.type === 0 || record.type === 2) ?
                        <div>
                            {<span> {text} </span>}
                        </div>
                        :
                        <></>
                );
            },
        },
        {
            title: t('LogsTable.LogsTable9'),
            dataIndex: 'quota',
            render: (text, record, index) => {
                return (
                    record.type === 0 || record.type === 2 ?
                        <div>
                            {
                                renderQuota(text, 6)
                            }
                        </div>
                        :
                        <></>
                );
            }
        },
        {
            title: t('LogsTable.LogsTable10'),
            dataIndex: 'response_time',
            className: isAdmin() ? 'tableShow' : 'tableHiddle',
            render: (text, record, index) => {
                return (
                    isAdminUser ?
                        <div>
                            {<span> {text ? text.toFixed(2) + 's' : ''} </span>}
                        </div>
                        :
                        <></>
                );
            },
        },
        {
            title: "IP",
            dataIndex: 'ip',
        },
        {
            title: t('LogsTable.LogsTable12'),
            dataIndex: 'content',
        }
    ];

    const [logs, setLogs] = useState([]);
    const [showStat, setShowStat] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingStat, setLoadingStat] = useState(false);
    const [activePage, setActivePage] = useState(1);
    const [logCount, setLogCount] = useState(ITEMS_PER_PAGE);
    const [searchKeyword, setSearchKeyword] = useState('');
    const [searching, setSearching] = useState(false);
    const [logType, setLogType] = useState(0);
    const [resetKey, setResetKey] = useState(0);
    let now = new Date();
    let zeroNow = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const [inputs, setInputs] = useState({
        username: '',
        token_name: '',
        model_name: '',
        user_id: '',
        agent_id: '',
        start_timestamp: timestamp2string(zeroNow.getTime() / 1000),
        end_timestamp: timestamp2string(now.getTime() / 1000 + 3600),
        channel_id: '',
        group: '',
    });
    const { username, token_name, model_name, start_timestamp, end_timestamp, channel_id, user_id, agent_id, group } = inputs;

    const [dashboard, setDashboard] = useState({
        RPM: 0,
        RPD: 0
    });

    const [stat, setStat] = useState({
        quota: 0,
        token: 0
    });

    const handleInputChange = (value, name) => {
        setInputs((inputs) => ({ ...inputs, [name]: value }));
    };

    const getLogSelfStat = async () => {
        let localStartTimestamp = Date.parse(start_timestamp) / 1000;
        let localEndTimestamp = Date.parse(end_timestamp) / 1000;
        let res = await API.get(`/api/log/self/stat?type=${logType}&token_name=${token_name}&model_name=${model_name}&start_timestamp=${localStartTimestamp}&end_timestamp=${localEndTimestamp}`);
        const { success, message, data } = res.data;
        if (success) {
            setStat(data);
        } else {
            showError(message);
        }
    };

    const getLogStat = async () => {
        let localStartTimestamp = Date.parse(start_timestamp) / 1000;
        let localEndTimestamp = Date.parse(end_timestamp) / 1000;
        let res = await API.get(`/api/log/stat?type=${logType}&username=${username}&token_name=${token_name}&model_name=${model_name}&start_timestamp=${localStartTimestamp}&end_timestamp=${localEndTimestamp}&channel_id=${channel_id}&user_id=${user_id}`);
        const { success, message, data } = res.data;
        if (success) {
            setStat(data);
        } else {
            showError(message);
        }
    };

    const handleEyeClick = async () => {
        setLoadingStat(true);
        if (isAdminUser) {
            await getLogStat();
        } else {
            await getLogSelfStat();
        }
        setShowStat(true);
        setLoadingStat(false);
    };

    const refreshLogStat = async () => {
        if (isAdminUser) {
            await getLogStat();
        } else {
            await getLogSelfStat();
        }
    }

    const showUserInfo = async (userId) => {
        if (!isAdminUser) {
            return;
        }
        const res = await API.get(`/api/user/${userId}`);
        const { success, message, data } = res.data;
        if (success) {
            Modal.info({
                title: t('LogsTable.LogsTable13'),
                content: <div style={{ padding: 12 }}>
                    <p>{"ID"}: {userId}</p>
                    <p>{t('LogsTable.LogsTable14')}: {data.username}</p>
                    <p>{t('LogsTable.LogsTable15')}: {renderQuota(data.quota)}</p>
                    <p>{t('LogsTable.LogsTable16')}：{renderQuota(data.used_quota)}</p>
                    <p>{t('LogsTable.LogsTable17')}：{renderNumber(data.request_count)}</p>
                </div>,
                centered: true,
            })
        } else {
            showError(message);
        }
    };

    const setLogsFormat = (logs) => {
        for (let i = 0; i < logs.length; i++) {
            logs[i].timestamp2string = timestamp2string(logs[i].created_at);
            logs[i].key = '' + logs[i].id;
        }
        // data.key = '' + data.id
        setLogs(logs);
        setLogCount(logs.length + ITEMS_PER_PAGE);
        // console.log(logCount);
    }

    const loadLogs = async (startIdx) => {
        setLoading(true);

        let url = '';
        let localStartTimestamp = Date.parse(start_timestamp) / 1000;
        let localEndTimestamp = Date.parse(end_timestamp) / 1000;
        if (isAdminUser) {
            url = `/api/log/?p=${startIdx}&type=${logType}&username=${username}&token_name=${token_name}&model_name=${model_name}&start_timestamp=${localStartTimestamp}&end_timestamp=${localEndTimestamp}&channel_id=${channel_id}&user_id=${user_id}&agent_id=${agent_id}&group=${group}`;
        } else if (isAgentUser) {
            url = `/api/log/agent?p=${startIdx}&type=${logType}&username=${username}&token_name=${token_name}&model_name=${model_name}&start_timestamp=${localStartTimestamp}&end_timestamp=${localEndTimestamp}&user_id=${user_id}&group=${group}`;
        } else {
            url = `/api/log/self?p=${startIdx}&type=${logType}&token_name=${token_name}&model_name=${model_name}&start_timestamp=${localStartTimestamp}&end_timestamp=${localEndTimestamp}`;
        }
        const res = await API.get(url);
        const { success, message, data } = res.data;
        if (success) {
            if (startIdx === 0) {
                setLogsFormat(data);
            } else {
                let newLogs = [...logs];
                newLogs.splice(startIdx * ITEMS_PER_PAGE, data.length, ...data);
                setLogsFormat(newLogs);
            }
        } else {
            showError(message);
        }
        setLoading(false);
    };

    const pageData = logs.slice((activePage - 1) * ITEMS_PER_PAGE, activePage * ITEMS_PER_PAGE);

    const handlePageChange = page => {
        setActivePage(page);
        if (page === Math.ceil(logs.length / ITEMS_PER_PAGE) + 1) {
            // In this case we have to load more data and then append them.
            loadLogs(page - 1).then(r => {
            });
        }
    };

    const refresh = async () => {
        // setLoading(true);
        setActivePage(1);
        await loadLogs(0);
    };

    const copyText = async (text) => {
        if (await copy(text)) {
            showSuccess(t('LogsTable.LogsTable18') + text);
        } else {
            // setSearchKeyword(text);
            Modal.error({ title: t('LogsTable.LogsTable19'), content: text });
        }
    }

    const reSetting = () => {
        setResetKey(prevKey => prevKey + 1);
        setInputs(prevInputs => ({
            ...prevInputs,
            username: '',
            token_name: '',
            model_name: '',
            user_id: '',
            agent_id: '',
            start_timestamp: timestamp2string(zeroNow.getTime() / 1000),
            end_timestamp: timestamp2string(now.getTime() / 1000 + 3600),
            channel_id: '',
            group: ''
        }))
    }

    const getDashboard = async () => {
        let url = '';
        if (isAdminUser) {
            url = `/api/dashboard/admin`;
        } else {
            url = `/api/dashboard/user`;
        }
        const res = await API.get(url);
        const { success, message, data } = res.data;
        if (success) {
            setDashboard(data);
        } else {
            showError(message);
        }
    };

    useEffect(() => {
        getDashboard()
    }, []);

    useEffect(() => {
        refresh().then()
    }, [logType]);

    useEffect(() => {
        if (isAdminUser) {
            setStaticUrl('/api/log/model_usage')
        } else if (isAgentUser) {
            setStaticUrl('/api/log/agent/model_usage')
        } else {
            setStaticUrl('/api/log/self/model_usage')
        }
        getStaticShow()
    }, []);


    const searchLogs = async () => {
        if (searchKeyword === '') {
            // if keyword is blank, load files instead.
            await loadLogs(0);
            setActivePage(1);
            return;
        }
        setSearching(true);
        const res = await API.get(`/api/log/self/search?keyword=${searchKeyword}`);
        const { success, message, data } = res.data;
        if (success) {
            setLogs(data);
            setActivePage(1);
        } else {
            showError(message);
        }
        setSearching(false);
    };

    const handleKeywordChange = async (e, { value }) => {
        setSearchKeyword(value.trim());
    };

    const sortLog = (key) => {
        if (logs.length === 0) return;
        setLoading(true);
        let sortedLogs = [...logs];
        if (typeof sortedLogs[0][key] === 'string') {
            sortedLogs.sort((a, b) => {
                return ('' + a[key]).localeCompare(b[key]);
            });
        } else {
            sortedLogs.sort((a, b) => {
                if (a[key] === b[key]) return 0;
                if (a[key] > b[key]) return -1;
                if (a[key] < b[key]) return 1;
            });
        }
        if (sortedLogs[0].id === logs[0].id) {
            sortedLogs.reverse();
        }
        setLogs(sortedLogs);
        setLoading(false);
    };

    const renderType = (type) => {
        switch (type) {
            case 1:
                return <Tag color='cyan' size='large'> {t('LogsTable.LogsTable20')} </Tag>;
            case 2:
                return <Tag color='lime' size='large'> {t('LogsTable.LogsTable21')} </Tag>;
            case 3:
                return <Tag color='orange' size='large'> {t('LogsTable.LogsTable22')} </Tag>;
            case 4:
                return <Tag color='purple' size='large'> {t('LogsTable.LogsTable23')} </Tag>;
            default:
                return <Tag color='black' size='large'> {t('LogsTable.LogsTable24')} </Tag>;
        }
    }

    const [staticShow, setStaticShow] = useState();
    const [updateTimes, setUpdateTimes] = useState(0);
    const [staticShowQuery, setStaticShowQuery] = useState({
        start_timestamp: Date.parse(moment().subtract(6, 'days').toDate()) / 1000,
        end_timestamp: Date.parse(moment().toDate()) / 1000,
        user_id: ''
    })

    const [staticUrl, setStaticUrl] = useState('')

    const getStaticShow = async () => {
        if (!staticUrl) return
        const res = await API.get(`${staticUrl}?start_timestamp=${staticShowQuery.start_timestamp}&end_timestamp=${staticShowQuery.end_timestamp}&user_id=${staticShowQuery.user_id}`);
        const { success, message, data } = res.data;
        if (success) {
            setStaticShow(data.reverse())
            setUpdateTimes(updateTimes + 1)
        } else {
            showError(message);
        }
    }

    useEffect(() => {
        getStaticShow()
    }, [staticUrl])

    return (
        <>
            <Layout>
                <Collapse style={{ margin: '-10px 0px 20px -16px' }}>
                    <Collapse.Panel
                        style={{ paddingBottom: '1px' }}
                        header={
                            <h3 style={{ marginBottom: '0px' }}>{t('LogsTable.LogsTable42')}</h3>
                        }
                        itemKey="1"
                    >
                        <RadioGroup
                            type='button'
                            buttonSize='small'
                            defaultValue={staticUrl}
                            name="demo-radio-small"
                            onChange={(v) => {
                                if (v.target.value) {
                                    setStaticUrl(v.target.value)
                                }
                            }}
                        >
                            <Radio style={{ marginBottom: '0px' }} value='/api/log/self/model_usage'>{t('LogsTable.LogsTable43')}</Radio>
                            {isAgentUser && <Radio style={{ marginBottom: '0px' }} value='/api/log/agent/model_usage'>{t('LogsTable.LogsTable44')}</Radio>}
                            {isAdminUser && <Radio style={{ marginBottom: '0px' }} value='/api/log/model_usage'>{t('LogsTable.LogsTable45')}</Radio>}
                        </RadioGroup>
                        <Row style={{ marginTop: '10px' }}>
                            <Col span={7} style={{ marginRight: '16px' }}>
                                <DatePicker
                                    value={[moment().subtract(6, 'days').toDate(), moment().toDate()]}
                                    insetLabel={t('LogsTable.LogsTable46')} type="dateRange" density="compact" onChange={v => {
                                        if (v) {
                                            setStaticShowQuery({
                                                ...staticShowQuery,
                                                start_timestamp: Date.parse(v[0]) / 1000,
                                                end_timestamp: Date.parse(v[1]) / 1000
                                            })
                                        }
                                    }} />
                            </Col>
                            <Col span={6}>
                                <Input prefix={t('LogsTable.LogsTable47')} showClear onChange={v => {
                                    setStaticShowQuery({
                                        ...staticShowQuery,
                                        user_id: v
                                    })
                                }}></Input>
                            </Col>
                            <Col span={6}>
                                <Button label='查询' type="primary" htmlType="submit" className="btn-margin-right" onClick={async () => {
                                    await getStaticShow()
                                }} loading={loading}>{t('LogsTable.LogsTable35')}</Button>
                            </Col>
                        </Row>
                        <ModelUsageBarChart key={`ModelUsageBarChart${updateTimes}`} data={staticShow} />
                    </Collapse.Panel>
                </Collapse>
                <Header>
                    <h3>{
                        t('LogsTable.LogsTable25')}：
                        {showStat && renderQuota(stat.quota)}
                        {!showStat && <span onClick={handleEyeClick} style={{ cursor: 'pointer', color: 'gray' }}>{t('LogsTable.LogsTable26')}</span>})
                        {showStat && <Button type="link" size='small' onClick={refreshLogStat} loading={loading}>{t('LogsTable.LogsTable27')}</Button>}
                    </h3>
                </Header>
                <h3>
                    当前 RPM (每分钟请求量)：{dashboard.RPM}
                </h3>
                <Form key={resetKey} layout='horizontal' style={{ marginTop: 10 }}>
                    <>
                        <Form.Input field="token_name" label={t('LogsTable.LogsTable28')} style={{ width: 176 }} value={token_name}
                            placeholder={t('LogsTable.LogsTable29')} name='token_name'
                            onChange={value => handleInputChange(value, 'token_name')} />
                        <Form.Input field="model_name" label={t('LogsTable.LogsTable30')} style={{ width: 176 }} value={model_name}
                            placeholder={t('LogsTable.LogsTable29')}
                            name='model_name'
                            onChange={value => handleInputChange(value, 'model_name')} />
                        <Form.DatePicker field="start_timestamp" label={t('LogsTable.LogsTable31')} style={{ width: 272 }}
                            value={start_timestamp} type='dateTime' initValue={zeroNow}
                            name='start_timestamp'
                            onChange={value => handleInputChange(value, 'start_timestamp')} />
                        <Form.DatePicker field="end_timestamp" fluid label={t('LogsTable.LogsTable32')} style={{ width: 272 }}
                            value={end_timestamp} type='dateTime' initValue={now}
                            name='end_timestamp'
                            onChange={value => handleInputChange(value, 'end_timestamp')} />
                        {
                            isAdminUser && <>
                                <Form.Input field="channel_id" label={t('LogsTable.LogsTable33')} style={{ width: 100 }} value={channel_id}
                                    placeholder={t('LogsTable.LogsTable29')} name='channel_id'
                                    onChange={value => handleInputChange(value, 'channel_id')} />
                                <Form.Input field="agent_id" label="代理ID" style={{ width: 100 }} value={agent_id}
                                    placeholder="代理ID" name='agent_id'
                                    onChange={value => handleInputChange(value, 'agent_id')} />
                            </>
                        }
                        {
                            isAgentUser && <>
                                <Form.Input field="username" label={t('LogsTable.LogsTable34')} style={{ width: 176 }} value={username}
                                    placeholder={t('LogsTable.LogsTable29')} name='username'
                                    onChange={value => handleInputChange(value, 'username')} />
                                <Form.Input field="user_id" label="用户ID" style={{ width: 100 }} value={user_id}
                                    placeholder="用户ID" name='user_id'
                                    onChange={value => handleInputChange(value, 'user_id')} />
                                <Form.Input field="group" label="Group" style={{ width: 100 }} value={group}
                                    placeholder="Group" name='group'
                                    onChange={value => handleInputChange(value, 'group')} />
                            </>
                        }
                        <div>
                            <div style={{ marginBottom: 4 }}>
                                <Typography.Text>类型</Typography.Text>
                            </div>
                            <Select label={"类型"} defaultValue="0" style={{ width: 120 }} onChange={
                                (value) => {
                                    setLogType(parseInt(value));
                                }
                            }>
                                <Select.Option value="0">{t('LogsTable.LogsTable37')}</Select.Option>
                                <Select.Option value="1">{t('LogsTable.LogsTable38')}</Select.Option>
                                <Select.Option value="2">{t('LogsTable.LogsTable39')}</Select.Option>
                                <Select.Option value="3">{t('LogsTable.LogsTable40')}</Select.Option>
                                <Select.Option value="4">{t('LogsTable.LogsTable41')}</Select.Option>
                            </Select>
                        </div>


                        <Form.Section>
                            <Button label='查询' type="primary" htmlType="submit" className="btn-margin-right" onClick={refresh} loading={loading}>{t('LogsTable.LogsTable35')}</Button>
                            <Button label='重置' type="primary" className="btn-margin-right" onClick={reSetting} style={{ marginLeft: '8px' }}>{t('LogsTable.LogsTable36')}</Button>
                        </Form.Section>
                    </>
                </Form>
                <Table style={{ marginTop: 5 }} columns={columns} dataSource={pageData} pagination={{
                    currentPage: activePage,
                    pageSize: ITEMS_PER_PAGE,
                    total: logCount,
                    pageSizeOpts: [10, 20, 50, 100],
                    onPageChange: handlePageChange,
                }} />
            </Layout>
        </>
    );
};

export default LogsTable;
